import { Component, Input } from '@angular/core';
import { FacetValue } from '@spartacus/core';
import { FacetService } from 'src/app/feature/product-listing/product-facet-navigation/services/facet.service';
// import { FacetService } from '@spartacus/storefront';

@Component({
  selector: 'app-waygate-facet',
  templateUrl: './waygate-facet.component.html',
  styleUrls: ['./waygate-facet.component.scss'],
})
export class WaygateFacetComponent {
  @Input() facet;
  @Input() index;
  expand;
  show = true;
  selectedOne: any;

  constructor(private facetService: FacetService) {}
  ngOnChanges() {
    if (this.index < 3) {
      this.expand = true;
    }
    this.checkIfOneItemSelected(this.facet);
  }
  getLinkParams(value: FacetValue) {
    return this.facetService.getLinkParams(value.query?.query.value);
  }
  showItems(values) {
    if (this.show) {
      return 5;
    }
    return values.length;
  }
  checkIfOneItemSelected(facet) {
    this.selectedOne = facet?.values.reduce((a, c) => a || c.selected, false);
  }
}
