import { Component } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'app-waygate-router-dialog',
  templateUrl: './waygate-router-dialog.component.html',
  styleUrls: ['./waygate-router-dialog.component.scss']
})
export class WaygateRouterDialogComponent {
  constructor(
    protected launchDialogService: LaunchDialogService, private router: Router
  ) {}
  reason : string;
  close(reason: string){
    this.reason = reason
    this.launchDialogService.closeDialog(reason);
    this.router.events.subscribe((event)=>{
      if(event instanceof Scroll && event.routerEvent instanceof NavigationEnd){
        if (event.routerEvent.urlAfterRedirects == '/waygate'){
          location.reload();
        }
      }
    })
  }

}
