<app-waygate-breadcrumbs [breadcrumbs]="breadcrumbs"></app-waygate-breadcrumbs>
<div class="container position">
    <div class="msg success-backgrnd" *ngIf="showSussess"><img src="../../../../assets/img/success_tick.png">
        {{'contactusPage.successMsg' | cxTranslate }} <img (click)="close('success')" class="close-icon"
            src="../../../../assets/img/Close.png"></div>
</div>
<div class="container position">
    <div class="msg warning-backgrnd" *ngIf="showWarning"><img src="../../../../assets/img/warning.png">
        {{'contactusPage.warningMsg' | cxTranslate }} <img (click)="close('warning')" class="close-icon"
            src="../../../../assets/img/Close.png"></div>
</div>
<div class="main-wrapper container">
    <div>
        <div class="title">
            <hr class="separator">
            {{ 'contactusPage.contactus' | cxTranslate }}
        </div>
        <div class="container">
            <div class="w-70">
                <form [formGroup]="contactusForm" class="registration-form-main">
                    <!-- Tag selection -->
                    <!-- <div class="tag-row" *ngIf="showCustTypeOptn">
                        <label class="tag-label required typography12">{{'contactusPage.custType' | cxTranslate }}
                        </label>
                        <div [ngClass]="{'tag-group': true, 'tag-group-checked': selectedCustType == i}"
                            *ngFor="let cust of custType let i = index" (click)="onCustTypeClick(i)">
                            <label>
                                <input type="radio" formControlName="custType" [value]="cust" />
                                <span>{{cust}}</span>
                            </label>
                        </div>
                        <div class="requredMsg"
                            *ngIf="contactusForm.get('requestType').invalid && contactusForm.get('requestType').touched">
                            <span class="errMsgRed"> {{'registration.thisFieldRequired' | cxTranslate}} </span>
                        </div>
                    </div>
                    <div class="tag-row" *ngIf="this.selectedCustType == 1 || !showCustTypeOptn">
                        <label class="tag-label required typography12">{{'contactusPage.typeOfRequest' | cxTranslate }}
                        </label>
                        <div [ngClass]="{'tag-group': true, 'tag-group-checked': selectedRequestType == i}"
                            *ngFor="let line of requestType let i = index" (click)="onReqTypeClick(i)">
                            <label>
                                <input type="radio" formControlName="requestType" [value]="line" />
                                <span>{{line}}</span>
                            </label>
                        </div>
                        <div class="requredMsg"
                            *ngIf="contactusForm.get('requestType').invalid && contactusForm.get('requestType').touched">
                            <span class="errMsgRed"> {{'registration.thisFieldRequired' | cxTranslate}} </span>
                        </div>
                    </div>
                    <div class="form-row"
                        *ngIf="contactusForm.get('requestType').value === 'Invoice' || contactusForm.get('requestType').value === 'Order' || contactusForm.get('requestType').value === 'Returns'">
                        <div class="form-group w-100">
                            <label for="requestVal" class="required">
                                {{ options[contactusForm.get('requestType').value].label | cxTranslate }}
                            </label>
                            <input id="requestVal" formControlName="requestVal" class="form-control" type="text"
                                [ngClass]="{'errInputFieldRed': contactusForm.get('requestVal').invalid && contactusForm.get('requestVal').touched}"
                                placeholder="{{ options[contactusForm.get('requestType').value].placeholder | cxTranslate }}" />
                            <div
                                *ngIf="contactusForm.get('requestVal').invalid && contactusForm.get('requestVal').touched">
                                <span class="errMsgRed">{{'registration.fieldCanNotBeEmpty' | cxTranslate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group w-100">
                            <label class="required">{{ 'contactusPage.selectSubproductLine' | cxTranslate }}</label>

                            <bh-dropdown isFluid="true" isSmall="false" width="large" menuWidth="large"
                                unselectable="false" [menuItems]="subProductLines"
                                placeholder='{{ "contactusPage.selectSubProduct" | cxTranslate }}'
                                [attr.error]="contactusForm.get('areaOfInterest')?.invalid && contactusForm.get('areaOfInterest')?.touched ? 'empty':null"
                                (selected)="onSubProductLineChange($event)" isEllipsis="false"
                                (focusout)="bhDropdownClick('areaOfInterest')"></bh-dropdown>
                            <div
                                *ngIf="contactusForm.get('areaOfInterest').invalid && contactusForm.get('areaOfInterest').touched">
                                <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' | cxTranslate}}</span>
                            </div>
                        </div>
                    </div> -->
                    <ng-cotainer *ngIf="!this.loggedIn" class="w-100">
                        <div class="form-row wrap">
                            <div class="form-group">
                                <label for="firstName" class="required">{{ 'contactusPage.fname' | cxTranslate
                                    }}</label>
                                <input id="firstname" formControlName="firstName" class="form-control" type="text"
                                    [ngClass]="{'errInputFieldRed': contactusForm.get('firstName').invalid && contactusForm.get('firstName').touched}"
                                    placeholder="{{'contactusPage.enterFName' | cxTranslate }}" />
                                <div
                                    *ngIf="contactusForm.get('firstName').invalid && contactusForm.get('firstName').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="lastName" class="required">{{ 'contactusPage.lname' | cxTranslate
                                    }}</label>
                                <input id="lastName" formControlName="lastName" class="form-control" type="text"
                                    [ngClass]="{'errInputFieldRed': contactusForm.get('lastName').invalid && contactusForm.get('lastName').touched}"
                                    placeholder="{{'contactusPage.enterLName' | cxTranslate }}" />
                                <div
                                    *ngIf="contactusForm.get('lastName').invalid && contactusForm.get('lastName').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group">
                                <label for="companyName" class="required">{{ 'contactusPage.companyName' |
                                    cxTranslate
                                    }}</label>
                                <input id="companyName" formControlName="companyName" class="form-control" type="text"
                                    [ngClass]="{'errInputFieldRed': contactusForm.get('companyName').invalid && contactusForm.get('companyName').touched}"
                                    placeholder="{{'contactusPage.enterCompanyName' | cxTranslate }}" />
                                <div
                                    *ngIf="contactusForm.get('companyName').invalid && contactusForm.get('companyName').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-row wrap">
                            <div class="form-group">
                                <div class="info-icon-align">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class="d-flex">
                                            <label for="email" class="required">{{ 'contactusPage.emailId' |
                                                cxTranslate
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                                <input id="email" formControlName="email" class="form-control" autocomplete="false"
                                    [ngClass]="{'errInputFieldRed': contactusForm.get('email').invalid && contactusForm.get('email').touched}"
                                    placeholder="{{'contactusPage.enterEmailId' | cxTranslate }}" />
                                <div *ngIf="contactusForm.get('email').invalid && contactusForm.get('email').touched">
                                    <span class="errMsgRed" *ngIf="contactusForm.get('email').errors?.invalidEmail">
                                        {{ 'contactusPage.enterValidEmail' | cxTranslate }}
                                    </span>
                                    <span class="errMsgRed" *ngIf="contactusForm.get('email').errors?.required">
                                        {{ 'contactusPage.fieldCanNotBeEmpty' | cxTranslate }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="phoneNum" class="required">{{ 'contactusPage.phoneNumber' | cxTranslate
                                    }}</label>
                                <input id="phoneNum" formControlName="phoneNum" class="form-control" type="text"
                                    placeholder="{{'contactusPage.enterPhoneNumber' | cxTranslate }}" />
                                <div
                                    *ngIf="contactusForm.get('phoneNum').invalid && contactusForm.get('phoneNum').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                                <span class="errMsgRed"
                                    *ngIf="fc.phoneNum?.errors?.pattern">{{'contactusPage.enterNumbersOnly' |
                                    cxTranslate}}</span>
                            </div>
                        </div>

                        <div class="form-row wrap">
                            <div class="form-group">
                                <label for="country" class="required">{{ 'contactusPage.country' |
                                    cxTranslate }}</label>
                                <bh-dropdown searchable [menuItems]="countryItems"
                                    placeholder='{{ "contactusPage.selectCountry" | cxTranslate }}' required="true"
                                    [attr.error]="contactusForm.get('country')?.invalid && contactusForm.get('country')?.touched ? 'empty':null"
                                    (focusout)="bhDropdownClick('country')" (selected)="getStateList($event,'country')">
                                </bh-dropdown>
                                <div
                                    *ngIf="contactusForm.get('country').invalid && contactusForm.get('country').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="state" class="required">{{ 'contactusPage.stateRegion' |
                                    cxTranslate }}</label>
                                <!-- <select id="state" formControlName="state" class="form-control"
                                    [ngClass]="{'errInputFieldRed': contactusForm.get('state').invalid && contactusForm.get('state').touched}"
                                    placeholder="{{'contactusPage.selectCountry' | cxTranslate }}">
                                    <option value="" disabled>{{'contactusPage.selectState' | cxTranslate }}</option>
                                    <option value="test">test</option>

                                    <option *ngFor="let item of subProductLines" [value]="item">{{item}}</option>
                                </select> -->
                                <bh-dropdown searchable [menuItems]="stateItems"
                                    placeholder='{{ "contactusPage.selectStateRegion" | cxTranslate }}' required="true"
                                    [attr.error]="contactusForm.get('state')?.invalid && contactusForm.get('state')?.touched ? 'empty':null"
                                    (selected)="onChange($event,'state')" (focusout)="bhDropdownClick('state')">
                                </bh-dropdown>
                                <div *ngIf="contactusForm.get('state').invalid && contactusForm.get('state').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row wrap">
                            <div class="form-group">
                                <label for="areaInterest" class="required">{{ 'contactusPage.areaInterest' |
                                    cxTranslate }}</label>
                                <bh-dropdown searchable [menuItems]="areasOfInterestItems"
                                    placeholder='{{ "contactusPage.selectAreaInterest" | cxTranslate }}' required="true"
                                    [attr.error]="contactusForm.get('areaOfInterest')?.invalid && contactusForm.get('areaOfInterest')?.touched ? 'empty':null"
                                    (selected)="onChange($event,'areaOfInterest')"
                                    (focusout)="bhDropdownClick('areaOfInterest')">
                                </bh-dropdown>
                                <div
                                    *ngIf="contactusForm.get('areaOfInterest').invalid && contactusForm.get('areaOfInterest').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="jobRole" class="required">{{ 'contactusPage.jobRole' | cxTranslate
                                    }}</label>
                                <bh-dropdown searchable [menuItems]="jobRolesItems"
                                    placeholder='{{ "contactusPage.selectJobRole" | cxTranslate }}' required="true"
                                    [attr.error]="contactusForm.get('title')?.invalid && contactusForm.get('title')?.touched ? 'empty':null"
                                    (selected)="onChange($event,'title')"
                                    (focusout)="bhDropdownClick('title')"></bh-dropdown>
                                <div *ngIf="contactusForm.get('title').invalid && contactusForm.get('title').touched">
                                    <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' |
                                        cxTranslate}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-cotainer>
                    <div class="form-row">
                        <div class="form-group w-100">
                            <label for="mktoPersonNotes" class="required">{{ 'contactusPage.tellYourQuery' |
                                cxTranslate
                                }}</label>
                            <textarea id="mktoPersonNotes" formControlName="mktoPersonNotes" class="form-control"
                                type="textarea" rows="4"
                                [ngClass]="{'errInputFieldRed': contactusForm.get('mktoPersonNotes').invalid && contactusForm.get('mktoPersonNotes').touched}"
                                placeholder="{{'contactusPage.writeSomething' | cxTranslate }}"
                                maxlength="500"></textarea>
                            <div
                                *ngIf="contactusForm.get('mktoPersonNotes').invalid && contactusForm.get('mktoPersonNotes').touched">
                                <span class="errMsgRed">{{'contactusPage.fieldCanNotBeEmpty' | cxTranslate}}</span>
                            </div>
                            <div>
                                <span class="char-count">{{charCount}}/500 {{'contactusPage.characters' |
                                    cxTranslate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="this.selectedCustType === 0 && !loggedIn">
                        <!-- druckcontact@bakerhughes.com -->
                        <div class="cust-care-link">If you’d like to send us a file, please email  <a
                                href="mailto:{{mailAddress}}"
                                class="contact-link">{{mailAddress}}</a></div>
                    </div>
                    <!-- <ng-container *ngIf="this.selectedCustType === 1">
                        <label>{{'contactusPage.additionalDocument' | cxTranslate}}</label>
                        <div class="form-group w-100">
                            <ds-file-upload label="{{'contactusPage.fileUploadButton' | cxTranslate}}" [maxLength]="5"
                                [maxSizeInMB]="2" [allowedFileExtensions]="ALLOWED_EXTENSIONS"
                                (selectedFiles)="selectedFiles($event)" [showIcon]="showIcon" [icon]="'backup'"
                                isDragDrop="true">upload</ds-file-upload>

                            <div class="file-list">
                                <ds-file-upload-progress *ngFor="let file of files" [layout]="layouts.LIST"
                                    [deleteUrl]="deleteUrl" [file]="file" (deletedFiles)="deletedFiles($event)"
                                    [uploadParams]="uploadParams" [uploadUrl]="uploadUrl"
                                    [showProgress]="true"></ds-file-upload-progress>
                            </div>

                        </div>
                        <div class="form-group w-100">
                            <span class="fileformat mt-1">
                                File Format - .pdf, .jpg, .png | Max. size - 2MB
                            </span>
                        </div>
                    </ng-container> -->
                    <div class="form-check">
                        <input type="radio" class="form-check-input" id="yes" formControlName="optIn" value="true">
                        <label for="yes" class="form-check-label">{{'contactusPage.optInYes' | cxTranslate
                            }}</label>
                    </div>
                    <div class="form-check">
                        <input type="radio" class="form-check-input" id="no" formControlName="optIn" value="false">
                        <label for="no" class="form-check-label">{{'contactusPage.optInNo' | cxTranslate
                            }}</label>
                    </div>
                    <div class="form-row terms-conditions">
                        <div for="terms" class="ml-0"
                            innerHTML="{{'contactusPage.comunucationPreferences' | cxTranslate }}">
                        </div>
                    </div>
                    <div class="form-row">
                        <bh-button isSmall=False Type="Primary" label='{{ "contactusPage.submit" | cxTranslate }}'
                            isSmall=False isFluid=True (click)="onSubmit()" class="mystyle"></bh-button>
                    </div>
                </form>
            </div>
            <div class="w-30">
                <div class="cust-care-link" *ngIf="loggedIn && this.brand != _allProductLine.waygate">To contact a
                    customer care site directly, <a [href]="customerCareUrl" class="contact-link">Click here &nbsp;
                        <img class="inline-img" alt="img" src="../../../../assets/img/vector-nort-east.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="spinner-overlay" *ngIf="isLoading">
    <cx-spinner></cx-spinner>
</div>