import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  ForbiddenHandler,
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorHandler,
  HttpResponseStatus,
  OccEndpointsService,
  Priority,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class DSForbiddenHandler extends ForbiddenHandler {
  responseStatus = HttpResponseStatus.FORBIDDEN;

  handleError(request: HttpRequest<any>) {
    if (request.url.indexOf('/carts') != -1) {
    } else {
      if (
        request.url.endsWith(
          this.occEndpoints.buildUrl('user', {
            urlParams: { userId: 'current' },
          })
        )
      ) {
        this.authService.logout();
      }
      this.globalMessageService.add(
        { key: 'httpHandlers.forbidden' },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  getPriority(): Priority {
    return Priority.HIGH;
  }

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected authService: AuthService,
    protected occEndpoints: OccEndpointsService
  ) {
    super(globalMessageService, authService, occEndpoints);
  }
}
