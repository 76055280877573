import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';

@Component({
  selector: 'app-waygate-breadcrumbs',
  templateUrl: './waygate-breadcrumbs.component.html',
  styleUrls: ['./waygate-breadcrumbs.component.scss'],
})
export class WaygateBreadcrumbsComponent implements OnInit {
  @Input()
  breadcrumbs: any[] = [];
  constructor(
    private customerAccService: CustomerAccountService,
    private router: Router
  ){}
  ngOnInit(): void { }

  waygatePage() {
    this.customerAccService.disableChangeAccount.next(false);
    this.router.navigate(['/waygate']);
  }
}
