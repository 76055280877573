import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  AnonymousConsentsModule,
  AuthModule,
  CostCenterOccModule,
  ExternalRoutesModule,
  I18nModule,
  ProductModule,
  ProductOccModule,
  UserOccModule,
} from '@spartacus/core';
import {
  AddressBookModule,
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  HamburgerMenuModule,
  HomePageEventModule,
  IconModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  PaymentMethodsModule,
  ProductDetailsPageModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductSummaryModule,
  ProductTabsModule,
  SearchBoxModule,
  SpinnerModule,
  StockNotificationModule,
  TabParagraphContainerModule,
} from '@spartacus/storefront';
import { UserFeatureModule } from './features/user/user-feature.module';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { SpinnerOverlayModule } from '../shared/components/spinner-overlay/spinner-overlay.module';
import { BreadcrumbModule } from '../shared/components/breadcrumb/breadcrumb.module';
import { TrainingDocsModule } from '../feature/training-docs/training-docs.module';
import { NotificationsModule } from '../feature/notifications/notifications.module';
import { CalibrationDataModule } from '../feature/calibration-data/calibration-data.module';
import { CheckoutModule } from '../feature/checkout/checkout.module';
import { LandingPagesModule } from '../feature/landing/landing-pages.module';
import { GuestQuoteModule } from '../feature/quote/guest-quote.module';
import { MyProfileModule } from '../feature/user/my-profile/my-profile.module';
import { FeedbackModule } from '../feature/feedback/feedback.module';
import { LinksModule } from '../feature/links/links.module';
import { ContactUsModule } from '../feature/contact-us/contact-us.module';
import { SearchboxModule } from '../feature/header/searchbox/searchbox.module';
import { SavedCartModule } from '../feature/saved-cart/saved-cart.module';
import { ProductDetailsModule } from '../feature/product-details/product-details.module';
import { FooterModule } from '../feature/footer/footer.module';
import { MiniCartModule } from '../feature/header/mini-cart/mini-cart.module';
import { LoginFormModule } from '../feature/user/login-form/login-form.module';
import { MyFavoritesModule } from '../feature/my-favorites/my-favorites.module';
import { SiteEquipmentsModule } from '../feature/site-equipments/site-equipments.module';
import { ProductFacetNavigationModule } from '../feature/product-listing/product-facet-navigation/product-facet-navigation.module';
import { ProductListingModule } from '../feature/product-listing/product-listing.module';
import { SiteLoginModule } from '../feature/header/site-login/site-login.module';
import { RmaModule } from '../feature/rma/rma.module';
import { OrderTrackingModule } from '../feature/order-tracking/order-tracking.module';
import { CoreModule } from '../core/core.module';
import { OrderApprovalRootModule } from '@spartacus/organization/order-approval/root';
import { AdministrationRootModule } from '@spartacus/organization/administration/root';
import { AddToCartModule } from '../feature/cart/add-to-cart/add-to-cart.module';
import { NavigationMenuModule } from '../feature/header/navigation-menu/navigation-menu.module';
import { WaygateTechModule } from '../feature/waygate-tech-landing/waygate-tech.module';
import { OrderOccModule } from '@spartacus/order/occ';
import { CheckoutRootModule } from '@spartacus/checkout/base/root';
import { AddToWishListModule } from '@spartacus/cart/wish-list/components/add-to-wishlist';
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { RouterModule } from '@angular/router';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { WishListFeatureModule } from './features/cart/wish-list-feature.module';
import { CartImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { CartQuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { CustomerTicketingFeatureModule } from './features/customer-ticketing/customer-ticketing-feature.module';
import { DigitalPaymentsFeatureModule } from './features/digital-payments/digital-payments-feature.module';
import { EpdVisualizationFeatureModule } from './features/epd-visualization/epd-visualization-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { OrganizationAccountSummaryFeatureModule } from './features/organization/organization-account-summary-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { OrganizationOrderApprovalFeatureModule } from './features/organization/organization-order-approval-feature.module';
import { OrganizationUnitOrderFeatureModule } from './features/organization/organization-unit-order-feature.module';
import { OrganizationUserRegistrationFeatureModule } from './features/organization/organization-user-registration-feature.module';
import { PickupInStoreFeatureModule } from './features/pickup-in-store/pickup-in-store-feature.module';
import { ProductConfiguratorFeatureModule } from './features/product-configurator/product-configurator-feature.module';
import { ProductBulkPricingFeatureModule } from './features/product/product-bulk-pricing-feature.module';
import { ProductFutureStockFeatureModule } from './features/product/product-future-stock-feature.module';
import { QualtricsFeatureModule } from './features/qualtrics/qualtrics-feature.module';
import { S4omFeatureModule } from './features/s4om/s4om-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { StoreFinderFeatureModule } from './features/storefinder/store-finder-feature.module';
import { PersonalizationFeatureModule } from './features/tracking/personalization-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { CartComponentModule } from '../feature/cart/cart.module';
import { RegistrationModule } from '../feature/registration/registration.module';
// import { DialogModule } from '../shared/components/dialog-component/dialog.module';
@NgModule({
  declarations: [],
  imports: [
    AsmFeatureModule,
    RouterModule,
    // Auth Core
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    BreadcrumbModule,
    // Basic Cms Components
    HamburgerMenuModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    // User Core,
    // User UI,
    AddressBookModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,
    // Anonymous Consents Core,
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI,
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,
    // Product Core,
    ProductModule.forRoot(),
    ProductOccModule,
    // Product UI,
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductIntroModule,
    // Cart Core,
    // Cart UI,
    AddToWishListModule,
    CostCenterOccModule,
    // Checkout Core
    CheckoutRootModule,
    CheckoutModule,
    // Order,
    OrderOccModule,
    // Page Events,
    NavigationEventModule,
    HomePageEventModule,
    // CartPageEventModule,
    ProductPageEventModule,
    // External routes,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    UserOccModule,
    IconModule,
    SpinnerModule,
    I18nModule,
    SpinnerOverlayModule,
    AdministrationRootModule,
    OrderApprovalRootModule,
    CartBaseFeatureModule,
    CartSavedCartFeatureModule,
    WishListFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    OrderFeatureModule,
    CheckoutFeatureModule,
    PersonalizationFeatureModule,
    TagManagementFeatureModule,
    OrganizationAdministrationFeatureModule,
    OrganizationAccountSummaryFeatureModule,
    OrganizationUnitOrderFeatureModule,
    OrganizationUserRegistrationFeatureModule,
    OrganizationOrderApprovalFeatureModule,
    ProductConfiguratorFeatureModule,
    StoreFinderFeatureModule,
    S4omFeatureModule,
    EpdVisualizationFeatureModule,
    DigitalPaymentsFeatureModule,
    CustomerTicketingFeatureModule,
    SmartEditFeatureModule,
    QualtricsFeatureModule,
    ProductFutureStockFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    ProductBulkPricingFeatureModule,
    PickupInStoreFeatureModule,
    CoreModule,
    OrderTrackingModule,
    RmaModule,
    SiteLoginModule,
    ProductListingModule,
    ProductFacetNavigationModule,
    SiteEquipmentsModule,
    MyFavoritesModule,
    LoginFormModule,
    MiniCartModule,
    NavigationMenuModule,
    FooterModule,
    ProductDetailsModule,
    CartComponentModule,
    // GuestBuyCheckoutModule,
    // BuyCheckoutModule,
    SavedCartModule,
    SearchboxModule,
    ContactUsModule,
    LinksModule,
    FeedbackModule,
    MyProfileModule,
    GuestQuoteModule,
    LandingPagesModule,
    CheckoutModule,
    // CartDetailsModule,
    CalibrationDataModule,
    NotificationsModule,
    TrainingDocsModule,
    AddToCartModule,
    WaygateTechModule,
    RegistrationModule,
    // DialogModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SpinnerModule, AddToCartModule, AddToCartModule, I18nModule],
})
export class SpartacusFeaturesModule {}
