import { Component } from '@angular/core';
import { CmsService, StorageSyncType, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-wayagte-main-structure',
  templateUrl: './wayagte-main-structure.component.html',
  styleUrls: ['./wayagte-main-structure.component.scss'],
})
export class WayagteMainStructureComponent {
  outageBannerComponent$: Observable<any> =
    this.cmsService.getComponentData<any>('OutageBannerComponent');
  showOutageBanner = true;
  constructor(
    private cmsService: CmsService,
    private commonService: CommonService,
    protected winRef: WindowRef
  ) {
    this.showOutageBanner = this.getOutageBannerDisplayFromStorage()
      ? false
      : true;

    // If banner message is closed for more than 7 days, show the outage message
    if (!this.showOutageBanner) {
      const bannerDate = this.getOutageBannerDisplayFromStorage();
      const timeDifference =
        new Date().getTime() - new Date(bannerDate).getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      if (daysDifference >= 7) {
        this.showOutageBanner = true;
        this.removeOutageBannerDisplayFromStorage();
      }
    }
  }
  closeOutageBanner() {
    this.showOutageBanner = false;
    this.setOutageBannerDisplayToStorage(new Date().toString());
  }

  setOutageBannerDisplayToStorage(showHideBanner: string) {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    this.commonService.persistToStorage(
      'outage-banner',
      showHideBanner,
      storage
    );
  }
  getOutageBannerDisplayFromStorage() {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    return this.commonService.readFromStorage(storage, 'outage-banner');
  }

  removeOutageBannerDisplayFromStorage() {
    const storageType = StorageSyncType.LOCAL_STORAGE;
    const storage = this.commonService.getStorage(storageType, this.winRef);
    return this.commonService.removeFromStorage(storage, 'outage-banner');
  }
}
