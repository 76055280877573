<bh-button type=primary label="Click Me!" loading="true" (click)="clickMe()"></bh-button>

<bh-checkbox label="Check Me" name="checkMe" value="true" indeterminate="true" error="true" (change)="checkMe($event)"></bh-checkbox>

<bh-a type="primary" href="/login" text="login" (click)="clickMe()"></bh-a>

<!-- <div style="margin:20px">
    <p class="mat-subtitle-2">Default </p>
    <button mat-button class="mat-button-primary" style="margin-right: 16px;">Button
    </button>
    <button mat-button class="mat-button-primary mat-button-primary__icon" style="margin-right: 16px;">
        <mat-icon class="mat-icon-left">home</mat-icon>Button
    </button>
    <button mat-button class="mat-button-primary mat-button-primary__icon" style="margin-right: 16px;">
        Button<mat-icon class="mat-icon-right">home</mat-icon>
    </button>

    <button mat-button class="mat-button-primary mat-button-primary__icon-only" style="margin-right: 16px;">
        <mat-icon class="mat-icon">add</mat-icon>
    </button>

    <p class="mat-subtitle-2">Small</p>
    <button mat-button class="mat-button-primary-sm" style="margin-right: 16px;"> Button
    </button>
    <button mat-button class="mat-button-primary-sm mat-button-primary-sm__icon" style="margin-right: 16px;">
        <mat-icon class="mat-icon-left mat-icon--sml">home</mat-icon>Button
    </button>
    <button mat-button class="mat-button-primary-sm mat-button-primary-sm__icon" style="margin-right: 16px;">
        Button <mat-icon class="mat-icon-right mat-icon--sml">home</mat-icon>
    </button>
    <button mat-button class="mat-button-primary-sm mat-button-primary-sm__icon-only" style="margin-right: 16px;">
        <mat-icon class="mat-icon--sml">add</mat-icon>
    </button>
</div> -->

<!-- <div style="margin:20px">
    <mat-radio-group>
        <mat-radio-button color=primary [disableRipple]="true" disabled=false style="margin:10px" value="1">
            Yes
        </mat-radio-button>
        <mat-radio-button color=primary [disableRipple]="true" disabled=false style="margin:10px" value="2">
            No
        </mat-radio-button>
    </mat-radio-group>
</div> -->