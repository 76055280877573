<div class="conatiner">
  <div class="brand-list">
    <div class="brand-item col-4" *ngIf="availableProductLines.includes(productLine.waygate)">
      <div class="brand-content">
        <div class="brand-log">
          <img src="../../../../assets/img/bannernav1.png" [alt]="productLine.waygate"  />
        </div>
        <div class="brand-desc">
          World’s largest provider of non-destructive testing solutions including radiography, computed tomography (CT), remote visual inspection, and ultrasound.
        </div>
        <a href="javascript:void(0)" (click)="routeToRedirect(productLine.waygate)" >Visit Site<span class="material-icons"> call_made </span></a>
        <!-- <div class="vertical-line"></div> -->
      </div>
    </div>
    <div class="brand-item col-4" *ngIf="availableProductLines.includes(productLine.druck)">
      <div class="brand-content">
        <div class="brand-log">
          <img src="../../../../assets/img/bannernav3.png" [alt]="productLine.druck" />
        </div>
        <div class="brand-desc">
          Druck’s piezo-resistive pressure sensors and test and calibration instruments provide our customers with the highest performance, stability, quality, accuracy and quickest response in any environment.
        </div>
        <a href="javascript:void(0)" (click)="routeToRedirect(productLine.druck)" >Visit Site<span class="material-icons"> call_made </span></a>
        <!-- <div class="vertical-line"></div> -->
      </div>
    </div>
    <div class="brand-item col-4" *ngIf="availableProductLines.includes(productLine.bently)">
      <div class="brand-content">
        <div class="brand-log">
          <img src="../../../../assets/img/bannernav4.png" [alt]="productLine.bently"  />
        </div>
        <div class="brand-desc">
          Bently Nevada serves a variety of needs, including Asset Condition Monitoring, Machinery Diagnostics, Monitoring and Sensing Hardware, Asset Protection Software and Global Support and Services.
        </div>
        <a href="javascript:void(0)" (click)="routeToRedirect(productLine.bently)" >Visit Site<span class="material-icons"> call_made </span></a>
        <!-- <div class="vertical-line"></div> -->
      </div>
    </div>
    <div class="brand-item col-4" *ngIf="availableProductLines.includes(productLine.panametrics)">
      <div class="brand-content">
        <div class="brand-log">
          <img src="../../../../assets/img/bannernav2.png" [alt]="productLine.panametrics"  />
        </div>
        <div class="brand-desc">
          Panametrics has been a pioneer in the sensor technology industry for more than 50 years. With an innovative culture, Panametrics continues to develop solutions for moisture, oxygen, liquid flow, and gas flow measurement.
        </div>
        <a href="javascript:void(0)" (click)="routeToRedirect(productLine.panametrics)" >Visit Site<span class="material-icons"> call_made </span></a>
        <!-- <div class="vertical-line"></div> -->
      </div>
    </div>
    <div class="brand-item col-4" *ngIf="availableProductLines.includes(productLine.reuterStokes)">
      <div class="brand-content">
        <div class="brand-log">
          <img src="../../../../assets/img/bannernav5.png" [alt]="productLine.reuterStokes"  />
        </div>
        <div class="brand-desc">
          Our solutions include precise radiation measurement, nuclear reactor monitoring, UV flame detection, and downhole sensing for directional drilling.
        </div>
        <a href="javascript:void(0)" (click)="routeToRedirect(productLine.reuterStokes)" >Visit Site<span class="material-icons"> call_made </span></a>
        <!-- <div class="vertical-line"></div> -->
      </div>
    </div>
  </div>
</div>
