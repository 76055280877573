import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthService,
  HttpErrorModel,
  SiteContextActions,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { CustomerAccount } from 'src/app/core/customer-account/store/customer-account.model';

@Component({
  selector: 'ds-waygate-customer-account',
  templateUrl: './waygate-customer-account.component.html',
  styleUrls: ['./waygate-customer-account.component.scss'],
})
export class WaygateCustomerAccountComponent implements OnInit {
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  @Output() closeMenu = new EventEmitter<any>();
  showSlider: boolean = false;
  activeSalesArea: any;
  currentCustomerAccount$: Observable<CustomerAccount>;
  favCustomerAccounts$: Observable<CustomerAccount[] | HttpErrorModel>;
  recentCustomerAccounts$: Observable<CustomerAccount[] | HttpErrorModel>;
  disabelChangeAcc = false;
  rmaSalesAreaId;
  previousURL: string;
  favList: any;
  otherList: any;
  activeList: any;
  b2bUnits: any[];
  legalEntities: any;
  constructor(
    private authService: AuthService,
    private customerAccService: CustomerAccountService,
    private cRef: ChangeDetectorRef,
    private store: Store,
    private router: Router
  ) {}
  salesAreaData;

  ngOnInit(): void {
    // disable change account for checkout and confirmation page
    this.customerAccService.disableChangeAccount.subscribe((data) => {
      if (data) {
        this.disabelChangeAcc = true;
      } else {
        this.disabelChangeAcc = false;
      }
      this.cRef.detectChanges();
    });
    this.userLoggedIn$.subscribe((res) => {
      if (res) {
        this.previousURL = localStorage.getItem('prevURL');
        this.customerAccService.loadFavCustomerAccounts();
        this.favCustomerAccounts$ =
          this.customerAccService.getFavCustomerAccounts();

        this.customerAccService.loadRecentCustomerAccounts();
        this.recentCustomerAccounts$ =
          this.customerAccService.getRecentCustomerAccounts();

        this.currentCustomerAccount$ =
          this.customerAccService.getCurrentCustomerAccount();

        this.customerAccService.removeGuestActiveFromStorage();

        this.currentCustomerAccount$.subscribe((res: any) => {
          this.rmaSalesAreaId =
            res.selectedSalesArea?.salesAreaId.split('_')[1];
          localStorage.setItem('rmaSalesAreaId', this.rmaSalesAreaId);
          if (res.currency) {
            this.store.dispatch(
              new SiteContextActions.SetActiveCurrency(res?.currency?.isocode)
            );
          }
        });
        this.favCustomerAccounts$.subscribe((res: any) => {
          this.favList = res;
        });
        this.recentCustomerAccounts$.subscribe((res: any) => {
            this.otherList = res;
        });
        this.currentCustomerAccount$.subscribe((res: any) => {      
            this.activeList = res;
        });
        const favList = null !== this.favList ? Object.values(this.favList) : '';
        const otherList =
          null !== this.otherList ? Object.values(this.otherList) : '';
        this.b2bUnits = [this.activeList, ...favList, ...otherList];
        const uniqueEntries = [];
        const encounteredNames = new Set();
        this.b2bUnits.forEach((entry) => {
          if (!encounteredNames.has(entry.uid)) {
            uniqueEntries.push(entry);
            encounteredNames.add(entry.uid);
          }
        });
        this.b2bUnits = uniqueEntries;
        if(this.b2bUnits?.length > 1){
          this.disabelChangeAcc = false;
        } else {
          this.legalEntities = this.b2bUnits[0]?.salesAreaObjectDataList;
          if(this.legalEntities.length > 1){
            this.disabelChangeAcc = false;
          } else {
            this.disabelChangeAcc = true;
          }
        }
      }
    });
  }

  openSlider() {
    this.showSlider = !this.showSlider;
    this.closeMenu.emit(true);
    this.customerAccService.openSlider();
  }

  getSelectedLegalEntity(legalEntities) {
    return legalEntities.filter((en) => en['active'] === true)[0];
  }
}
