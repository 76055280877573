export enum AllProductLine {
  waygate = `waygate`,
  bently = `bently-nevada`,
  panametrics = `panametrics`,
  druck = `druck`,
  reuterStokes = `reuter-stokes`,
}

export const ProductLineHomePageURL = {
  [AllProductLine.waygate]: `/waygate`,
  [AllProductLine.druck]: `/homepage`,
  [AllProductLine.bently]: `/homepage`,
  [AllProductLine.panametrics]: `/homepage`,
  [AllProductLine.reuterStokes]: `/homepage`,
};
export enum ProductLineStorageKey{
  selectedBrand = `selectedBrand`,
  avaiableProductLine = `avaiableProductLine`
}
