import { Component, SecurityContext } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '@spartacus/core';
import { FileProgressLayouts } from 'src/app/shared/models/fileSize.model';
import { WaygateContactUsService } from './waygate-contactus.service';
import { RouterState } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { PlApiURL, AllProductLine } from 'src/app/shared/enums/availableProductList.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { REGULAR_PATTERN, testRegex } from 'src/app/core/generic-validator/regular-expressions'
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { mailToUrl } from 'src/app/shared/products-constants';

// enum CustTypeEnum {
//   newCustomer = 'New Customer',
//   existingCust = 'Existing Customer'
// }
@Component({
  selector: 'app-waygate-contactus',
  templateUrl: './waygate-contactus.component.html',
  styleUrls: ['./waygate-contactus.component.scss']
})
export class WaygateContactusComponent {
  /* subProductLines = {
    itemGroups: [{
      items: [
        { label: "Film", value: "Film" },
        { label: "UT devices & probes", value: "UT devices & probes" },
        { label: "Industrial rad system", value: "Industrial rad system" },
        { label: "Remote visual inspection", value: "Remote visual inspection" },
        { label: "Digital imaging & generators", value: "Digital imaging & generators" }
      ]
    }]
  } */

  // requestType = ['Invoice', 'Order', 'Returns', 'Feedback', 'Support'];
  // custType = [CustTypeEnum.newCustomer, CustTypeEnum.existingCust];
  // options = {
  //   Invoice: { label: 'contactusPage.invoiceNumber', placeholder: 'contactusPage.enterInvoiceNumber' },
  //   Order: { label: 'contactusPage.orderNumber', placeholder: 'contactusPage.enterOrderNumber' },
  //   Returns: { label: 'contactusPage.rmaNumber', placeholder: 'contactusPage.enterRmaNumber' },
  // }
  selectedRequestType: number | string;
  contactusForm: FormGroup;
  isCheckedProductLines: boolean[] = [];
  readonly ALLOWED_EXTENSIONS = ['jpg', 'pdf', 'png'];
  files = [];
  showIcon: boolean = true;
  readonly layouts = FileProgressLayouts;
  deleteUrl = 'users/anonymous/contactus/removeQuoteAttach';
  uploadUrl;
  uploadParams = {
    entryNumber: 1,
    fields: 'DEFAULT',
    returnLocation: 'DEFAULT',
  };
  deleteParams = {
    returnLocation: 'DEFAULT',
  };
  breadcrumbs: any = [];
  charCount = 0;
  selectedCustType = 0;
  showCustTypeOptn = false;
  loggedIn = false;
  showSussess = false;
  showWarning = false;
  countriesList: any;
  productLine:string;
  mailAddress:string;
  countryItems: any = {
    itemGroups: [
      {
        items: [],
      },
    ],
  };
  stateList: any;
  stateItems: any = {
    itemGroups: [
      {
        items: [],
      },
    ],
  };
  currentUrl: string;
  isStateDisabled = true;
  brand: string;
  customerCareUrl = '';
  _allProductLine = AllProductLine;
  jobRolesList: any;
  jobRolesItems: any = {
    itemGroups: [
      {
        items: [],
      },
    ],
  };

  areasOfInterestList: any;
  areasOfInterestItems: any = {
    itemGroups: [
      {
        items: [],
      },
    ],
  };
  userType = 'anonymous';
  isLoading = false;
  constructor(private fb: FormBuilder, public auth: AuthService, private contactUsService: WaygateContactUsService,
    private store: Store<{ router: RouterState }>, public sanitizer: DomSanitizer,
    private custAccService: CustomerAccountService) { }

  ngOnInit() {
    // this.isCheckedProductLines = new Array(this.subProductLines?.length).fill(false);
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      if(this.productLine){
        this.mailAddress = mailToUrl[this.productLine]
      }
    });
    this.breadcrumbs = [
      {
        name: 'Contact Us',
        url: `/${this.productLine}/contactus`,
      },
    ];

    this.store.select('router').subscribe((routerState: any) => {
      this.currentUrl = routerState?.router?.state?.url || '';
      if (this.currentUrl.includes(AllProductLine.waygate)) {
        this.brand = AllProductLine.waygate;
      } else if (this.currentUrl.includes(AllProductLine.bently)) {
        this.brand = AllProductLine.bently;
        this.customerCareUrl = 'https://www.bently.com/support';
      } else if (this.currentUrl.includes(AllProductLine.panametrics)) {
        this.brand = AllProductLine.bently;
        this.customerCareUrl = 'https://www.panametrics.com/support';
      } else if (this.currentUrl.includes(AllProductLine.reuterStokes)) {
        this.brand = AllProductLine.reuterStokes;
      } else if (this.currentUrl.includes(AllProductLine.druck)) {
        this.brand = AllProductLine.druck;
      }
    })

    this.contactusForm = this.fb.group({
      // custType: ['', ''],
      // requestType: ['', ''],
      // requestVal: [''],
      areaOfInterest: [''],

      firstName: [''],
      lastName: [''],
      companyName: [''],
      email: ['', [Validators.required, this.emailValidator()]],
      phoneNum: [''],
      country: [''],
      state: [''],
      title: [''],
      mktoPersonNotes: ['', Validators.required],
      optIn: ['', Validators.required]
    })

    this.contactusForm.get('mktoPersonNotes').valueChanges.subscribe((value) => {
      this.charCount = value.length;
      if (value) {
        let cValue = testRegex(this.sanitizer.sanitize(SecurityContext.HTML, value), REGULAR_PATTERN.alphaNumeric);
        if (cValue != value) {
          this.contactusForm.patchValue(({ mktoPersonNotes: cValue }));
          this.contactusForm.get('mktoPersonNotes').updateValueAndValidity();
        }
      }
    })

    this.auth.isUserLoggedIn().subscribe((success: any) => {
      if (success) {
        this.loggedIn = true;
        this.userType = 'current'
        this.showCustTypeOptn = false;
        this.selectedCustType = 1;
        // this.contactusForm.patchValue({custType: ''});
        this.selectedRequestType = 0; // Defaut selection is 'Invoice'

        this.contactusForm.get('firstName').clearValidators();
        this.contactusForm.get('firstName').setValue('');
        this.contactusForm.get('firstName').markAsUntouched();

        this.contactusForm.get('lastName').clearValidators();
        this.contactusForm.get('lastName').setValue('');
        this.contactusForm.get('lastName').markAsUntouched();

        this.contactusForm.get('companyName').clearValidators();
        this.contactusForm.get('companyName').setValue('');
        this.contactusForm.get('companyName').markAsUntouched();

        this.contactusForm.get('email').clearValidators();
        this.contactusForm.get('email').setValue('');
        this.contactusForm.get('email').markAsUntouched();

        this.contactusForm.get('phoneNum').clearValidators();
        this.contactusForm.get('phoneNum').setValue('');
        this.contactusForm.get('phoneNum').markAsUntouched();

        this.contactusForm.get('country').clearValidators();
        this.contactusForm.get('country').setValue('');
        this.contactusForm.get('country').markAsUntouched();

        this.contactusForm.get('state').clearValidators();
        this.contactusForm.get('state').setValue('');
        this.contactusForm.get('state').markAsUntouched();

        this.contactusForm.get('areaOfInterest').clearValidators();
        this.contactusForm.get('areaOfInterest').setValue('');
        this.contactusForm.get('areaOfInterest').markAsUntouched();

        this.contactusForm.get('title').clearValidators();
        this.contactusForm.get('title').setValue('');
        this.contactusForm.get('title').markAsUntouched();
      } else {

        this.loggedIn = false;
        this.userType = 'anonymous'
        this.showCustTypeOptn = true;
        // this.contactusForm.patchValue({custType: this.custType[this.selectedCustType]});

        this.contactusForm.get('firstName').setValidators([Validators.required]);
        this.contactusForm.get('lastName').setValidators([Validators.required]);
        this.contactusForm.get('companyName').setValidators([Validators.required]);
        this.contactusForm.get('email').setValidators([Validators.required, this.emailValidator()]);
        this.contactusForm.get('phoneNum').setValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
        this.contactusForm.get('country').setValidators([Validators.required]);
        this.contactusForm.get('state').setValidators([Validators.required]);
        this.contactusForm.get('areaOfInterest').setValidators([Validators.required]);
        this.contactusForm.get('title').setValidators([Validators.required]);
      }

      this.contactusForm.get('firstName').updateValueAndValidity();
      this.contactusForm.get('lastName').updateValueAndValidity();
      this.contactusForm.get('companyName').updateValueAndValidity();
      this.contactusForm.get('email').updateValueAndValidity();
      this.contactusForm.get('phoneNum').updateValueAndValidity();
      this.contactusForm.get('country').updateValueAndValidity();
      this.contactusForm.get('state').updateValueAndValidity();
      this.contactusForm.get('areaOfInterest').updateValueAndValidity();
      this.contactusForm.get('title').updateValueAndValidity();


    });
    //--START-> SANITIZE FORM--//
    this.contactusForm.get('firstName').valueChanges.subscribe((value) => {
      if (value) {
        let cValue = testRegex(this.sanitizer.sanitize(SecurityContext.NONE, value), REGULAR_PATTERN.alphaNumericOnlyForAllLang);
        if (cValue != value) {
          this.contactusForm.patchValue(({ firstName: cValue }));
          this.contactusForm.get('firstName').updateValueAndValidity();
        }
      }
    })

    this.contactusForm.get('lastName').valueChanges.subscribe((value) => {
      if (value) {
        let cValue = testRegex(this.sanitizer.sanitize(SecurityContext.NONE, value), REGULAR_PATTERN.alphaNumericOnlyForAllLang);
        if (cValue != value) {
          this.contactusForm.patchValue(({ lastName: cValue }));
          this.contactusForm.get('lastName').updateValueAndValidity();
        }
      }
    })

    this.contactusForm.get('companyName').valueChanges.subscribe((value) => {
      if (value) {
        let cValue = testRegex(this.sanitizer.sanitize(SecurityContext.HTML, value), REGULAR_PATTERN.alphaNumeric);
        if (cValue != value) {
          this.contactusForm.patchValue(({ companyName: cValue }));
          this.contactusForm.get('companyName').updateValueAndValidity();
        }
      }
    })

    //--END-> SANITIZE FORM--//
    this.contactusForm.get('mktoPersonNotes').valueChanges.subscribe((value) => {
      this.charCount = value.length;
    })

    // this.contactusForm.get('requestType').valueChanges.subscribe((value)=>{
    //   if(value == 'Invoice' || value == 'Order' || value == 'Returns'){
    //     this.contactusForm.get('requestVal').setValidators([Validators.required]);
    //   }else{
    //     this.contactusForm.get('requestVal').clearValidators();
    //     this.contactusForm.get('requestVal').setValue('');
    //     this.contactusForm.get('requestVal').markAsUntouched();
    //   }
    //   this.contactusForm.get('requestVal').updateValueAndValidity();

    // })
    this.getCountryList();
    this.getAreasOfIntList();
    this.getJobRolesList();
  }

  get fc() {
    return this.contactusForm.controls;
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value

      if (!value) {
        return null
      }
      const errors: ValidationErrors = {};

      var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

      if (!pattern.test(value)) {
        errors.invalidEmail = true
      }
      if (errors.invalidEmail)
        return Object.keys(errors).length ? errors : null;

      // var res = value.split("@");
      // if (res[1].toUpperCase() == 'bhge.com'.toUpperCase() || res[1].toUpperCase() == 'bakerhughes.com'.toUpperCase()) {
      //   errors.bhEmployee = true
      // }

      // if (value.includes("@yahoo.com") || value.includes("@gmail.com") || value.includes("@hotmail.com")) {
      //   errors.notBussunessEmail = true;
      // }
      return Object.keys(errors).length ? errors : null;
    }
  }

  /* onReqTypeClick(index) {
    this.selectedRequestType = index;
  } */

  /* onCustTypeClick(index){
   // index == 0 => new customer option is selected
   // index == 1 => existing customer option is selected

    this.selectedCustType = index;
    if(index == 1){// existing customer option is selected
      this.selectedRequestType = 0; // Defaut selection is 'Invoice'
      this.contactusForm.patchValue({requestType: this.requestType[this.selectedRequestType]});
    }else{
      this.selectedRequestType = 0;
      this.contactusForm.patchValue({requestType: ''});
    }
  } */

  selectedFiles(event) {
    this.files = event;
  }

  deletedFiles(event) {
    if (this.files.indexOf(event) > -1) {
      this.files.splice(this.files.indexOf(event), 1);
    }
  }

  // onSubProductLineChange(e) {
  //   this.contactusForm.get('areaOfInterest').patchValue(e.detail);
  //   this.contactusForm.get('areaOfInterest').updateValueAndValidity();
  // }
  getCountryList() {
    this.contactUsService.getCountries().subscribe(
      (data) => {
        this.countriesList = data;
        var countries = [];
        this.countriesList &&
          this.countriesList.countries.forEach((country) => {
            countries.push({
              label: country.name,
              value: country.isocode,
            });
          });
        this.countryItems = {
          itemGroups: [
            {
              items: countries,
            },
          ],
        };
      },
      (error) => { }
    );
  }

  getStateList(event, field) {
    let countryCode = event.detail;
    this.contactusForm.patchValue(({ country: countryCode }));
    this.contactusForm.get('country').updateValueAndValidity();

    this.contactUsService.getRegion(countryCode).subscribe(
      (data) => {
        this.stateList = data;
        var states = [];
        this.stateList.regions.forEach((state) => {
          states.push({
            label: state.name,
            value: state.isocode,
          });
        });
        this.stateItems = {
          itemGroups: [
            {
              items: states,
            },
          ],
        };
      },
      (error) => { }
    );
  }

  onChange(event, field) {
    if (field == 'state') {
      this.contactusForm.patchValue(({ state: event.detail }));
      this.contactusForm.get('state').updateValueAndValidity();
    }
    if (field == 'areaOfInterest') {
      this.contactusForm.patchValue(({ areaOfInterest: event.detail }));
      this.contactusForm.get('areaOfInterest').updateValueAndValidity();
    }
    if (field == 'title') {
      this.contactusForm.patchValue(({ title: event.detail }));
      this.contactusForm.get('title').updateValueAndValidity();
    }
  }

  getAreasOfIntList() {
    this.contactUsService.getAreaOfInterest().subscribe(
      (data) => {
        this.areasOfInterestList = data;
        var aresOfInt = [];
        this.areasOfInterestList &&
          this.areasOfInterestList.areaOfInterest.forEach((areas) => {
            aresOfInt.push({
              label: areas.name,
              value: areas.id,
            });
          });
        this.areasOfInterestItems = {
          itemGroups: [
            {
              items: aresOfInt,
            },
          ],
        };
      },
      (error) => { }
    );
  }

  getJobRolesList() {
    this.contactUsService.getJobRoles().subscribe(
      (data) => {
        this.jobRolesList = data;
        var jobRoles = [];
        this.jobRolesList &&
          this.jobRolesList.jobRoles.forEach((roles) => {
            jobRoles.push({
              label: roles.name,
              value: roles.jobRoleId,
            });
          });
        this.jobRolesItems = {
          itemGroups: [
            {
              items: jobRoles,
            },
          ],
        };
      },
      (error) => { }
    );
  }

  close(type) {
    if (type === 'warning') {
      this.showWarning = false;
    }
    if (type === 'success') {
      this.showSussess = false;
    }
  }

  bhDropdownClick(dropdown) {
    if (dropdown == 'country') {
      this.contactusForm.get('country').markAsTouched();
      return;
    }
    if (dropdown == 'areaOfInterest') {
      this.contactusForm.get('areaOfInterest').markAsTouched();
      return;
    }
    if (dropdown == 'state') {
      this.contactusForm.get('state').markAsTouched();
      return;
    }
    if (dropdown == 'title') {
      this.contactusForm.get('title').markAsTouched();
      return;
    }
  }

  onSubmit() {
    // Object.keys(this.contactusForm.controls).forEach(control=>{
    //   const ctl = this.contactusForm.get(control)
    //   console.log(control,ctl?.valid)
    // })
    if (this.contactusForm.valid) {
      // this.loadSpinner = true;
      this.isLoading = true;
      this.contactUsService.saveContactUsDetails(this.contactusForm.value, this.userType).subscribe(
        (data) => {
          this.showSussess = true;
          this.isLoading = false;
          // this.loadSpinner = false;
          this.contactusForm.reset();

          this.contactusForm.patchValue(({ country: '' }));
          this.contactusForm.get('country').updateValueAndValidity();

          this.contactusForm.patchValue(({ state: '' }));
          this.contactusForm.get('state').updateValueAndValidity();

          this.contactusForm.patchValue(({ areaOfInterest: '' }));
          this.contactusForm.get('areaOfInterest').updateValueAndValidity();

          this.contactusForm.patchValue(({ title: '' }));
          this.contactusForm.get('title').updateValueAndValidity();

          window.scrollTo(0, 0);
        },
        (error) => {
          this.showWarning = true;
          this.isLoading = false;
          window.scrollTo(0, 0);
        }
      );

    } else {
      this.isLoading = false;
      Object.values(this.contactusForm.controls).forEach(control => {

        if (control instanceof FormArray) {
          control.markAllAsTouched();
        } else {
          control.markAsTouched();
        }
      })
    }
  }
}