import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/shared/components/breadcrumb/breadcrumb.service';
import { TranslationService } from '@spartacus/core';

@Component({
  selector: 'ds-search-private-folder',
  templateUrl: './search-private-folder.component.html',
  styleUrls: ['./search-private-folder.component.scss']
})
export class SearchPrivateFolderComponent implements OnInit {
  constructor(
    private translate: TranslationService,
    private breadCrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadCrumbService.setBreadCrumbs([]);
    this.translate
      .translate('mySiteEquipment.customer-file.pageTitle')
      .subscribe((res: string) =>
        this.breadCrumbService.setBreadcrumbTitle(res)
      );
  }
}
