<ng-container *ngIf="product ;else loading">
<app-waygate-breadcrumbs [breadcrumbs]="breadcrumbs"></app-waygate-breadcrumbs>
<div class="waygate-product-details-container container">
  <div class="waygate-details-image-container">
    <div class="waygate-details-image" *ngIf="!!productImages?.GALLERY; else missingImage">
    <cx-media
      class="cx-product-image waygate-product-image"
      [container]="selectedImg"
      format="product"
      [alt]="selectedImg?.product?.altText"
    ></cx-media>
      <ul class="list-inline text-center thumbnail-image mb-2">
        <li *ngFor="let gallaryImg of productImages.GALLERY; let i = index" class="d-inline mr-2 mt-2">
          <cx-media
          [class.selected-thumbnail] = "gallaryImg.thumbnail.galleryIndex === selectedGalleryImgIdx"
          class="img-small"
          [container]="gallaryImg"
          format="thumbnail"
          [alt]="gallaryImg.altText"
          (click)="thumbnailImgClicked(gallaryImg, gallaryImg.thumbnail.galleryIndex)"
        ></cx-media>
        </li>
      </ul>
    </div>
    <ng-template #missingImage>
      <div class="waygate-details-image">
        <cx-media
        class="cx-product-image waygate-product-image"
        [container]="product?.images?.PRIMARY"
        format="product"
        [alt]="selectedImg?.summary"
      ></cx-media>
      </div>
    </ng-template>
    <div class="favorite-row">
      <a
        class="favorite-section"
        (click)="onFavoriteClick(product)"
        *ngIf="isLoggedIn"
      >
      <em class="material-icons overlay-fav-icon"
          >{{ favStatus ? 'star' : 'star_border' }}</em
        >
      </a>
    </div>
    <cx-product-image-zoom-trigger></cx-product-image-zoom-trigger>
  </div>
  <div class="waygate-details-product-container">
    <div class="waygate-product-intro">
      <div class="product-name" [innerHTML]="product?.name"></div>
      <div class="product-code">
        <div class="code">
          {{ 'pdp.partNumbers' | cxTranslate }}: <strong>{{ product?.code }}</strong>
        </div>
        <div class="lead-time" *ngIf="product?.leadTime">
          <div>|</div>
          <div>
            {{ 'pdp.leadTime' | cxTranslate }}: <strong>{{ product?.leadTime }} Weeks</strong>
          </div>
        </div>
      </div>
      <ng-container>
        <div
          class="product-short-description"
          [innerHTML]="product?.description"
        ></div>
      </ng-container>
    </div>
    <div *ngIf="isLoggedIn; else priceonRequest">
      <hr />
      <ng-container *ngIf="currentBuyAction == buyActions.CHECK_PRICE">
        <div class="warning-display">
          <span class="material-icons"> info </span
          ><span>
            {{ 'pdp.priceNotFound' | cxTranslate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="currentBuyAction != buyActions.CHECK_PRICE">
        <div
          class="waygate-product-price"
          *ngIf="product?.price || product?.yourPrice"
        >
          <div class="price-display-segment">
            <ng-container *ngIf="product?.price?.value > 0">
              <div id="waygateListPrice" class="waygate-price waygate-list-price">
                <div class="price-label">
                  {{ 'pdp.listPrice' | cxTranslate }}:
                </div>
                <div class="price-value">
                  {{ product?.price?.formattedValue }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="user$ | async as user">
              <ng-container *ngIf="product?.yourPrice?.value > 0">
                <div class="waygate-price">
                  <div class="price-label">
                    {{ 'pdp.price' | cxTranslate }}:
                  </div>
                  <div class="your-price-container">
                  <div class="price-value your-price">
                    {{ product?.yourPrice?.formattedValue }}
                  </div>
                  <!-- START- view price breakup -->
                    <div class="col-6 product-view-breakdown">
                      <!-- Item Price -->
                      <div class="extended-price unit-selling-price">
                        <label>
                          <a
                            #viewBreakUp
                            class="view-breakup-link"
                            (click)="togglePriceBreakup()"
                            ><span>{{ 'buyCart.viewBreakup' | cxTranslate }}</span
                            ><span *ngIf="!breakUpMenu" class="material-icons arrow">
                              expand_more </span
                            ><span *ngIf="breakUpMenu" class="material-icons arrow">
                              expand_less
                            </span></a
                          >
                        </label>
                      </div>
                      <div
                        class="breakup-dropdown p-0"
                        [clickSourceId]="viewBreakUp"
                        (clickOutside)="togglePriceBreakup()"
                        *ngIf="breakUpMenu"
                        #breakupContent
                      >
                        <div class="breakdown-price">
                          <div class="cart-entry-text-bold breakdown-heading p-2">
                            {{ 'pdp.breakdownPrice' | cxTranslate }}

                          </div>
                          <hr class="horizontal-line" />
                          <div class="p-2">
                            <ng-container>
                              <div *ngIf="product?.price?.value" class="extended-price row">
                                <label>{{ 'pdp.listPrice' | cxTranslate }}</label>
                                <div class="cart-entry-text-bold">
                                  <span> {{ product?.price?.formattedValue }}</span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <hr class="horizontal-line" />
                          <div class="p-2">
                            <div *ngIf="product?.yourPrice" class="price-row">
                              <label class="cart-entry-text">{{ 'pdp.netPrice' | cxTranslate }}</label>
                              <span class="cart-entry-text-bold">
                                {{ product?.yourPrice?.formattedValue }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="breakup-dropdown"
                      *ngIf="breakUp"
                      (clickOutside)="closeMenu($event)"
                    ></div>
                  <!-- END- view price breakup -->
                </div>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </ng-container>
      <hr />
      <div class="waygate-add-to-cart">
        <div class="pdp-add-to-cart">
          <!-- <cx-add-to-cart [mode]="'pdp'" [product]="product" [productCode]="product?.code"
            [showQuantity]="true"></cx-add-to-cart> -->
          <app-waygate-add-to-cart
            [product]="product"
            [productCode]="productCode"
            [leadTime]="leadTime"
            [inStock]="inStock"
            [currentBuyAction]="currentBuyAction"
            [currentReturnAction]="currentReturnAction"
            [currentGuestAction]="currentGuestAction"
          ></app-waygate-add-to-cart>
        </div>
      </div>
    </div>
    <ng-template #priceonRequest>
      <div class="waygate-product-price">
        <div class="price-display-segment">
          <div class="waygate-price">
            <div class="price-label">{{ 'pdp.listPrice' | cxTranslate }}:</div>
            <div class="price-on-request">
              <div>{{ 'pdp.priceOnRequest' | cxTranslate }}</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <app-waygate-add-to-cart
        [product]="product"
        [productCode]="productCode"
        [leadTime]="leadTime"
        [inStock]="inStock"
        [currentBuyAction]="currentBuyAction"
        [currentReturnAction]="currentReturnAction"
        [currentGuestAction]="currentGuestAction"
      ></app-waygate-add-to-cart>
    </ng-template>
  </div>
</div>
<div class="product-descriptions-attachments-container">
  <div class="tab-container">
    <div class="tab-header">
      <div class="container">
        <ng-container *ngIf="product?.description?.length > 0">
          <div class="product-details-tab-header active">{{ 'pdp.details' | cxTranslate }}</div>
        </ng-container>
      </div>
    </div>
    <div class="tab-content">
      <div class="container">
        <div
          *ngIf="product?.description?.length > 0"
          class="product-details-tab-content"
          [innerHTML]="product?.description"
        ></div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<ng-template #loading>
  <div class="cx-spinner">
      <cx-spinner></cx-spinner>
  </div>
</ng-template>
