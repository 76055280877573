import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { ApiService } from 'src/app/core/http/api.service'
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core'
import { observeOn, switchMap } from 'rxjs/operators'
import { UserAccountFacade } from '@spartacus/user/account/root'

@Injectable({
  providedIn: 'root',
})
export class SharedCartService {
  maxSavedCartErrorNotification = new BehaviorSubject(false)

  private readonly isCheckAvailabilitySource = new Subject<any>()
  isCheckAvailability$ = this.isCheckAvailabilitySource.asObservable()
  private validateMessage = new BehaviorSubject('')
  private guestEmailAddress = new BehaviorSubject('')
  private $validationvalue = new BehaviorSubject(false);
  currentMessage = this.validateMessage.asObservable()
  getGuestEmailAddress = this.guestEmailAddress.asObservable()
  user$: Observable<unknown>
  userType = ''
  validationvalue : any;
  constructor(
    private apiService: ApiService,
    protected authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private globalMessageService: GlobalMessageService
  ) {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userType = 'current'
          return this.userAccountFacade.get()
        } else {
          this.userType = 'anonymous'
          return of(undefined)
        }
      })
    )

    this.user$.subscribe(
      (res) => {
        if (res) {
          this.userType = 'current'
        } else {
          this.userType = 'anonymous'
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        )
        window.scrollTo(0, 0)
      }
    )
  }

  setGuestEmailAddress(email: string) {
    this.guestEmailAddress.next(email)
  }
  validateForm(validateMsg: string) {
    this.validateMessage.next(validateMsg)
  }

  saveGuestEmail(cartId, param) {
    const params = ['users', this.userType, 'dscheckout', cartId, 'guest']
    const apiUrl = this.apiService.constructUrl(params)
    return this.apiService.postData(apiUrl, param);
  }

  public addCartItemComments(cartId, entryNumber, comment) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'entries',
      entryNumber,
      'updateCartEntryNotes',
    ]
    const apiUrl = this.apiService.constructUrl(params)
    const Obj = {
      entryNotes: comment.entryNotes,
    }
    return this.apiService.putData_options(apiUrl, Obj)
  }

  public updateShipmentType(cartId, Obj) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'changeshipmentmethod',
    ]
    const apiUrl = this.apiService.constructUrl(params)
    return this.apiService.putData_options(apiUrl, Obj)
  }

  public checkAvailability(cartId, entryNumber, Obj) {
    const params = [
      'users',
      this.userType,
      'carts',
      cartId,
      'entries',
      entryNumber,
    ]
    const apiUrl = this.apiService.constructUrl(params)
    return this.apiService.putData_options(apiUrl, Obj)
  }

  public downloadCart(cartId) {
    const params = ['users', this.userType, 'carts', cartId, 'export']
    const apiUrl = this.apiService.constructUrl(params)
    return this.apiService.getData_Excel(apiUrl)
  }

  emitCheckAvailability(flag) {
    this.isCheckAvailabilitySource.next(flag)
  }
  setvalidation(params:boolean) {
    this.$validationvalue.next(params);
  }

  getvalidation(): Observable<any> {
    return this.$validationvalue.asObservable();
  }
}
