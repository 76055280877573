import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CmsService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { interval, Observable, of, Subject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export interface SlideInterface {
  url: string;
  title: string;
}

@Component({
  selector: 'image-slider',
  templateUrl: './imageSlider.component.html',
  styleUrls: ['./imageSlider.component.scss'],
})
export class ImageSliderComponent implements OnInit, OnDestroy { ;

  slideIndex = 0;
  componentArraylist: any = [];
  slidesArr = [];
  baseSiteURL = environment.occBaseUrl;
  constructor(private router: Router,
              private cmsService: CmsService,
              ){}

  
  
  ngOnInit(): void {
    if(this.router.url == '/waygate'){
      this.getContentFromSlots();      
    }
  }

  getContentFromSlots(){
    this.cmsService.getContentSlot('WaygateBannerContentSlot').subscribe(
      (data) => {
        this.componentArraylist = data?.components;
        this.componentArraylist.forEach((component) => {          
          this.cmsService
            .getComponentData(component?.uid)
            .subscribe((slideRes: any) => {
                this.slidesArr.push(slideRes);            
            });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
    
  }

plusSlides(n) {
  this.slideIndex += n;
  
}

currentSlide(n) {
  this.slideIndex = n;
  
}



isInternalLink(url:string){
  return !url.startsWith('https://') && !url.startsWith('https://')

}

handleExternalUrl(url: string){
  window.open(url, '_blank')
}
}
