<div class="waygate-grid-product">
  <div class="waygate-product-top">
    <div class="waygate-product-image-container">
      <a (click)="gtmSelectItemEvent(product)" [routerLink]="['/waygate', 'product', product.code, product.name]">
        <cx-media
          class="cx-product-image waygate-product-image"
          [container]="product.images?.PRIMARY"
          format="product"
          [alt]="product?.summary"
        ></cx-media>
        <ng-container *ngIf="user$ | async as user">
          <a
            class="overlay"
            (click)="
              onFavoriteClick();
              $event.preventDefault();
              $event.stopPropagation()
            "
          >
            <i class="material-icons">
              {{ favorite ? 'star' : 'star_border' }}</i
            >
          </a>
        </ng-container>
      </a>
    </div>
    <div class="waygate-product-body">
      <div class="product-info-section">
        <h4 class="product-name" [innerHTML]="product?.name"></h4>
        <div class="product-code">
          <div>{{ 'plp.partCode' | cxTranslate }} </div>
          <div class="code">{{ product?.code }}</div>
        </div>
        <div
          class="product-description"
          [innerHTML]="product?.description"
        ></div>
      </div>
    </div>
  </div>
  <div class="waygate-product-bottom">
    <ng-container *ngIf="user$ | async as user">
      <div class="waygate-grid-price-row">
        <ng-container
          *ngIf="
            product.price.value > 0 &&
              product.productAccessData.isCustomerBuy != false;
            else noPrice
          "
        >
          <div class="waygate-grid-price-label">{{'waygate.listPrice' | cxTranslate}}</div>
          <div class="waygate-grid-price-value">
            {{ product.price?.formattedValue }}
          </div>
        </ng-container>
        <ng-template #noPrice>
          <!-- <div class="waygate-grid-price-label">Price not found!</div> -->
        </ng-template>
      </div>
    </ng-container>
    <a (click)="gtmSelectItemEvent(product)" [routerLink]="['/waygate', 'product', product.code, product.name]"
      >{{ 'plp.details' | cxTranslate }} ></a
    >
  </div>
</div>
