import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  getStatusCount() {
    const params = ['csr', 'fetchCounts'];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.getData(apiUrl);
  }

  getCustomerList(params) {
    const urlParams = ['csr', 'fetchRequestlist'];
    let apiUrl = this.apiService.constructUrl(urlParams);
    return this.apiService.getData(apiUrl, params);
  }

  getCustomerDetail(accessRequestId) {
    const urlParams = ['csr', 'fetchRequestorDetails'];
    let apiUrl = this.apiService.constructUrl(urlParams);
    return this.apiService.getData(apiUrl, {
      requestAccessId: accessRequestId,
    });
  }

  updateStatus(params) {
    const urlParams = ['csr', 'processWorklist'];
    const apiUrl = this.apiService.constructUrl(urlParams);
    return this.apiService.postQueryData(apiUrl, null, params);
  }
}
