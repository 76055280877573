import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { AuthService } from '@spartacus/core';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { SalesArea } from 'src/app/core/customer-account/store/customer-account.model';
import {
  servicesList,
  industriesList,
  supportList,
  supportListGuest,
 
  loadproductListForGuest,
  quickOrderDisplay,
} from './../../../../shared/products-constants';
import { AllProductLine } from 'src/app/shared/enums/availableProductList.enum';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-waygate-nav-bar',
  templateUrl: './waygate-nav-bar.component.html',
  styleUrls: ['./waygate-nav-bar.component.scss'],
})
export class WaygateNavBarComponent {
  servicesList: any[];
  industriesList: any[];
  supportList: any[];
  @Input() isChooseBrandPage = false;
  supportListGuest: any[];
  industriesOpened: boolean;
  servicesOpened: boolean;
  showProdCat: boolean;
  showEqMenu: boolean;
  user$: Observable<any>;
  showCustomerFiles: any;
  guestSalesAreas$: Observable<SalesArea[]>;
  legalEntities: SalesArea[];
  showSlider = false;
  salesAreaData;
  activeSalesArea;
  selectedItem: string;
  productLine: string;
  waygate: string;
  loggedIn: boolean;
  storedScrollPosition = 0;
  isQuickOrderDisplay:boolean;
  constructor(
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private router: Router,
    private custAccService: CustomerAccountService
  ) {
    this.waygate = AllProductLine.waygate;
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.custAccService.getSliderState.subscribe((slider) => {
      if (slider) {
        this.storedScrollPosition = window.scrollY || 0;
        window.scrollTo(0, 0);
      } else if (slider === false) {
        window.scrollTo(0, this.storedScrollPosition);
      }
      this.showSlider = slider;
    });
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.servicesList = servicesList[this.productLine];
      this.industriesList = industriesList[this.productLine];
      this.supportList = supportList[this.productLine];
      this.supportListGuest = supportListGuest[this.productLine];
      this.isQuickOrderDisplay = quickOrderDisplay[this.productLine];
      if (!this.loggedIn) {
        this.loadProductLineGuestData();
      }
    });

    this.user$.subscribe((res: any) => {
      if (res) {
        this.loggedIn = !!res;
      }
      if (res?.hasOwnProperty('isPrivateFolderExists')) {
        this.showCustomerFiles = res?.isPrivateFolderExists;
      } else {
        this.showCustomerFiles = false;
      }
      if (this.showCustomerFiles) {
        this.supportList[7] = {
          name: 'Customer Files',
          url: '/search-private-folder',
          externalUrl: environment.bynderUrl
        };
      }
    });
    this.guestSalesAreas$ = this.custAccService.getGuestSalesAreas();
    this.guestSalesAreas$.subscribe((res) => {
      this.legalEntities = res;
    });
  }
  toggleIndustries() {
    this.industriesOpened = !this.industriesOpened;
  }
  toggleService() {
    this.servicesOpened = !this.servicesOpened;
  }
  toggleProdCat() {
    this.showProdCat = !this.showProdCat;
  }
  toggleEqMenu() {
    this.showEqMenu = !this.showEqMenu;
  }

  loadProductLineGuestData() {
    let param = loadproductListForGuest[this.productLine];
    // '{"active":true,"address":{"country":{"isocode":"US"},"formattedAddress":"Skaneateles, NY","id":"8822995582999","town":"Skaneateles, NY"},"salesAreaId":"1800_GE_GE","salesAreaName":"Waygate Technologies USA, LP"}';
    if (param) {
      this.custAccService.updateGuestSalesArea(JSON.parse(param));
    }
  }
}
