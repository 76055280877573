import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, ConverterService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomerAccountService } from '../../customer-account/customer-account.service';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import {
  CART_MODIFICATION_NORMALIZER,
  CartModification,
} from '@spartacus/cart/base/root';

@Injectable()
export class DsOccCartEntryAdapter extends OccCartEntryAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    private custAccService: CustomerAccountService
  ) {
    super(http, occEndpointsService, converterService);
  }

  public add(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number = 1
  ): Observable<CartModification> {
    const activeSalesArea =
      this.custAccService.getGuestActiveSalesAreaFromStorage();
    let url = '';
    if (activeSalesArea) {
      url = this.occEndpointsService.buildUrl('addEntries', {
        urlParams: {
          userId,
          cartId,
          quantity,
        },
        queryParams: { guestSalesArea: activeSalesArea.salesAreaId },
      });
    } else {
      url = this.occEndpointsService.buildUrl('addEntries', {
        urlParams: {
          userId,
          cartId,
          quantity,
        },
      });
    }
    // Handle b2b case where the x-www-form-urlencoded is still used
    if (url.includes(`quantity=${quantity}`)) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      return this.http
        .post<CartModification>(
          url,
          {},
          { headers, params: { code: productCode } }
        )
        .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
    }

    const toAdd = {
      quantity,
      product: { code: productCode },
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }
}
