<!-- <div class="new-arrival">
  <span class="new-arrival-label">{{
    'waygate.newArrivalHeading' | cxTranslate
  }}</span>
</div>
<div class="new-arrival-main">
  <div class="row">
    <div class="col-md-6 first-tile">
      <img
        class="img-main"
        src="../../../../assets/img/2023_Small_Army6552.jpg"
        alt="click"
      />
      <div class="centered">
        <span class="centered-text-label"
          >Everest Mentor Visual IQ+ VideoProbe</span
        ><br /><br />
        <span class="centered-text-label-small"
          >The Mentor you know. Plus a whole lot more.</span
        ><br /><br />
        <a
          class="new-arrival-a"
          target="_blank"
          href="https://www.bakerhughes.com/waygate-technologies/remote-visual-inspection-rvi/video-borescopes/everest-mentor-visual-iq-videoprobetm-hd-video-borescope"
          ><bh-button
            type="secondary"
            label="{{ 'waygate.knowMoreBtn' | cxTranslate }}"
            class="know"
            (click)="knowMore()"
          >
            <i class="material-icons arrow-right">keyboard_arrow_right</i>
          </bh-button></a
        >
      </div>
    </div>
    <div class="col-md-6 pr-0">
      <div class="row right-img-col">
        <div class="col-md-12">
          <div>
            <img
              class="img-main"
              style="height: 306px"
              src="../../../../assets/img/newArrival1.png"
              alt="click"
            />
            <div class="centered-tile-2">
              <span class="centered-text-label"
                >Krautkrämer RotoArray comPAct</span
              ><br /><br />
              <span class="centered-text-label-small"
                >The new standard in flaw detection</span
              ><br /><br />
              <a
                class="new-arrival-a"
                target="_blank"
                href="https://www.bakerhughes.com/waygate-technologies/industrial-ultrasonic-testing/portable-ultrasound-equipment/ultrasonic-applications/krautkramer-rotoarray-compact"
              >
                <bh-button
                  type="secondary"
                  label="{{ 'waygate.knowMoreBtn' | cxTranslate }}"
                  class="know"
                  (click)="knowMore()"
                >
                  <i class="material-icons arrow-right">keyboard_arrow_right</i>
                </bh-button></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row right-img-col-1">
        <div class="col-md-6">
          <div>
            <img
              class="img-main"
              style="height: 253px"
              src="../../../../assets/img/newArrival2.png"
              alt="click"
            />
            <div class="centered-tile-3">
              <span class="centered-text-label">Krautkrämer USM 100</span><br />
              <span class="centered-text-label-small"
                >Superior image quality</span
              ><br /><br />
              <a
                class="new-arrival-a"
                target="_blank"
                href="https://www.bakerhughes.com/waygate-technologies/ultrasonic-testing/portable-ultrasound-equipment/ultrasonic-flaw-detectors/krautkramer-usm100"
                ><bh-button
                  type="secondary"
                  label="{{ 'waygate.knowMoreBtn' | cxTranslate }}"
                  class="know"
                  (click)="knowMore()"
                >
                  <i class="material-icons arrow-right">keyboard_arrow_right</i>
                </bh-button></a
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <img
              class="img-main"
              src="../../../../assets/img/newArrival4.png"
              alt="click"
            />
            <div class="centered-tile-4">
              <span class="centered-text-label"
                >Mobile DXR Digital X-Ray Detectors</span
              ><br />
              <span class="centered-text-label-small"
                >Portable DR detectors starts here</span
              ><br /><br />
              <a
                class="new-arrival-a"
                target="_blank"
                href="https://www.bakerhughes.com/waygate-technologies/industrial-radiography-and-ct/computed-digital-radiography-crdr/dxr-digital-xray-detectors  "
                ><bh-button
                  type="secondary"
                  label="{{ 'waygate.knowMoreBtn' | cxTranslate }}"
                  class="know"
                  (click)="knowMore()"
                >
                  <i class="material-icons arrow-right">keyboard_arrow_right</i>
                </bh-button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="new-arrivals-header">
  <div class="header-green-border"></div>
  <h1>{{'waygate.newArrivalHeading' | cxTranslate}}</h1>
</div>
<div class="new-arrival-main" *ngIf="NewArrivalList.length>0">
  <ng-container *ngFor="let item of NewArrivalList;let i=index">
  <div class="arrival-wrapper"  *ngIf="i < 2">
    <div class="arrival-img-1" >
      <a  *ngIf="isInternalLink(item?.urlLink); else externalLinkButton" [routerLink]="item?.urlLink" >

      <img class="custom-arrival-img" src="{{baseSiteURL}}{{item?.media?.url}}" alt="image"></a>
      <ng-template #externalLinkButton>
        <a href="{{item?.urlLink}}" target="_blank">
          <img class="custom-arrival-img" src="{{baseSiteURL}}{{item?.media?.url}}" alt="image">
        </a>
      </ng-template>
    </div>
    </div>
  </ng-container>
</div>
