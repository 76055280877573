<div
  class="inner"
  *ngIf="(facetList$ | async)?.facets as facets"
  [cxFocus]="isDialog ? dialogFocusConfig : {}"
  (esc)="close($event)"
  (click)="block($event)"
>
  <h4>
    {{ 'productList.filterBy.label' | cxTranslate }}
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <cx-icon aria-hidden="true" [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </h4>

  <!-- 
      Here we'd like to introduce configurable facet components, 
      either by using specific configuration or generic sproutlets 
  -->

  <!-- Custom component to display ALL, Buy & Return filters -->
  <ds-product-type-facet
    class="cx-facet multi-select mb-4"
  ></ds-product-type-facet>

  <cx-facet
    *ngFor="let facet of facets"
    #facetRef
    [facet]="facet"
    [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
    (unlock)="expandFacetGroup(facet, facetRef)"
    [class.expanded]="isExpanded(facet) | async"
    [class.collapsed]="isCollapsed(facet) | async"
  ></cx-facet>
</div>
