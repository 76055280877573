import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchDialogService } from '@spartacus/storefront';
import { interval, Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ds-idle-timeout-dialog',
  templateUrl: './idle-timeout-dialog.html',
  styleUrls: ['./idle-timeout-dialog.scss'],
})
export class IdleSessionTimeoutDialogComponent implements OnInit {
  public secondsToDday;

  constructor(
    public launchDialogService: LaunchDialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.launchDialogService.data$.subscribe((data) => {
      this.secondsToDday = data.timeout;
    });

    interval(1000).subscribe((x) => {
      this.secondsToDday = this.secondsToDday - 1;
      if (this.secondsToDday == 0) {
        this.closeModal('logout');
        this.logout();
      }
    });
  }

  closeModal(reason) {
    this.launchDialogService.closeDialog(reason);
    if (reason == 'logout') {
      this.logout();
    }
  }
  logout() {
    this.router.navigate(['logout']);
  }
}
