<ng-container *ngIf="cart$ | async as cart">
  <div class="waygate-cart-container my-3">
    <div class="waygate-cart-action">
      <h1>{{'buyCart.shoppingCart' | cxTranslate}}</h1>
    </div>
    <div class="waygate-cart-action-content">
      <div class="waygate-cart-shipment-preference">
        <div class="waygate-cart-shipment-preference-text mr-3">
          <div>{{'buyCart.selectYourShipment' | cxTranslate}}</div>
          <div class="vs-tooltip-icon">
            <p class="vs-tooltip">
              {{'buyCart.partialShipmentText' | cxTranslate}}
            </p>
          </div>
        </div>
        <div class="waygate-cart-radio-btn mt-2">
          <bh-radio-button
            [attr.checked]="(productLine === productLines.waygate) ? cart?.isShipCompleteOrder : true"
            label="Complete"
            value="'Complete'"
            name="group"
            checked="checked"
            (change)="handleChange($event)"
          ></bh-radio-button>
          <bh-radio-button
            [attr.checked]="(productLine === productLines.waygate) ? (!cart?.isShipCompleteOrder) : false"
            label="Partial"
            value="'Partial'"
            name="group"
            (change)="handleChange($event)"
          ></bh-radio-button>
        </div>
      </div>
      <div class="waygate-cart-action-options">
        <div class="waygate-cart-action-option">
          <a class="action-links mr-4" (click)="printPage()">
            <bh-button
              type="secondary"
              class="waygate-cart-action-option-text"
              label="Print Screen"
            ></bh-button>
            <i class="material-icons-outlined action-option-icon">print</i>
          </a>
        </div>
        <div class="waygate-cart-action-option">
          <a class="action-links mr-4" (click)="downloadCart()">
            <bh-button
              type="secondary"
              class="waygate-cart-action-option-text"
              label="Download CSV"
            ></bh-button>
            <i class="material-icons-outlined action-option-icon">save_alt</i>
          </a>
        </div>
        <div class="waygate-cart-action-option">
          <a class="action-links mr-4" (click)="openSaveCartModal()">
            <bh-button
              type="secondary"
              class="waygate-cart-action-option-text"
              label="Save cart"
            ></bh-button>
            <i class="material-icons-outlined action-option-icon">save</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
