import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  normalizeHttpError,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
} from '@spartacus/core';
import { from, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { ApiService } from 'src/app/core/http/api.service';
import { DefaultProductCode } from '../../model/product-categories.model';
import { ProductCategoriesService } from '../../services/product-categories.service';

import * as fromProductCategoriesAction from '../actions/product-categories.action';

@Injectable()
export class ProductCategoriesEffects {
  salesAreaCheckSubscription: Subscription;
  fetchProductCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProductCategoriesAction.FETCH_ALL_CATEGORIES),
      map(
        (action: fromProductCategoriesAction.FetchAllProductCategories) =>
          action.payload
      ),
      switchMap((payload) => {
        let url = '';
        let apiParams = {};
        url = this.occEndpointsService.buildUrl(
          `/users/${payload}/${DefaultProductCode}`
        );

        if (payload == OCC_USER_ID_ANONYMOUS) {
          const activeSalesArea =
            this.custAccService.getGuestActiveSalesAreaFromStorage();
          apiParams = {
            ...apiParams,
            guestSalesArea: activeSalesArea?.salesAreaId,
          };
        }
        return this.apiService.getData(url, apiParams);
      }),

      map((res: any) => {
        // res['categories'] = res?.categories.sort((a, b) =>
        //   a.code > b.code ? 1 : -1
        // );
        //var index = res?.categories.map(function (x) { return x.name; }).indexOf('Nexus Controls');
        //res?.categories.splice(index,1);
        let panametrics = res?.categories.find((x) => x.name === 'Panametrics');
        let filteredData = res?.categories.filter((x) => {
          return x.name !== 'Panametrics';
        });
        res['categories'] = filteredData.splice(0, filteredData.length);
        res['categories'].splice(1, 0, panametrics);
        return new fromProductCategoriesAction.FetchAllProductCategoriesSuccess(
          {
            ...res['categories'],
          }
        );
      }),
      catchError((err) => {
        return from([
          new fromProductCategoriesAction.FetchAllProductCategoriesFail({
            error: normalizeHttpError(err),
          }),
        ]);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private occEndpointsService: OccEndpointsService,
    private apiService: ApiService,
    private custAccService: CustomerAccountService,
    private productCatService: ProductCategoriesService
  ) {
    this.salesAreaCheckSubscription =
      this.custAccService.getSalesAreaData.subscribe((data) => {
        if (data) {
          this.productCatService.loadDefaultProductCategories(
            OCC_USER_ID_ANONYMOUS
          );
        }
      });
  }
  ngOnDestroy(): void {
    this.salesAreaCheckSubscription?.unsubscribe();
  }
}
