import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, OccEndpointsService, WindowRef } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { CustomerAccount } from 'src/app/core/customer-account/store/customer-account.model';
import { ApiService } from 'src/app/core/http/api.service';
import { AllProductLine, ProductLineHomePageURL, ProductLineStorageKey } from 'src/app/shared/enums/availableProductList.enum';

@Component({
  selector: 'app-choose-brand',
  templateUrl: './choose-brand.component.html',
  styleUrls: ['./choose-brand.component.scss'],
})
export class ChooseBrandComponent implements OnInit {
  availableProductLines = [];
  productLine = AllProductLine;
  customerAct: CustomerAccount;
  currentSalesArea: any
  constructor(
    protected router: Router,
    private custAccountService: CustomerAccountService,
    private occEndpointsService: OccEndpointsService,
    private apiService: ApiService,
    private auth: AuthService,
    private winRef: WindowRef
  ) { }

  ngOnInit() {
    if (this.winRef.localStorage.getItem(ProductLineStorageKey.productLine)) {
      this.winRef.localStorage.removeItem(ProductLineStorageKey.productLine);
    }
    this.custAccountService.setProductLine('');
    this.custAccountService.getCurrentCustomerAccount().subscribe((res) => {
      if (res) {
        this.currentSalesArea = res?.selectedSalesArea?.salesAreaId.split("_")[1];
      } else {
        this.currentSalesArea = '';
      }
    });
    this.auth.isUserLoggedIn().subscribe((isUserLoggedIn) => {
      if (isUserLoggedIn) {
        this.custAccountService
          .getMyProfile()
          .pipe(
            map((res) => {
              return {
                ...res['orgUnit'],
                ...res['recentSalesArea'],
                ...{ visibleCategories: res['visibleCategories'] },
              };
            })
          )
          .subscribe((customerAccount: any) => {
            this.availableProductLines =
              customerAccount?.visibleCategories ?? [];

            this.custAccountService.updateAvaiableProductLine(
              this.availableProductLines
            );

            if (this.availableProductLines?.length === 1) {
              this.custAccountService.setProductLine(
                this.availableProductLines[0]
              );
              this.router.navigate([
                ProductLineHomePageURL[this.availableProductLines[0]],
              ]);
            }
          });
      }
    });
  }

  routeToRedirect(brand: string) {
    this.custAccountService.setProductLine(brand);
    this.router.navigate([ProductLineHomePageURL[brand]]);
  }
}
