import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { AuthService } from '@spartacus/core';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { SalesArea } from 'src/app/core/customer-account/store/customer-account.model';
@Component({
  selector: 'app-waygate-nav-bar',
  templateUrl: './waygate-nav-bar.component.html',
  styleUrls: ['./waygate-nav-bar.component.scss'],
})
export class WaygateNavBarComponent {
  @Input() isChooseBrandPage = false;
  servicesList = [
    {
      name: 'Radiography and CT',
      url: 'https://www.bakerhughes.com/waygate-technologies/radiography-and-ct-services',
      categories: [
        {
          name: 'On Demand Inspection Services',
          url: 'https://www.bakerhughes.com/waygate-technologies/radiography-and-ct-services/ondemand-inspection-services-radiography-ct',
        },

        {
          name: 'Field Services',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-repair-services/field-services-radiography-ct',
        },

        {
          name: 'Services Agreements',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-services-agreements/radiography-ct',
        },

        {
          name: 'Parts & Replacements',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/parts-and-replacements/radiography-ct',
        },

        {
          name: 'Rentals',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/rentals/radiography-ct',
        },
        {
          name: 'System Updates & Upgrades',
          url: ''
          
        }
      ],
    },
    {
      name: 'RVI and Robotics',
      url: 'https://www.bakerhughes.com/waygate-technologies/rvi-and-robotics-services',
      categories: [
        {
          name: 'Robotics-as-a-Service',
          url: 'https://www.bakerhughes.com/waygate-technologies/rvi-and-robotics-services/roboticsasaservice',
        },

        {
          name: 'Inspection Without Limitations',
          url: 'https://www.bakerhughes.com/waygate-technologies/rvi-and-robotics-services/advanced-robotic-services',
        },

        {
          name: 'Flexible RVI Equipment Services',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-services-agreements/rvi-managed-equipment-services-program-flex',
        },

        {
          name: 'VideoProbe™ Rentals & More',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/rentals/visual-inspection',
        },

        {
          name: 'Remote Visual Inspection Equipment House Repairs',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-repair-services/remote-visual-inspection-house-repairs',
        },

        {
          name: 'Visual Care Plan for Video Borescopes',
          url: 'https://www.bakerhughes.com/waygate-technologies/rvi-and-robotics-services/videoprobe-visual-care-plan',
        },
      ],
    },
    {
      name: 'NDT Services & Support',
      url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support',
      categories: [
        {
          name: 'Elevate Your NDT System with Advanced Upgrades',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/system-upgrades',
        },
        {
          name: 'Revolutionize NDT with Remote Service Solutions',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/remote-service',
        },
        {
          name: 'NDT Repair Services for Precision Inspections',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-repair-services',
        },
        {
          name: 'Radiography and CT System Updates',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/software-updates',
        },
        {
          name: 'Streamline NDT Operations with Service Agreements',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-services-agreements',
        },
        {
          name: 'NDT Spare and Replacement Parts',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/parts-and-replacements',
        },
        {
          name: 'NDT Inspection Equipment Rentals',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/rentals',
        },
        {
          name: 'NDT Inspection Services for Industry Excellence',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-inspection-services',
        },
      ],
    },
    {
      name: 'Ultrasound',
      url: 'https://www.bakerhughes.com/waygate-technologies/ultrasound-services',
      categories: [
        {
          name: 'Ultrasonic Testing Consulting from the Industry Leader',
          url: 'https://www.bakerhughes.com/waygate-technologies/ultrasound-services/consulting-services-ultrasound',
        },

        {
          name: 'UT Repair and Calibration Service',
          url: 'https://www.bakerhughes.com/waygate-technologies/ultrasound-services/field-services-ultrasound  ',
        },

        {
          name: 'Specialty Service Agreements for Ultrasonic Testing Equipment',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-services-agreements/ultrasonic-testing',
        },

        {
          name: 'Spare Parts Management',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/parts-and-replacements/ultrasonic-testing',
        },

        {
          name: 'Ultrasonic Testing Equipment Rentals',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/rentals/ultrasonic-testing',
        },

        {
          name: 'System Upgrades for UT Machines',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/system-updates-upgrades-radiography-ct/system-upgrades/ultrasonic-testing',
        },

        {
          name: 'Software Updates for UT Machines',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/system-updates-upgrades-radiography-ct/software-updates/ultrasonic-testing',
        },

        {
          name: 'UT Service Agreements',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-repair-services/ut-calibration-certification',
        },

        {
          name: 'Repairs and Calibration Services by Qualified Experts at our Facilities',
          url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/ndt-repair-services/ut-repair-shop-services',
        },

        {
          name: 'AppLab Service',
          url: 'https://www.bakerhughes.com/waygate-technologies/ultrasound-services/applab-service',
        },
      ],
    },
    {
      name: 'Remote',
      url: 'https://www.bakerhughes.com/waygate-technologies/waygate-technologies-remote-service-contact',
      services: [],
    },
    {
      name: 'Inspection Training Academy',
      url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/waygate-technologies-training-academy',
      services: [],
    },
  ];

  industriesList = [
    {
      name: 'Aerospace',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-aerospace',
      categories:[
        {
          name: 'Ultrasonic Solutions for Aerospace',
          url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-aerospace/ultrasonic-solutions-aerospace'
        },
        {
          name: 'Radiography and CT Solutions for Aerospace Industry',
          url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-aerospace/radiography-and-ct-solutions-aerospace-industry'
        }
        
      ]
    },
    {
      name: 'Electronics',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-electronics',
      categories:[
        {name: 'Radiography and CT Solutions for Electronics Industry',
        url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-electronics/radiography-and-ct-solutions-electronics-industry'}
      ]
    },
    {
      name: 'Automotives',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-automotives',
      categories:[
        {name: 'Ultrasonic Solutions for Automative',
        url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-automotives/ultrasonic-solutions-automotive'},
        {
          name: 'Radiography and CT Solutions for Automotive Industry',
          url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-automotives/radiography-and-ct-solutions-automotive-industry'
        }

      ]

    },
    {
      name: 'Batteries',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-batteries',
    },
    {
      name: 'Energy',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-energy',
      categories:[
        {name: 'Visual Inspection for Power Generation',
        url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-energy/visual-inspection-power-generation'}
      ]
    },
    {
      name: 'Engineering Solutions',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/engineering-solutions',
    },
    {
      name: 'Manufacturing',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-manufacturing',
      categories:[
        {name: 'Radiography and CT Solutions for Additive Manufacturing Industry',
        url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-manufacturing/radiography-and-ct-solutions-additive-manufacturing'}
      ]
    },
    {
      name: 'Medical',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-medical',
    },
    {
      name: 'Oil and Gas',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-oil-and-gas',
      categories:[
        {name: 'Ultrasonic NDT Solutions for Refineries',
         url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-oil-and-gas/ultrasonic-ndt-solutions-refineries'}
      ]
    },
    {
      name: 'Research and Development',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-research-and-development',
    },
    {
      name: 'Transportation',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-transportation',
      categories:[
        {
          name: 'Ultrasonic Solutions for Rail',
          url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-transportation/ultrasonic-solutions-rail'
        }
      ]
    },
  ];
  supportList = [
    {name: 'Track order',
    url: '/waygate/track-order'
  },
  {
    name: 'Contact us',
    url: '/contactus'
  },
  {
    name: 'Other Portals & Links',
    url: '/list-of-portals'
  },
  {
    name: 'Provide feedback',
    url: '/feedback'
  },
  {
    name: 'Training document',
    url: '/training-docs'
  },
  {
    name: 'Returns',
    url: '/rma-tracking'    
  },
  {
    name: 'Calibration & Certificates',
    url: '/waygate/calibration-data',
  },
    // {
    //   name: 'My Saved Equipments',
    //   url: '/my-equipments',
    // },
    // {
    //   name: 'Add New Equipment',
    //   url: '/add-equipment-to-watchlist',
    // },
    // {
    //   name: 'Calibration Data & Certificates',
    //   url: '/calibration-data',
    // },
    // {
    //   name: 'Other Important Links',
    //   url: '/list-of-portals',
    // },
  ];
  supportListGuest = [
    {name: 'Track order',
      url: '/waygate/track-order'
    },
    {
      name: 'Contact us',
      url: '/contactus'
    },
    {
      name: 'Other Portals & Links',
      url: '/list-of-portals'
    },
    {
      name: 'Provide feedback',
      url: '/feedback'
    },
    /*{
      name: 'Training document',        (DE155416 - hide training document link for guest users)
      url: '/training-docs'
    },*/
    {
      name: 'Returns',
      url: '/assets/pdf/BH_RMA_Process_Latest.pdf',
      external: true,
    },
    {
      name: 'Calibration & Certificates',
      url: '/waygate/calibration-data',
    },   
    // {
    //   name: 'Other Important Links',
    //   url: '/list-of-portals',
    // },
  ];
  industriesOpened: boolean;
  servicesOpened: boolean;
  showProdCat: boolean;
  showEqMenu: boolean;
  user$: Observable<any>;
  showCustomerFiles: any;
  guestSalesAreas$: Observable<SalesArea[]>;
  legalEntities: SalesArea[];
  showSlider = false;
  salesAreaData;
  activeSalesArea;
  selectedItem: string;
  storedScrollPosition = 0;
  constructor(
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    private router: Router,
    private custAccService: CustomerAccountService
  ) {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.custAccService.getSliderState.subscribe((slider) => {
      if(slider){
        this.storedScrollPosition = window.scrollY || 0;
        window.scrollTo(0,0)
      }else if(slider === false){
        window.scrollTo(0,this.storedScrollPosition)
      }
      this.showSlider = slider;
    });
    this.loadWaygate();
    this.user$.subscribe((res: any) => {
      if (res?.hasOwnProperty('isPrivateFolderExists')) {
        this.showCustomerFiles = res?.isPrivateFolderExists;
      } else {
        this.showCustomerFiles = false;
      }
      if (this.showCustomerFiles) {
        this.supportList[7] = {
          name: 'Customer Files',
          url: '/search-private-folder',
        };
      }
    });
    this.guestSalesAreas$ = this.custAccService.getGuestSalesAreas();
    this.guestSalesAreas$.subscribe((res) => {
      this.legalEntities = res;
    });
  }
  toggleIndustries() {
    this.industriesOpened = !this.industriesOpened;
  }
  toggleService() {
    this.servicesOpened = !this.servicesOpened;
  }
  toggleProdCat() {
    this.showProdCat = !this.showProdCat;
  }
  toggleEqMenu() {
    this.showEqMenu = !this.showEqMenu;
  }

  loadWaygate() {
    this.selectedItem = 'waygate';
    let param =
      '{"active":true,"address":{"country":{"isocode":"US"},"formattedAddress":"Skaneateles, NY","id":"8822995582999","town":"Skaneateles, NY"},"salesAreaId":"1800_GE_GE","salesAreaName":"Waygate Technologies USA, LP"}';
    this.custAccService.updateGuestSalesArea(JSON.parse(param));
  }
}
