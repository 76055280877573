import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-arrivals',
  templateUrl: './new-arrivals.component.html',
  styleUrls: ['./new-arrivals.component.scss']
})
export class NewArrivalsComponent implements OnInit, OnDestroy {
  // slides = [
  //   { url: '../../../../assets/img/new-arrival-1.svg', title: 'Krautkrämer USM 100', partnumber:'113-842-600', description:'Sleek but rugged, the Krautkrämer USM 100 is a portable, highly versatile flaw detector with smartphone-like capabilities. Designed ergonomically for unrivaled ease of use.', price:'Starting from £12,999.99 per unit' },
  //   { url: '../../../../assets/img/new-arrival-1.svg', title: 'Krautkrämer USM 100', partnumber:'113-842-600', description:'Sleek but rugged, the Krautkrämer USM 100 is a portable, highly versatile flaw detector with smartphone-like capabilities. Designed ergonomically for unrivaled ease of use.', price:'Starting from £12,999.99 per unit' },
  // ];
  componentArraylist: any = [];
  NewArrivalList = [];
  baseSiteURL = environment.occBaseUrl;
  constructor(private router: Router,
              private cmsService: CmsService,
              ){}

  ngOnInit(): void {
    if(this.router.url == '/waygate'){
      this.getContentFromSlots();      
    }
  }


  getContentFromSlots(){
    this.cmsService.getContentSlot('WaygateNewArrivalSlot').subscribe(
      (data) => {
        this.componentArraylist = data?.components;
        this.componentArraylist.forEach((component) => {          
          this.cmsService
            .getComponentData(component?.uid)
            .subscribe((slideRes: any) => {              
              const  contentArr = slideRes.content?.split('<br/>');
              const [partnumber, description, price] = contentArr;
              const item = {...slideRes, partnumber, description, price};
                this.NewArrivalList.push(item);            
            });
        });
      },
      (error) => {
        console.log(error);
      }
    );
    console.log(this.NewArrivalList)
  }

  isInternalLink(url:string){
    return !url.startsWith('https://') && !url.startsWith('https://')
  
  }
  
 
  knowMore() { }
  ngOnDestroy(): void {
    
  }
}
