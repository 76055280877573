export enum AllProductLine {
  waygate = `waygate`,
  bently = `bently-nevada`,
  panametrics = `panametrics`,
  druck = `druck`,
  reuterStokes = `reuter-stokes`,
}

export const ProductLineHomePageURL = {
  [AllProductLine.waygate]: `/waygate`,
  [AllProductLine.druck]: `/homepage`,
  [AllProductLine.bently]: `/bently-nevada`,
  [AllProductLine.panametrics]: `/homepage`,
  [AllProductLine.reuterStokes]: `/homepage`,
};
export enum ProductLineStorageKey{
  productLine = `productLine`,
  avaiableProductLine = `avaiableProductLine`
}

export const RegisterUrl = {
  Waygate : `${ProductLineHomePageURL[AllProductLine.waygate]}/register`,
  WaygateRegisterProgress : `${ProductLineHomePageURL[AllProductLine.waygate]}/register/progress-page`,

  Bently : `${ProductLineHomePageURL[AllProductLine.bently]}/register`,
  BentlyRegisterProgress : `${ProductLineHomePageURL[AllProductLine.bently]}/register/progress-page`,

  ReuterStokes: `${ProductLineHomePageURL[AllProductLine.reuterStokes]}/register`,
  ReuterStokesRegisterProgress : `${ProductLineHomePageURL[AllProductLine.reuterStokes]}/register/progress-page`,

  Druck :`${ProductLineHomePageURL[AllProductLine.druck]}/register`,
  DruckRegisterProgress : `${ProductLineHomePageURL[AllProductLine.druck]}/register/progress-page`,

  Panametrics : `${ProductLineHomePageURL[AllProductLine.panametrics]}/register`,
  PanametricsRegisterProgress : `${ProductLineHomePageURL[AllProductLine.panametrics]}/register/progress-page`,

}

export const PlApiURL = {
  [RegisterUrl.Waygate]: `${AllProductLine.waygate}`,
  [RegisterUrl.Bently]: `${AllProductLine.bently}`,
  [RegisterUrl.Druck]: `${AllProductLine.druck}`,
  [RegisterUrl.Panametrics]: `${AllProductLine.panametrics}`,
  [RegisterUrl.ReuterStokes]: `${AllProductLine.reuterStokes}`,
}