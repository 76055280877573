import { Component, Input } from '@angular/core';
import { OrderStatusName } from 'src/app/shared/models/status/order-status.model';

@Component({
  selector: 'app-waygate-order-product',
  templateUrl: './waygate-order-product.component.html',
  styleUrls: ['./waygate-order-product.component.scss'],
})
export class WaygateOrderProductComponent {
  @Input() product;
  @Input() userType;
  getClass = (orderStatus) => {
    return orderStatus.replace(/\s/g, '').replace(/\&/g, '');
  };

  /**
   * @description conpaire the selected status and if it is shipped the return true otherwise false
   * @returns boolean true/false
   */
  get statusIsShipped(): boolean {
    return [OrderStatusName.SHIPPED, OrderStatusName.INVOICED].includes(
      this.product?.status
    )
      ? true
      : false;
  }

  getUnitNetPrice(product) {
    if (product?.netPrice) {
      if (product?.qty > 1) {
        return Number(product?.netPrice / product.qty).toFixed(2);
      } else {
        return Number(product?.netPrice).toFixed(2);
      }
    }
  }

  getTotalPrice(totalPrice) {
    return Number(totalPrice).toFixed(2);
  }
}
