import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { RoutingService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartNotEmptyGuardClass {
  constructor(
    protected routingService: RoutingService,
    protected activeCartFacade: ActiveCartFacade
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.activeCartFacade.getActive(),
      this.activeCartFacade.isStable(),
    ]).pipe(
      filter(([_, loaded]) => loaded),
      map(([cart]) => {
        if (this.isEmpty(cart)) {
          this.routingService.go({ cxRoute: 'home' });
          return false;
        }
        return true;
      })
    );
  }

  private isEmpty(cart: Cart): boolean {
    return cart && !cart.totalItems;
  }
}

export const CartNotEmptyGuard: CanActivateFn = (): Observable<boolean> => {
  return inject(CartNotEmptyGuardClass).canActivate()
}