import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';

@Injectable({
  providedIn: 'root',
})
export class WaygateContactUsService {
  constructor(private apiService: ApiService) { }

  saveContactUsDetails(detailsObj, userType) {
    const apiParams = ['users', userType, 'contactus'];
    const API_URL = this.apiService.constructUrl(apiParams);
    return this.apiService.postData(API_URL, detailsObj);
  }

  getCountries() {
    const apiParams = ['dscountries'];
    const url = this.apiService.constructUrl(apiParams);
    return this.apiService.getData(url);
  }


  getRegion(countryCode) {
    const params = ['dscountries', countryCode, 'dsregions'];
    const apiUrl = this.apiService.constructUrl(params);
    const apiParams = { countryIso: countryCode };
    return this.apiService.getData(apiUrl, apiParams);
  }

  getJobRoles() {
    return this.apiService.getData('assets/job-roles.json');
  }

  getAreaOfInterest() {
    return this.apiService.getData('assets/area-of-interest.json');
  }
}
