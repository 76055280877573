import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import {
  AuthService,
  ConverterService,
  Occ,
  OccEndpointsService,
  OccProductAdapter,
  OccRequestsOptimizerService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  Product,
  PRODUCT_NORMALIZER,
  ScopedDataWithUrl,
  ScopedProductData,
} from '@spartacus/core';

import { CustomerAccountService } from '../../customer-account/customer-account.service';
import { ProductCategoriesService } from '../services/product-categories.service';
import { ProductCatelogService } from '../services/product-catelog.service';
import { switchMap } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable()
export class DsOccProductAdapter extends OccProductAdapter {
  userType: string;
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
    private custAccService: CustomerAccountService,
    private auth: AuthService,
    private authService: AuthService,
    private custAccountService: CustomerAccountService,
    private productCatService: ProductCatelogService,
    private userAccountFacade: UserAccountFacade
  ) {
    super(http, occEndpoints, converter, requestsOptimizer);

    this.userLoggedIn$.subscribe((res) => {
      if (res) {
        this.userType = OCC_USER_ID_CURRENT;
        return this.userAccountFacade.get();
      } else {
        this.userType = OCC_USER_ID_ANONYMOUS;
        return of(undefined);
      }
    });
  }

  load(productCode: string, scope?: string): Observable<Product> {
    return this.http
      .get(this.getEndpoint(productCode, scope))
      .pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
  }

  loadMany(products: ScopedProductData[]): ScopedProductData[] {
    const scopedDataWithUrls: ScopedDataWithUrl[] = products.map((model) => ({
      scopedData: model,
      url: this.getEndpoint(model.code, model.scope),
    }));
    return this.requestsOptimizer
      .scopedDataLoad<Occ.Product>(scopedDataWithUrls)
      .map(
        (scopedProduct) =>
          ({
            ...scopedProduct,
            data$: scopedProduct.data$.pipe(
              this.converter.pipeable(PRODUCT_NORMALIZER)
            ),
          } as ScopedProductData)
      );
  }

  protected getEndpoint(code: string, scope?: string): string {
    const activeSalesArea =
      this.custAccService.getGuestActiveSalesAreaFromStorage();
    if (this.userType == OCC_USER_ID_CURRENT) {
      return this.occEndpoints.buildUrl(
        'product',

        {
          urlParams: {
            userId: OCC_USER_ID_CURRENT,
          },

          queryParams: {
            productCode: code,
          },
          scope,
        }
      );
    } else {
      if (activeSalesArea) {
        return this.occEndpoints.buildUrl('product', {
          urlParams: {
            userId: OCC_USER_ID_ANONYMOUS,
          },
          queryParams: {
            productCode: code,
            guestSalesArea: activeSalesArea.salesAreaId,
          },
          scope,
        });
      } else {
        this.loadWaygate();
        let param = this.getWaygate();
        return this.occEndpoints.buildUrl('product', {
          urlParams: {
            userId: OCC_USER_ID_ANONYMOUS,
          },
          queryParams: {
            productCode: code,
            guestSalesArea: JSON.parse(param).salesAreaId,
          },
          scope,
        });
      }
    }
  }

  loadWaygate() {
    let param = this.getWaygate();
    this.custAccService.updateGuestSalesArea(JSON.parse(param));
  }

  getWaygate(): any {
    return '{"active":true,"address":{"country":{"isocode":"US"},"formattedAddress":"Skaneateles, NY","id":"8822995582999","town":"Skaneateles, NY"},"salesAreaId":"1800_GE_GE","salesAreaName":"Waygate Technologies USA, LP"}';
  }
}
