<div class="header-main">
  <div class="top-header-waygate container">
    <div class="top-header-logo">
      <div class="bh-logo">
        <img *ngIf="!isChooseBrandPage"
          routerLink="/waygate"
          class="bh-img" 
          src="../../../../../assets/img/bh-logo.svg"
          alt="click"
        />
      </div>
    </div>

    <div class="bh-sitelinks">
      <nav class="navigation-menu bh-right-menu">
        <ul class="menu right-menu link-anchor">
          <li
            class="contact-us-li"
            #productListTempRef
            *ngIf="availableProductLines && availableProductLines.length > 1"
            (click)="toggleBrandList($event)"
          >
            <span class="link-anchor">{{
              'waygate.brandsHeader' | cxTranslate
            }}</span>
            <span class="material-icons" *ngIf="!showBrandList" >
              keyboard_arrow_down
            </span>
            <span class="material-icons" *ngIf="showBrandList">
              keyboard_arrow_up
          </span>
          </li>
          <li class="contact-us-li" *ngIf="!isChooseBrandPage">
            <span routerLink="/waygate/track-order" class="link-anchor">{{
              'waygate.trackOrder' | cxTranslate
            }}</span>
          </li>

          <li class="contact-us-li">
            <span routerLink="/contactus" class="link-anchor">{{
              'waygate.contactUs' | cxTranslate
            }}</span>
          </li>
          <li class="lang-dd-menu">
            <bh-dropdown
              (click)="languagedropdown()"
              class="langDD nav-content link-anchor"
              isFluid="true"
              isSmall="true"
              menuWidth="fluid"
              unselectable="false"
              [menuItems]="props"
              [value]="currentLanguage"
              (selected)="onLanguageChange($event)"
            ></bh-dropdown>
            <span class="material-icons lang-icon nav-content link-anchor"
              >language</span
            >
          </li>
          <!-- <li class="help-li" #helpMenuLink>
            <div class="help-menu link-anchor">
              <span class="help" (click)="togglehelp()">{{
                'navigation-menu.help' | cxTranslate
              }}</span>
              <span
                class="material-icons help-down-arrow"
                (click)="togglehelp()"
              >
                keyboard_arrow_down
              </span>
              <div
                class="help-menu-content"
                [clickSourceId]="helpMenuLink"
                *ngIf="helpOpened"
                (clickOutside)="togglehelp()"
              >
                <p>
                  <bh-a
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    type="primary"
                    routerLink="/contactus"
                    text="{{ 'navigation-menu.contactUs' | cxTranslate }}"
                    (click)="togglehelp()"
                  >
                  </bh-a>
                </p>
                <p>
                  <bh-a
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    type="primary"
                    routerLink="/list-of-portals"
                    text="{{ 'navigation-menu.link' | cxTranslate }}"
                    (click)="togglehelp()"
                  >
                  </bh-a>
                </p>
                <p>
                  <bh-a
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    type="primary"
                    (click)="togglehelp()"
                    routerLink="/feedback"
                    text="{{ 'navigation-menu.provideFeedback' | cxTranslate }}"
                  >
                  </bh-a>
                </p>
                <p>
                  <bh-a
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    type="primary"
                    (click)="togglehelp()"
                    routerLink="/training-docs"
                    text="{{ 'navigation-menu.trainingDocs' | cxTranslate }}"
                  >
                  </bh-a>
                </p>
              </div>
            </div>
          </li> -->
        </ul>
      </nav>
    </div>
  </div>
</div>

<ng-container *ngIf="availableProductLines && availableProductLines.length > 1">
  <app-choose-brand-list
    *ngIf="showBrandList"
    [clickSourceId]="productListTempRef.nativeElement"
    (clickOutside)="showBrandList = false"
    (brandSelected)="showBrandList = false"
  ></app-choose-brand-list>
</ng-container>

<div class="waygate-header-filled"></div>
<div class="waygate-header-main-section">
  <div class="header-waygate-main container-fluid" [class.header-fixed]="fixed">
    <div class="container">
      <div class="header-waygate-wrapper">
        <ng-container *ngIf="!isChooseBrandPage; else chooseBrandPageTemplate">
          <img
            class="waygate-logo"
            src="../../../../../assets/img/waygateTechLogo.svg"
            alt="click"
            (click)="WaygatePage()"
          />
        </ng-container>
        <div class="waygate-search-input-container" *ngIf="!isChooseBrandPage">
          <!-- <cx-searchbox></cx-searchbox> -->
          <input
            type="text"
            class="waygate-search-input"
            (click)="openSearch()"
            #searchFocusInput
            placeholder="Search by product name / part number"
          /><span class="material-icons waygate-search-icon"> search </span>
        </div>
        <div>
          <div class="header-waygate-right">
            <ng-container *ngIf="currentCustomerAccount$ | async; else loading">
              <div
                class="cart-main"
                *ngIf="isLoggedInUser && cart$ | async as cart"
              >
                <!-- <a class="shopping-cart" (click)="openSlider()">
                  <span class="material-icons-outlined bell-icon unread">
                    notifications
                  </span>
                </a> -->
                <a
                  class="shopping-cart"
                  (click)="openMenu = !openMenu"
                  #cartIcon
                >
                  <img
                    class=""
                    src="../../../../../assets/img/shoppingCartWaygate.svg"
                    alt="click"
                  />
                  <!-- <span class="count"> -->
                  <b class="count">{{
                    cart?.commerceType == commerceTypes.RETURNS
                      ? (cart?.returnsCartData | rmaProduct)?.length ||
                        totalNumberOfCart
                      : cart.totalItems || totalNumberOfCart
                  }}</b>
                  <!-- </span> -->
                </a>
                <div
                  class="mini-cart"
                  clickOutside
                  [clickSourceId]="cartIcon"
                  (clickOutside)="openMenu = !openMenu"
                  *ngIf="openMenu"
                >
                  <ds-mini-cart-details
                    class="mini-cart-details"
                    [cart]="cart"
                    (closeMenu)="closeMenu($event)"
                    [showWaygate]="showWaygate"
                  ></ds-mini-cart-details>
                </div>
              </div>
            </ng-container>

            <div class="user-details-container">
              <app-waygate-user-details></app-waygate-user-details>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-waygate-notification-slider
      *ngIf="showSlider"
      [toggleMarging]="fixed"
      (closeSlider)="showSlider = false"
    ></app-waygate-notification-slider> -->
  </div>
  <app-waygate-nav-bar
    [isChooseBrandPage]="isChooseBrandPage"
  ></app-waygate-nav-bar>
</div>

<ng-template #chooseBrandPageTemplate>
  <img
    class="waygate-logo"
    src="../../../../../assets/img/bh-logo-dark-green.png"
    alt="click" width="200"
  />
</ng-template>
<ng-template #loading class="mini-cart-loader-header">
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
