import { Component, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import {
  AuthRedirectStorageService,
  AuthService,
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  WindowRef,
  SiteContextActions,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import 'node_modules/frog-bh-ui-toolkit';
import { mergeMap, take } from 'rxjs/operators';
import { DSAuthService } from './core/auth/ds-auth.service';
import { CustomerAccountService } from './core/customer-account/customer-account.service';
import { ApiService } from './core/http/api.service';
import { GoogleTagManagerService } from './shared/services/gtm.service';
import { UserRoleService } from './shared/services/user-role.service';
import { DS_DIALOG } from './core/dialog/dialog.config';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AllProductLine,ProductLineStorageKey } from './shared/enums/availableProductList.enum';


// tslint:disable-next-line: ban-types
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'Dsstore';
  public data: any;
  loggedIn: boolean;
  userType: string;
  cartId: string;
  count = 0;
  prevBool = false;

  // timeout code
  idleState = 'Not started.';
  idleTimeoutStart = false;
  timedOut = false;
  lastPing?: Date = null;

  idleTimeoutModalRef = null;
  currentCustAccount;

  constructor(
    private authRedirectStorageService: AuthRedirectStorageService,
    private idle: Idle,
    private launchDialogService: LaunchDialogService,
    public auth: AuthService,
    private globalMessageService: GlobalMessageService,
    private authStorageService: AuthStorageService,
    private http: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private custAccService: CustomerAccountService,
    private userRoleService: UserRoleService,
    private dsAuthService: DSAuthService,
    private sanitizer: DomSanitizer,
    private windowRef: WindowRef,
    private store: Store
  ) {
    this.authRedirectStorageService.setRedirectUrl('homepage');
    this.auth.isUserLoggedIn().subscribe((success: any) => {
      this.loggedIn = success;
      if (this.loggedIn) {
        this.setIdleTimerSettings();
        const localSelectedBrand =
          this.windowRef.localStorage.getItem(
            ProductLineStorageKey.productLine
          ) || '';
        const localAvaiableProductLine =
          this.custAccService.getAvaiableProductLineToFromStorage() || [];
        if (localAvaiableProductLine?.length) {
          this.custAccService.updateAvaiableProductLine(
            localAvaiableProductLine
          );
        }
        if (localSelectedBrand?.length) {
          this.custAccService.setProductLine(localSelectedBrand);
        }
      }
    });

    const userType = this.loggedIn
      ? OCC_USER_ID_CURRENT
      : OCC_USER_ID_ANONYMOUS;
    this.userRoleService.getCurrentUserRole(userType);
    /* GTM Snippet */
    this.custAccService
      .getCurrentCustomerAccount()
      .pipe(
        mergeMap((res: any) => {
          this.currentCustAccount = res;
          if (res.currency) {
            this.store.dispatch(
              new SiteContextActions.SetActiveCurrency(res?.currency?.isocode)
            );
          }
          return this.router.events;
        })
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (Object.keys(this.currentCustAccount).length > 0) {
            const userType = this.dsAuthService.getUserTypeFromStorage();
            this.gtmService.sendEvent({
              event: 'pageView',
              userType: userType,
              pagePath: event.urlAfterRedirects,
            });
          } else if (!this.loggedIn) {
            this.gtmService.sendEvent({
              event: 'pageView',
              userType: 'Guest',
              pagePath: event.urlAfterRedirects,
            });
          }
        }
      });

    /* GTM Snippet */
  }

  setIdleTimerSettings() {
    // Timeout code
    // sets an idle timeout of 30 mins(1800 seconds), for testing purposes.
    this.idle.setIdle(1800);
    // sets a timeout period of 30 mins. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      if (this.idleTimeoutModalRef) {
        return;
      }
      this.idleState = 'No longer idle.';
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });

    this.idle.onIdleStart.pipe(take(1)).subscribe(() => {
      this.idleState = "You've gone idle!";
      this.idle.stop();
      this.openSessionTimeoutDialog();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // Sets idle watchers
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  openSessionTimeoutDialog() {
    const componentdata = {
      timeout: 60,
    };
    this.idleTimeoutModalRef = this.launchDialogService.openDialog(
      DS_DIALOG.IDLE_TIMEOUT,
      undefined,
      undefined,
      componentdata
    );
    if (this.idleTimeoutModalRef) {
      this.idleTimeoutModalRef.pipe(take(1)).subscribe((value) => {
        if (value != undefined) {
          if (value == 'logout') {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.logout();
          } else {
            this.reset();
          }
        }
      });
      this.launchDialogService.dialogClose.subscribe((value) => {
        if (value != undefined) {
          if (value == 'logout') {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.logout();
          } else {
            this.reset();
          }
        }
      });
    }
  }

  logout() {
    this.idle.stop();
    // this.router.navigate(['logout']);
    this.authStorageService
      .getToken()
      .pipe()
      .subscribe(
        (data) => {
          if (data && data.access_token) {
            if (data.access_token) {
              const url = `dslogin/revoke/${data.access_token}`;
              const loginUrl = this.occEndpointsService.buildUrl(url);
              this.http
                .get(loginUrl, { responseType: 'text' as 'json' })
                .subscribe(
                  (res: any) => {
                    if (res) {
                      const userType = OCC_USER_ID_ANONYMOUS;
                      this.userRoleService.getCurrentUserRole(userType);
                      localStorage.clear();
                      let returnUrl = res?.split('?')[1]?.split('=')[1];
                      returnUrl = returnUrl
                        ? returnUrl
                        : this.sanitizer.sanitize(
                            SecurityContext.URL,
                            window.location.origin
                          );
                      window.location.href = this.sanitizer.sanitize(
                        SecurityContext.URL,
                        returnUrl
                      );
                    } else {
                      this.globalMessageService.add(
                        'Something went wrong on logout.',
                        GlobalMessageType.MSG_TYPE_ERROR,
                        10000
                      );
                    }
                  },
                  (err) => {
                    this.globalMessageService.add(
                      'Something went wrong on logout.',
                      GlobalMessageType.MSG_TYPE_ERROR,
                      10000
                    );
                  }
                );
            }
          }
        },
        (error) => {
          this.globalMessageService.add(
            error,
            GlobalMessageType.MSG_TYPE_ERROR,
            5000
          );
          window.scrollTo(0, 0);
        }
      );
  }
  ngOnDestroy(): void {
    this.windowRef.nativeWindow.sessionStorage.clear();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof ResolveEnd) {      
      if (!this.loggedIn) {        
        if (evt.url.includes(`/${AllProductLine.waygate}`)) {          
          this.custAccService.setProductLine(AllProductLine.waygate);
        } else if (evt.url.includes(`/${AllProductLine.bently}`)) {          
          this.custAccService.setProductLine(AllProductLine.bently);
        }
      } 
    }

    });
  }
}
