import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'translate'
})
export class I18Pipe implements PipeTransform {

  constructor(private ts: TranslateService) { }

  transform(value: any, args?: any): any {
    if (args && this.ts.translate[args]) {
      return this.ts.translate[args] + environment.language_symbol;
    }
    return value;
  }

}
