<div class="waygate-complaince-questions-main mt-4">
  <div class="waygate-complaince-questions-heading">{{'waygate.complianceCheck' | cxTranslate}}</div>
  <form (ngSubmit)="onSubmit()">
    <div class="waygate-complaince-questions">
      <div class="mb-2">
        <ul>
          <li>
            <label class="question-text mb-2">
              {{'waygate.complianceQuestionText' | cxTranslate}}
            </label>
          </li>
          <li>
            <label class="question-text mb-2">
              {{'waygate.nuclearOpportunity' | cxTranslate}}
            </label>
          </li>
          <li>
            <label class="question-text mb-2">
              {{'waygate.materialsOrder' | cxTranslate}}
            </label>
          </li>
          <li>
            <label class="question-text mb-2">
              {{'waygate.governmentAgency' | cxTranslate}}
            </label>
          </li>
        </ul>
        <div>
          {{'waygate.applyToThisOrder' | cxTranslate}}
        </div>
        <div class="radio-btn">
          <bh-radio-button
            label="Yes"
            value="true"
            name="radiobtnbuy"
            (change)="handleChange($event, 'radiobtnbuy')"
          ></bh-radio-button>

          <bh-radio-button
            label="No"
            value="false"
            name="radiobtnbuy"
            (change)="handleChange($event, 'radiobtnbuy')"
          ></bh-radio-button>
        </div>
        <div
          class="err-msg-css"
          *ngIf="submitted && !radiobtnbuy"
        >
          <i class="typography--icon-small bh-form-message__icon err-msg-icon-css">error_outline</i
          >{{'waygate.itIsMandatory' | cxTranslate}}
        </div>
      </div>
    </div>
  </form>
</div>
