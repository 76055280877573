import { Component, ViewChild } from '@angular/core';
import { QuickOrderService } from './quick-order.service';
import { QuickOrderProductsComponent } from './quick-order-products/quick-order-products.component';

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent {
  sharedData: any;
  textChange;
  enableAddToCart = false;
  showLoader: boolean = false;
  showInvalidEntryMsg: boolean = false;
  showObsoluteMsg: boolean = false;
  deletedRow: number;
  @ViewChild(QuickOrderProductsComponent, { static: false })
  private quickOrderProductsComponent: QuickOrderProductsComponent;
  handleData(data: any) {
    if (data?.data?.validatedBulkUploadList.length > 0) {
      this.sharedData = data;
      // this.showInvalidEntryMsg = false;
      this.enableAddToCart = this.sharedData?.data?.validatedBulkUploadList?.filter(item => item.status === 'Check Price' || item.status === 'Validated').length > 0;
      this.showInvalidEntryMsg = this.sharedData?.data?.validatedBulkUploadList?.filter(item => !item.productAccessData?.isBuy).length > 0;
    } else {
      this.sharedData = data;
      this.enableAddToCart = false;
      this.showInvalidEntryMsg = true;
      this.showObsoluteMsg = false;
    }
  }
  addToCart() {
    this.showLoader = true;
    this.quickOrderProductsComponent.addToCart();
  }
  partsCopied(event) {
    this.textChange = event;
  }
  enableCart(data) {
    this.enableAddToCart = data;
  }
  deleteRow(deletedRow: any) {
    this.deletedRow = deletedRow;
  }
}