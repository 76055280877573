import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CmsService,
  ContentSlotData,
  ConverterService,
  GlobalMessageService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  PRODUCT_NORMALIZER,
  WindowRef,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { ApiService } from 'src/app/core/http/api.service';
import {
  GtmEvents,
  ItemListTypeEnum,
  StoreType,
  StoreTypeEnum,
} from 'src/app/shared/enums/gtm.enum';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';

export enum DsProductDetails {
  DSPRODUCTSFEATURED = 'FEATURED',
}
@Component({
  selector: 'app-featured-prods',
  templateUrl: './featured-prods.component.html',
  styleUrls: ['./featured-prods.component.scss'],
})
export class FeaturedProdsComponent implements OnInit {
  user$: Observable<unknown>;
  dsProductDetails = DsProductDetails;
  user: any;
  componentArraylist: any = [];
  productListItem: any = [];
  totalCards: number;
  currentPage: number = 1;
  cardsPerPage: number;
  totalPages: number;
  overflowWidth: string;
  cardWidth: string = '25%';
  pagePosition: string = '0%';
  timer: any;
  productsAPI: boolean = false;
  wayGateUrl = '/waygate';
  contentSlot$: Observable<ContentSlotData>;
  activeSalesArea: string;
  customerAccount: string;
  componentLoad: boolean = false;
  showLoading: boolean = false;
  selectedCategory = '';
  categories = [];
  @HostListener('window:resize') windowResize() {
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
      this.populatePagePosition();
    }
  }

  constructor(
    private cmsService: CmsService,
    private apiService: ApiService,
    private windowRef: WindowRef,
    private cref: ChangeDetectorRef,
    private converterService: ConverterService,
    private userAccountFacade: UserAccountFacade,
    private authService: AuthService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private custAccService: CustomerAccountService
  ) {}

  ngOnInit(): void {
    if (this.router.url == '/waygate') this.productsAPI = true;
    this.componentLoad = true;
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
    this.showLoading = true;
    this.contentSlot$ = this.cmsService.getContentSlot(
      'DSWaygateProductsContentSlot1'
    );
    combineLatest([this.user$, this.contentSlot$]).subscribe(
      (data) => {
        if (data.length > 0) {
          if (data[0]) {
            this.user = data[0];
          }
          this.componentArraylist = data[1]?.components;
          this.componentArraylist.forEach((component) => {
            this.cmsService.getComponentData(component?.uid).subscribe(
              (res: any) => {
                if (
                  res?.componentType ===
                  this.dsProductDetails.DSPRODUCTSFEATURED
                ) {
                  if (res?.productCodes) {
                    this.getFeaturedProducts(res, data[0]);
                    // Removing this because of DE154288. Timeout is taking time to load Products
                    // setTimeout(() => {
                    // }, 5000);
                  }
                }
              },
              (error) => this.hideLoader()
            );
          });
        }
      },
      (error) => this.hideLoader()
    );
  }
  ngAfterViewInit(): void {
    this.scrollProducts();
  }

  getFeaturedProducts(res, user) {
    const userType = user ? OCC_USER_ID_CURRENT : OCC_USER_ID_ANONYMOUS;
    let apiParams;
    let productCodeList = res?.productCodes?.split(' ').toString();
    if (userType === OCC_USER_ID_ANONYMOUS) {
      const activeSalesArea =
        this.custAccService.getGuestActiveSalesAreaFromStorage();
      this.activeSalesArea = activeSalesArea?.saleAreaId || '1800_GE_GE';
      apiParams = {
        fields: 'FULL',
        guestSalesArea: this.activeSalesArea,
        productCodeList: productCodeList,
      };
    } else {
      this.custAccService.getCurrentCustomerAccount().subscribe((res: any) => {
        this.customerAccount = res?.uid || '';
        this.activeSalesArea = res.selectedSalesArea?.salesAreaId.split('_')[1];
      });
      apiParams = { fields: 'FULL', productCodeList: productCodeList };
    }
    let urlParams = ['users', userType, 'products', 'productList'];
    let url = this.apiService.constructUrl(urlParams);
    if (this.productsAPI) {
      this.apiService.getData(url, apiParams).subscribe(
        (data: any) => {
          this.categories = [
            ...new Set(data.map((product) => product.breadCrumbs[2]?.name)),
          ];
          this.productsAPI = false;
          this.componentLoad = false;
          this.productListItem = data;
          this.selectedCategory = this.categories[0];
          this.productListItem = this.productListItem.map((product) =>
            this.converterService.convert(product, PRODUCT_NORMALIZER)
          );
          this.totalCards = this.getProductsBYCategory(
            this.categories[0]
          )?.length;
          // this.totalCards = this.productListItem.length;
          this.cardsPerPage = this.getCardsPerPage();
          this.initializeSlider();
          this.hideLoader();
          this.pushAddToCartEvent();
        },
        (error) => this.hideLoader()
      );
    }
  }
  initializeSlider() {
    if (this.windowRef.isBrowser()) {
      this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
      this.overflowWidth = `calc(${this.totalPages * 100}% + ${
        this.totalPages * 30
      }px)`;
      this.cardWidth = `calc((${100 / this.totalPages}% - ${
        this.cardsPerPage * 30
      }px) / ${this.cardsPerPage})`;
    }
  }
  getCardsPerPage() {
    if (this.windowRef.isBrowser()) {
      if (this.windowRef.nativeWindow.innerWidth > 768) {
        return 3;
      } else {
        return 1;
      }
    }
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% + ${
      this.totalPages == this.currentPage &&
      this.currentPage > 1 &&
      this.totalCards % this.cardsPerPage != 0 &&
      this.totalCards % this.cardsPerPage >= this.cardsPerPage
        ? (this.cardsPerPage - (this.totalCards % this.cardsPerPage)) * 25
        : 0
    }% - ${
      this.totalPages == this.currentPage &&
      this.currentPage > 1 &&
      this.totalCards % this.cardsPerPage != 0 &&
      this.totalCards % this.cardsPerPage >= this.cardsPerPage
        ? 7.5 * (this.totalCards % this.cardsPerPage)
        : 30 * (this.currentPage - 1)
    }px)`;
  }

  scrollProducts() {
    this.timer = setInterval(() => {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      } else {
        this.currentPage = 1;
      }
      this.populatePagePosition();
      this.cref.detectChanges();
    }, 5000);
  }

  changePage(incrementor) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  stopMovement() {
    clearTimeout(this.timer);
  }
  startMovement() {
    // this.scrollProducts();
  }

  getProductsBYCategory(name) {
    return this.productListItem?.filter(
      (product) => product?.breadCrumbs[2]?.name == name
    );
  }

  tabClickForCategory(category: string, index: number) {
    this.selectedCategory = category;
    this.currentPage = 1;
    this.totalCards = this.getProductsBYCategory(
      this.categories[index]
    )?.length;
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
    this.populatePagePosition();
    this.cref.detectChanges();
  }

  dispalyCategoryFromUI(cat) {
    const text = cat.toLowerCase();
    switch (true) {
      case text.includes('visual'):
        return 'Visual';
      case text.includes('ultrasonic'):
        return 'Ultrasound';
      case text.includes('film'):
        return 'Film Products';
      case text.includes('radiography'):
        return 'Radiography';
      case text.includes('generators'):
        return 'Digital Imaging';
      default:
        return cat;
    }
  }

  hideLoader() {
    this.showLoading = false;
  }

  pushAddToCartEvent() {
    const item: Ecommerce = {
      item_list_id: ItemListTypeEnum.FeaturedProducts,
      item_list_name: ItemListTypeEnum.FeaturedProducts,
      items: this.productListItem.map((product, index) => {
        return {
          item_id: product?.code,
          item_name: product?.name,
          coupon: '',
          discount: product?.discountPercentage
            ? Number(product?.discountPercentage)
            : '',
          index: index,
          affiliation: StoreTypeEnum.Waygate,
          item_brand: StoreTypeEnum.Waygate,
          item_category: product?.breadCrumbs[0]?.name || '',
          item_category2: product?.breadCrumbs[1]?.name || '',
          item_category3: product?.breadCrumbs[2]?.name || '',
          item_category4: product?.breadCrumbs[3]?.name || '',
          item_category5: product?.breadCrumbs[4]?.name || '',
          item_list_id: ItemListTypeEnum.FeaturedProducts,
          item_list_name: ItemListTypeEnum.FeaturedProducts,
          item_variant: '',
          location_id: '',
          price: product?.yourPrice?.value || '',
          quantity: '',
        };
      }),
    };

    const eventData: GTMDataLayer = {
      event: GtmEvents.ViewItemList,
      store: StoreTypeEnum.Waygate,
      ecommerce: item,
    };
    this.gtmService.sendEvent(eventData);
  }

  //Google Analytics
  gtmSelectItemEvent(product) {
    if (product) {
      let producitem: EcommerceItem[] = [];

      producitem.push({
        item_id: product?.code,
        item_name: product?.name,
        discount: product?.discountPercentage || '',
        index: 0,
        item_brand: StoreTypeEnum.Waygate,
        item_category: product?.breadCrumbs[0]?.name || '',
        item_category2: product?.breadCrumbs[1]?.name || '',
        item_category3: product?.breadCrumbs[2]?.name || '',
        item_category4: product?.breadCrumbs[3]?.name || '',
        item_category5: product?.breadCrumbs[4]?.name || '',
        item_list_id: ItemListTypeEnum.SavedCart,
        item_list_name: ItemListTypeEnum.FeaturedProducts,
        price: product?.discountPrice ? +product?.discountPrice : '',
      });
      let purchaseEcommerceEcommerce: Ecommerce = {
        item_list_id: ItemListTypeEnum.FeaturedProducts,
        item_list_name: ItemListTypeEnum.FeaturedProducts,
        items: producitem,
      };
      let selectItemDataLayer: GTMDataLayer = {
        event: GtmEvents.SelectItem,
        store: StoreTypeEnum.Waygate,
        ecommerce: purchaseEcommerceEcommerce,
      };
      this.gtmService.sendEvent(selectItemDataLayer);
    }
  }
}
