<app-waygate-header [isChooseBrandPage]="true"></app-waygate-header>
<div class="container">
  <div class="heading">
    <h1 class="our-brands">Our brands</h1>
    <p class="looking-for">What are you looking for today?</p>
  </div>
  <div
    class="brand-list"
    [ngClass]="{ 'justify-content-center': availableProductLines.length === 2 }"
  >
    <div
      class="brand-item"
      *ngIf="availableProductLines.includes(productLine.waygate)" (click)="routeToRedirect(productLine.waygate)"
    >
      <div class="brand-img">
        <img src="../../../assets/img/bannernav1.png" [alt]="productLine.waygate" />
      </div>
      <div class="brand-desc">
        World’s largest provider of non-destructive testing solutions including
        radiography, computed tomography (CT), remote visual inspection, and
        ultrasound.
      </div>
    </div>
    <div
      class="brand-item"
      *ngIf="availableProductLines.includes(productLine.druck)" (click)="routeToRedirect(productLine.druck)"
    >
      <div class="brand-img">
        <img src="../../../assets/img/bannernav3.png" [alt]="productLine.druck" />
      </div>
      <div class="brand-desc">
        Druck’s piezo-resistive pressure sensors and test and calibration
        instruments provide our customers with the highest performance,
        stability, quality, accuracy and quickest response in any environment.
      </div>
    </div>
    <div
      class="brand-item"
      *ngIf="availableProductLines.includes(productLine.bently)" (click)="routeToRedirect(productLine.bently)"
    >
      <div class="brand-img">
        <img src="../../../assets/img/bannernav4.png" [alt]="productLine.bently" />
      </div>
      <div class="brand-desc">
        Bently Nevada serves a variety of needs, including Asset Condition
        Monitoring, Machinery Diagnostics, Monitoring and Sensing Hardware,
        Asset Protection Software and Global Support and Services.
      </div>
    </div>
    <div
      class="brand-item"
      *ngIf="availableProductLines.includes(productLine.panametrics)" (click)="routeToRedirect(productLine.panametrics)"
    >
      <div class="brand-img">
        <img src="../../../assets/img/bannernav2.png" [alt]="productLine.panametrics" />
      </div>
      <div class="brand-desc">
        Panametrics has been a pioneer in the sensor technology industry for
        more than 50 years. With an innovative culture, Panametrics continues to
        develop solutions for moisture, oxygen, liquid flow, and gas flow
        measurement.
      </div>
    </div>
    <div
      class="brand-item"
      *ngIf="availableProductLines.includes(productLine.reuterStokes)" (click)="routeToRedirect(productLine.reuterStokes)"
    >
      <div class="brand-img">
        <img src="../../../assets/img/bannernav5.png" [alt]="productLine.reuterStokes" />
      </div>
      <div class="brand-desc">
        Our solutions include precise radiation measurement, nuclear reactor
        monitoring, UV flame detection, and downhole sensing for directional
        drilling.
      </div>
    </div>
  </div>
</div>
<app-waygate-footer [isChooseBrandPage]="true"></app-waygate-footer>
