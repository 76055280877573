import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-waygate-manage-account',
  templateUrl: './waygate-manage-account.component.html',
  styleUrls: ['./waygate-manage-account.component.scss'],
})
export class WaygateManageAccountComponent {
  breadcrumbs = [
    {
      name: 'Manage Account',
    },
  ];
  state: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.state = queryParams?.state || 'profile';
    });
  }
  changeState = (state) => {
    this.setRoute({ state });
  };
  protected setRoute(queryParams): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }


}
