import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CmsService,
  ConverterService,
  GlobalMessageService,
  GlobalMessageType,
  PRODUCT_NORMALIZER,
  WindowRef,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap, take, takeLast } from 'rxjs/operators';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { ApiService } from 'src/app/core/http/api.service';
import {
  productsContentSlot,
  productByCategoryDisplayFlag,
} from 'src/app/shared/products-constants';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';
import {
  GtmEvents,
  ItemListTypeEnum,
} from 'src/app/shared/enums/gtm.enum';

export enum DsProductDetails {
  DSPRODUCTSBESTSELLER = 'BESTSELLER',
}
@Component({
  selector: 'app-best-sellers',
  templateUrl: './best-sellers.component.html',
  styleUrls: ['./best-sellers.component.scss'],
})
export class BestSellersComponent implements OnInit {
  user$: Observable<unknown>;
  userType = '';
  dsProductDetails = DsProductDetails;
  user: any;
  componentArraylist: any = [];
  totalCards: number;
  currentPage: number = 1;
  cardsPerPage: number;
  totalPages: number;
  overflowWidth: string;
  cardWidth: string = '25%';
  pagePosition: string = '0%';
  timer: any;
  categories = [];
  productList: any;
  productsAPI: boolean = false;
  activeSalesArea: string;
  customerAccount: string;
  componentLoad: boolean = false;
  showLoading: boolean = false;
  selectedCategory = '';
  productLine: string;
  slotPosition: string;
  iscontentAvailableFromSlot = false;
  isProductsDisplayAsPerCategory = false;
  productLine$: Observable<any>;
  @HostListener('window:resize') windowResize() {
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
      this.populatePagePosition();
    }
  }

  constructor(
    private router: Router,
    private cmsService: CmsService,
    private apiService: ApiService,
    private windowRef: WindowRef,
    private cref: ChangeDetectorRef,
    private converterService: ConverterService,
    private userAccountFacade: UserAccountFacade,
    private globalMessageService: GlobalMessageService,
    private authService: AuthService,
    private custAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.productLine$ = this.custAccService.getProductLine();
    this.user$ = this.authService.isUserLoggedIn();
    combineLatest([this.user$, this.productLine$]).pipe(take(1)).subscribe(
      (response) => {
        const loggedIn = response[0];
        this.productLine = response[1];
        if (loggedIn) {
          this.userType = 'current';
        } else {
          this.userType = 'anonymous';
        }
        this.slotPosition = productsContentSlot[this.productLine];
        //if slot is know and got usertype then only api need to call
        if (this.slotPosition && this.userType) {
          this.productsAPI = true;
          this.componentLoad = true;
          this.callContentSlotAndApi();
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
  }

  callContentSlotAndApi() {
    this.showLoading = true;
    this.cmsService.getContentSlot(this.slotPosition).pipe(take(1)).subscribe(
      (data) => {
        this.componentArraylist = data?.components ?? [];
        if (this.componentArraylist.length > 0) {
          this.iscontentAvailableFromSlot = true;
          this.componentArraylist.forEach((component) => {
            this.cmsService.getComponentData(component?.uid).subscribe(
              (res: any) => {
                if (
                  res?.componentType ===
                  this.dsProductDetails.DSPRODUCTSBESTSELLER
                ) {
                  if (res?.productCodes) {
                    this.getBestsellerProducts(res);
                  }
                }
              },
              (error) => this.hideLoader()
            );
          });
        } else {
          this.iscontentAvailableFromSlot = false;
        }
      },
      (error) => this.hideLoader()
    );
  }

  ngAfterViewInit(): void {
    this.scrollProducts();
  }

  getBestsellerProducts(res) {
    let apiParams;
    let productCodeList = res?.productCodes?.split(' ').toString();
    let urlParams = ['users', this.userType, 'products', 'productList'];
    let url = this.apiService.constructUrl(urlParams);

    if (this.userType === 'anonymous') {
      let activeSalesArea =
        this.custAccService.getGuestActiveSalesAreaFromStorage();
      if (activeSalesArea?.saleAreaId) {
        this.activeSalesArea = activeSalesArea?.saleAreaId;
      } else if (this.productLine) {
        this.activeSalesArea =
          this.custAccService.getGuestSalesIdFromProductLine(this.productLine);
      }
      apiParams = {
        fields: 'FULL',
        guestSalesArea: this.activeSalesArea,
        productCodeList: productCodeList,
      };
    } else {
      this.custAccService.getCurrentCustomerAccount().subscribe((res: any) => {
        this.customerAccount = res?.uid || '';
        this.activeSalesArea = res.selectedSalesArea?.salesAreaId.split('_')[1];
      });
      apiParams = { fields: 'FULL', productCodeList: productCodeList };
    }
    if (this.productsAPI) {
      this.isProductsDisplayAsPerCategory =
        productByCategoryDisplayFlag[this.productLine];
      this.apiService.getData(url, apiParams).subscribe(
        (data: any) => {
          this.hideLoader();
          this.productsAPI = false;
          this.componentLoad = false;
          if (data) {
            this.categories = this.isProductsDisplayAsPerCategory
              ? [
                  ...new Set(
                    data.map((product) => product.breadCrumbs[2]?.name)
                  ),
                ]
              : [];
            this.selectedCategory = this.isProductsDisplayAsPerCategory
              ? this.categories[0]
              : '';
            this.productList = data.map((product) =>
              this.converterService.convert(product, PRODUCT_NORMALIZER)
            );
            this.pushAddToCartEvent();
            this.totalCards = this.isProductsDisplayAsPerCategory
              ? this.getProductsBYCategory(this.categories[0])?.length
              : this.productList.length;
            this.cardsPerPage = this.getCardsPerPage();
            this.initializeSlider();
          }
        },
        (error) => this.hideLoader()
      );
    }
  }

  getProductsBYCategory(name) {
    if (name) {
      return this.productList?.filter(
        (product) => product?.breadCrumbs[2]?.name == name
      );
    } else {
      return this.productList;
    }
  }
  tabClickForCategory(category: string, index: number) {
    this.selectedCategory = category;
    this.currentPage = 1;
    this.totalCards = this.getProductsBYCategory(
      this.categories[index]
    )?.length;
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
    this.populatePagePosition();
    this.cref.detectChanges();
  }

  getCardsPerPage() {
    if (this.windowRef.isBrowser()) {
      if (this.windowRef.nativeWindow.innerWidth > 768) {
        return 2;
      } else {
        return 1;
      }
    }
  }

  initializeSlider() {
    if (this.windowRef.isBrowser()) {
      this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
      this.overflowWidth = `calc(${this.totalPages * 100}% + ${
        this.totalPages * 30
      }px)`;
      this.cardWidth = `calc((${100 / this.totalPages}% - ${
        this.cardsPerPage * 30
      }px) / ${this.cardsPerPage})`;
    }
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% + ${
      this.totalPages == this.currentPage &&
      this.currentPage > 1 &&
      this.totalCards % this.cardsPerPage != 0
        ? (this.cardsPerPage - (this.totalCards % this.cardsPerPage)) * 25
        : 0
    }% - ${
      this.totalPages == this.currentPage &&
      this.currentPage > 1 &&
      this.totalCards % this.cardsPerPage != 0
        ? 7.5 * (this.totalCards % this.cardsPerPage)
        : 30 * (this.currentPage - 1)
    }px)`;
  }

  scrollProducts() {
    this.timer = setInterval(() => {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      } else {
        this.currentPage = 1;
      }
      this.populatePagePosition();
      this.cref.detectChanges();
    }, 10000);
  }

  changePage(incrementor) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  stopMovement() {
    clearTimeout(this.timer);
  }

  startMovement() {
    this.scrollProducts();
  }

  dispalyCategoryFromUI(cat) {
    const text = cat.toLowerCase();
    switch (true) {
      case text.includes('visual'):
        return 'Visual';
      case text.includes('ultrasonic'):
        return 'Ultrasound';
      case text.includes('film'):
        return 'Film Products';
      case text.includes('radiography'):
        return 'Radiography';
      case text.includes('generators'):
        return 'Digital Imaging';
      default:
        return cat;
    }
  }

  hideLoader() {
    this.showLoading = false;
  }

  pushAddToCartEvent() {
    const item: Ecommerce = {
      item_list_id: ItemListTypeEnum.BestSellers,
      item_list_name: ItemListTypeEnum.BestSellers,
      items: this.productList.map((product, index) => {
        return {
          item_id: product?.code,
          item_name: product?.name,
          coupon: '',
          discount: product?.discountPercentage
            ? Number(product?.discountPercentage)
            : '',
          index: index,
          affiliation: this.gtmService.getItemBrand(),
          item_brand: this.gtmService.getItemBrand(),
          item_category: product?.breadCrumbs[0]?.name || '',
          item_category2: product?.breadCrumbs[1]?.name || '',
          item_category3: product?.breadCrumbs[2]?.name || '',
          item_category4: product?.breadCrumbs[3]?.name || '',
          item_category5: product?.breadCrumbs[4]?.name || '',
          item_list_id: ItemListTypeEnum.BestSellers,
          item_list_name: ItemListTypeEnum.BestSellers,
          item_variant: '',
          location_id: '',
          price: product?.yourPrice?.value || '',
          quantity: '',
        };
      }),
    };

    const eventData: GTMDataLayer = {
      event: GtmEvents.ViewItemList,
      store: this.gtmService.getItemBrand(),
      ecommerce: item,
    };
    this.gtmService.sendEvent(eventData);
  }

  //Google analytics
  gtmSelectItemEvent(product) {
    if (product) {
      let producitem: EcommerceItem[] = [];
      producitem.push({
        item_id: product?.code,
        item_name: product?.name,
        discount: product?.discountPercentage || '',
        index: 0,
        item_brand: this.gtmService.getItemBrand(),
        item_category: product?.breadCrumbs[0]?.name || '',
        item_category2: product?.breadCrumbs[1]?.name || '',
        item_category3: product?.breadCrumbs[2]?.name || '',
        item_category4: product?.breadCrumbs[3]?.name || '',
        item_category5: product?.breadCrumbs[4]?.name || '',
        item_list_id: ItemListTypeEnum.BestSellers,
        item_list_name: ItemListTypeEnum.BestSellers,
        price: '', //discountPrice not available
      });
      let purchaseEcommerceEcommerce: Ecommerce = {
        item_list_id: ItemListTypeEnum.BestSellers,
        item_list_name: ItemListTypeEnum.BestSellers,
        items: producitem,
      };
      let selectItemDataLayer: GTMDataLayer = {
        store: this.gtmService.getItemBrand(),
        ecommerce: purchaseEcommerceEcommerce,
        event: GtmEvents.SelectItem,
      };
      this.gtmService.sendEvent(selectItemDataLayer);
    }
  }
}
