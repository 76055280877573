export const REGULAR_PATTERN = {
  textOnly: /[^a-zA-Z. ]+/g,
  numbersOnly: /[^\d+ .]+/g,
  alphaNumeric: /[^a-zA-Z0-9 .+-/]+/g,
  alphaNumericOnlyForAllLang:/[^\p{L}\p{N}]+/ug,
  alphaNemericOnly:/[^a-zA-Z0-9 -]+/g,
  alphaNumericWithSpecialCharater: /[^a-zA-Z0-9,-.+/%#@&$ ]+/g,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
};

export const testRegex = (str, regexPattern) => {
  const sanitizedString = str.replace(regexPattern, '');
  return sanitizedString;
};
