import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductCategoriesService } from 'src/app/core/product-catalog/services/product-categories.service';
import { environment } from 'src/environments/environment';
declare const Optanon: any;
@Component({
  selector: 'app-waygate-footer',
  templateUrl: './waygate-footer.component.html',
  styleUrls: ['./waygate-footer.component.scss'],
})
export class WaygateFooterComponent implements OnInit {
  productCategories$: Observable<any>;  
  @Input() isChooseBrandPage:boolean = false;
  salesAreas = ['ECOM_LVL1_00000001'];
  servicesList = [
    {
      name: 'Radiography and CT',
      url: 'https://www.bakerhughes.com/waygate-technologies/radiography-and-ct-services',
    },
    {
      name: 'RVI and Robotics',
      url: 'https://www.bakerhughes.com/waygate-technologies/rvi-and-robotics-services',
    },
    {
      name: 'NDT Services & Support',
      url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support',
    },
    {
      name: 'Ultrasound',
      url: 'https://www.bakerhughes.com/waygate-technologies/ultrasound-services',
    },
    {
      name: 'Remote',
      url: 'https://www.bakerhughes.com/waygate-technologies/waygate-technologies-remote-service-contact',
    },
    {
      name: 'Inspection Training Academy',
      url: 'https://www.bakerhughes.com/waygate-technologies/ndt-services-support/waygate-technologies-training-academy',
    },
  ];

  industriesList = [
    {
      name: 'Aerospace',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-aerospace'
      
    },
    {
      name: 'Electronics',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-electronics'      
    },
    {
      name: 'Automotives',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-automotives'
    },
    {
      name: 'Batteries',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-batteries',
    },
    {
      name: 'Energy',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-energy'
      
    },
    {
      name: 'Engineering Solutions',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/engineering-solutions',
    },
    {
      name: 'Manufacturing',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-manufacturing'
      
    },
    {
      name: 'Medical',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-medical',
    },
    {
      name: 'Oil and Gas',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-oil-and-gas'
      
    },
    {
      name: 'Research and Development',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-research-and-development',
    },
    {
      name: 'Transportation',
      url: 'https://www.bakerhughes.com/waygate-technologies/industry/ndt-transportation'
      
    },
  ];

  constructor(private productCategoriesService: ProductCategoriesService) {}

  ngOnInit(): void {
    this.productCategories$ =
      this.productCategoriesService.fetchDefaultProductCategories();
  }
  openCookies() {
    Optanon.ToggleInfoDisplay();
  }
  openTerms() {
    const termsDocUrl =
      environment.occBaseUrl +
      `/_ui/responsive/common/images/DSe-CommercePortalTermsofUse.pdf`;
    (window as any).open(termsDocUrl, '_blank');
  }

  constructCategoryUrl(category) {
    return `/waygate/categories/${category.code}/${category.name}`;
  }

  scrollTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
