import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { I18nModule, provideConfig } from '@spartacus/core';
import { I18Pipe } from 'src/app/shared/pipes/i18.pipe';

import { DetailedNotificationPageComponent } from 'src/app/feature/notifications/detailed-notification-page/detailed-notification-page.component';
import { DismissAllComponent } from 'src/app/feature/notifications/dismiss-all/dismiss-all.component';
import { NotificationDropdownComponent } from 'src/app/feature/notifications/notification-dropdown/notification-dropdown.component';
import { DIALOG_TYPE, SpinnerModule } from '@spartacus/storefront';
import { ReadDismissAllComponent } from './read-dismiss-all/read-dismiss-all.component';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';

@NgModule({
  declarations: [
    DetailedNotificationPageComponent,
    DismissAllComponent,
    NotificationDropdownComponent,
    ReadDismissAllComponent,
  ],
  exports: [NotificationDropdownComponent],
  imports: [CommonModule, RouterModule, I18nModule, SpinnerModule],
  providers: [
    I18Pipe,
    provideConfig({
      launch: {
        [DS_DIALOG.READ_DISMISS_ALL_DIALOG]: {
          inlineRoot: true,
          component: ReadDismissAllComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.DISMISS_ALL_DIALOG]: {
          inlineRoot: true,
          component: DismissAllComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NotificationsModule {}
