import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { CalibrationDataComponent } from './calibration-data.component'
import { I18nModule, provideConfig } from '@spartacus/core'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { I18Pipe } from 'src/app/shared/pipes/i18.pipe'
import { RouterModule } from '@angular/router'
import { DatePickerModule } from 'src/app/shared/components/date-picker/date-picker.module';
import { CalibrationDataRequestComponent } from './calibration-data-request/calibration-data-request.component'
import { CalibrationDataSuccessComponent } from './calibration-data-success/calibration-data-success.component'
import { FindCalibrationDataComponent } from './find-calibration-data/find-calibration-data.component'
import { AddEquipmentComponent } from 'src/app/feature/site-equipments/add-equipment/add-equipment.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config'
import { DIALOG_TYPE } from '@spartacus/storefront'

@NgModule({
  declarations: [
    CalibrationDataComponent,
    CalibrationDataRequestComponent,
    CalibrationDataSuccessComponent,
    FindCalibrationDataComponent,
    AddEquipmentComponent,
  ],
  imports: [CommonModule, RouterModule, I18nModule, FormsModule,DatePickerModule],
  providers: [
    DatePipe,
    I18Pipe,
    { provide: HTTP_INTERCEPTORS, useClass:FindCalibrationDataComponent , multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    provideConfig({
      launch: {
        [DS_DIALOG.CALI_DATA_SUCCESS_DIALOG]: {
          inlineRoot: true,
          component: CalibrationDataSuccessComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
        [DS_DIALOG.CALI_DATA_REQUEST_DIALOG]: {
          inlineRoot: true,
          component: CalibrationDataRequestComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        }
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CalibrationDataModule {}
