import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { OrderTrackingService } from 'src/app/feature/order-tracking/order-tracking.service'
// import { QuoteTrackingService } from 'src/app/features/quote/quote-tracking/quote-tracking.service';
import { OrderStatus } from 'src/app/shared/models/status/order-status.model'
import { RmaStatus } from 'src/app/shared/models/status/rma-status.model'
import { EquipmentStatus } from 'src/app/shared/models/status/my-equipment-status.model'
import {
  QuoteStatus,
  QuoteStatusTypes,
} from 'src/app/shared/models/status/quote-status.model'
import { StatusType } from 'src/app/shared/models/status/status.model'
import { RmaTrackingService } from 'src/app/feature/rma/rma-tracking/rma-tracking.service'
import { SiteEquipmentsService } from 'src/app/feature/site-equipments/site-equipments.service'

/**
 * @author Aneesh Nair
 * Indicates singleton status tile
 */
export class StatusTile {
  /**
   * Indicates Status type - Quote or Order
   * starts from 0
   */
  statusType: string

  /**
   * Indicates Order Status
   * OrderStatus will be null for Quote Status
   */
  orderStatus?: OrderStatus

  /**
   * Indicates Quote Status
   * QuoteStatus will be null for Quote Status
   */
  quoteStatus?: QuoteStatus

  /**

  * Indicates Quote Status
   * QuoteStatus will be null for Quote Status
   */
  rmaStatus?: RmaStatus

    /**

  * Indicates equipment Status
   * QuoteStatus will be null for Quote Status
   */
     equipmentStatus?: EquipmentStatus

  /**
   * Indicates if this status is the current status
   */
  isCurrentStatus?: boolean

  /**
   * Bubble bg color in Hex format
   */
  statusCount? = 0

  /**
   * Bubble bg color in Hex format
   */
  colorTheme?: string = ''

  /**
   * staus Tooltip
   */
  tooltip?: string = ''
}

/**
 * @author Aneesh Nair
 * Component to display status tiles for Order & Quote Tracking pages
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'vs-status-tiles',
  templateUrl: './status-tiles.component.html',
  styleUrls: ['./status-tiles.component.scss'],
})
export class StatusTilesComponent implements OnInit {
  @Input()
  tiles: StatusTile[]

  @Input()
  currentStatusType: StatusType

  @Output()
  filteredTile: EventEmitter<any> = new EventEmitter()

  @Input()
  defaultTile: boolean

  selectedTile

  quoteSelectedTile
  selectedOrderTile: any
  constructor(
    private orderTrackingService: OrderTrackingService,
    private rmaTrackingService: RmaTrackingService,
    private equipmentDetailsService: SiteEquipmentsService
  ) {
    // this.quoteTrackingService.tileStatusEmitted$.subscribe(res=>{
    //   this.quoteSelectedTile = res;
    // })
  }

  ngOnInit(): void {

    this.orderTrackingService.emitTileStatusOrder.subscribe((res) => {
      if (res == 'Received') {
        this.selectedOrderTile = 'Order Received'
      } else if (res == 'Processing') {
        this.selectedOrderTile = 'Order In Progress'
      } else {
        this.selectedOrderTile = res
      }
    })

    this.rmaTrackingService.emitTileStatus.subscribe((res) => {
      this.selectedTile = res
    })
    this.equipmentDetailsService.equipmentTileStatus.subscribe((res) => {
      this.selectedTile = res
    })

  }

  public get statusTypes(): typeof StatusType {
    return StatusType
  }

  emitTileClick(tile: StatusTile) {
    this.filteredTile.emit(tile)
  }
}
