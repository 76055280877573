import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SlideInterface } from '../image-slider/slide.interface';
import { AuthService, CmsService } from '@spartacus/core';
import { PageLayoutService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'app-waygate-landing',
  templateUrl: './waygate-landing.component.html',
  styleUrls: ['./waygate-landing.component.scss'],
  providers: [NgbCarouselConfig],
})
export class WaygateLandingComponent implements OnInit {
  user$: Observable<any>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
  }

  talkToExpert() {}
  knowMore() {
    this.router.navigate(['/Waygate-Technologies/c/ECOM_LVL1_00000001']);
  }

  viewQuickOrder() {
    this.router.navigate(['waygate/quick-order']);
  }
}
