import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  LaunchDialogService,
  SearchBoxComponentService,
  SearchBoxConfig,
  SearchResults,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import {
  REGULAR_PATTERN,
  testRegex,
} from 'src/app/core/generic-validator/regular-expressions';
import {
  GtmEvents,
  ItemListTypeEnum,
  StoreType,
} from 'src/app/shared/enums/gtm.enum';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';

@Component({
  selector: 'app-waygate-search-dialog',
  templateUrl: './waygate-search-dialog.component.html',
  styleUrls: ['./waygate-search-dialog.component.scss'],
})
export class WaygateSearchDialogComponent implements OnInit, AfterViewInit {
  DEFAULT_SEARCH_BOX_CONFIG: SearchBoxConfig = {
    minCharactersBeforeRequest: 3,
    displayProducts: true,
    displaySuggestions: false,
    displayProductImages: true,
  };

  @ViewChild('searchInputWaygate') searchInputWaygate: ElementRef;
  results$: Observable<SearchResults> =
    this.searchBoxComponentService.getResults(this.DEFAULT_SEARCH_BOX_CONFIG);
  searchQuery: string;
  productLine: string;
  constructor(
    protected launchDialogService: LaunchDialogService,
    private searchBoxComponentService: SearchBoxComponentService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private custAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService
  ) {}
  ngOnInit() {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });
  }
  ngAfterViewInit(): void {
    this.searchInputWaygate.nativeElement.focus();
  }
  search(event) {
    this.searchQuery = event.target.value;
    const sanitizedQuery = testRegex(
      event.target.value,
      REGULAR_PATTERN.alphaNumeric
    );
    if (sanitizedQuery.length > 2)
      this.searchBoxComponentService.search(
        this.sanitizer.sanitize(SecurityContext.HTML, sanitizedQuery),
        this.DEFAULT_SEARCH_BOX_CONFIG
      );
  }
  goToPage() {
    this.router.navigate([`/${this.productLine}/search/${this.searchQuery}`]);
  }
  closeDialog(reason, product?) {
    this.gtmSelectItemEvent(product);
    this.launchDialogService.closeDialog(reason);
  }

  //Google analytics
  gtmSelectItemEvent(product) {
    let producitem: EcommerceItem[] = [];
    if (product) {
      producitem.push({
        item_id: product?.code,
        item_name: product?.name,
        discount: product?.discountPercentage ? +product?.discountPercentage : '',
        index: 0,
        item_brand: this.gtmService.getItemBrand(),
        item_list_id: ItemListTypeEnum.Search,
        item_list_name: ItemListTypeEnum.Search,        
        price: product?.discountPrice ? +product?.discountPrice : '',
        item_category: '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_variant: '',
      });

      let purchaseEcommerceEcommerce: Ecommerce = {
        item_list_id: ItemListTypeEnum.Search,
        item_list_name: ItemListTypeEnum.Search,
        items: producitem,
      };
      let selectItemDataLayer: GTMDataLayer = {
        event: GtmEvents.SelectItem,
        store: this.gtmService.getItemBrand(),
        ecommerce: purchaseEcommerceEcommerce,
      };
      this.gtmService.sendEvent(selectItemDataLayer);
    }
  }
}
