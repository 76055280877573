import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import {
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class MyFavoritesService {
  user$: Observable<unknown>;
  userType = '';
  constructor(
    private apiService: ApiService,
    private userAccountFacade: UserAccountFacade,
    private occEndpointsService: OccEndpointsService,
    private globalMessageService: GlobalMessageService,
    protected authService: AuthService
  ) {
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          this.userType = 'current';
          return this.userAccountFacade.get();
        } else {
          this.userType = 'anonymous';
          return of(undefined);
        }
      })
    );

    this.user$.subscribe(
      (res) => {
        if (res) {
          this.userType = 'current';
        } else {
          this.userType = 'anonymous';
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
  }
  addtowhishlist(productObj) {
    const params = ['users', this.userType, 'addToWishlist'];
    const apiUrl = this.occEndpointsService.buildUrl(
      `users/${this.userType}/addToWishlist`,
      { queryParams: productObj }
    );
    return this.apiService.postData(apiUrl, null);
  }
  getFavourites(value, current) {
    let apiparam;
    const params = ['users', this.userType, 'getfavourites'];
    const apiUrl = this.apiService.constructUrl(params);
    if (value) {
      apiparam = { text: value, currentPage: current, pageSize: 20 };
    } else {
      apiparam = { text: '', currentPage: current, pageSize: 20 };
    }

    return this.apiService.getData(apiUrl, apiparam);
  }
  removeFromWishlist(productid) {
    const params = ['users', this.userType, 'removeFromWishlist'];
    const apiUrl = this.apiService.constructUrl(params);
    let apiParam = productid;
    return this.apiService.postData(apiUrl, apiParam);
  }

  // function to get messages
  getMessages() {
    return this.apiService.getData('/assets/i18n-assets/en/favorites.json');
  }
}
