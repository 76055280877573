<div class="item-detail">
  <cx-media
    class="cx-product-image order-product-image"
    format="product"
  ></cx-media>
  <div class="middle-section">
    <div class="info-section-top">
      <div class="product-name-details">
        <h4 [innerHTML]="product?.description"></h4>
        <div>
          <span>{{ product?.partNumber }}</span>
        </div>
      </div>
      <div class="status" [class]="getClass(product?.status)">
        {{ product?.status }}
      </div>
    </div>
    <div class="info-section">
      <div>
        <div class="label">{{'order-tracking.quantityLbl' | cxTranslate}}</div>
        <div class="value">{{ product?.qty }}</div>
      </div>
      <ng-container *ngIf="userType == 'current'; else showguestUserDates">
        <div>
          <div class="label">{{'order-tracking.unitPriceLbl' | cxTranslate}}</div>
          <div class="value">
            {{ product?.currency }} {{ getUnitNetPrice(product) }}
          </div>
        </div>
        <div>
          <div class="label">{{'order-tracking.extendDateLbl' | cxTranslate}}</div>
          <div class="value">
            {{ product?.currency }} {{ getTotalPrice(product?.netPrice) }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="info-section" *ngIf="userType == 'current'">
      <div>
        <div class="label">{{'order-tracking.requestDateLbl' | cxTranslate}}</div>
        <div class="value">{{ product?.requestedShipDate || '-' }}</div>
      </div>
      <div>
        <div class="label" *ngIf="statusIsShipped; else currentPromiseDate">
          {{ 'waygate-order.actualShippedDate' | cxTranslate }}
          <div class="value">{{ product?.actShpDate || '-' }}</div>
        </div>
        <ng-template #currentPromiseDate>
          <div class="label" >{{ 'order-tracking.currentPromiseDate' | cxTranslate }}</div>
          <div class="value">{{ product?.geFromDate || '-' }}</div>
        </ng-template>
      </div>
      <div>
        <div>
          <div class="label">Tracking Number</div>
          <div class="value">{{ product?.trackingNumber || '-' }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="info-section-bottom" *ngIf="userType == 'current'">
      <div>
        <div class="label">Comments</div>
        <div class="value">{{ product?.commnets || '-' }}</div>
      </div>
      <div>
        <div>
          <div class="label">Carrier</div>
          <div class="value">{{ product?.courier || '-' }}</div>
        </div>
      </div>
    </div>
  </div> -->
</div>


<ng-template #showguestUserDates>
  <div>
    <div class="label" *ngIf="statusIsShipped; else currentPromiseDate">
      {{ 'waygate-order.actualShippedDate' | cxTranslate }}
      <div class="value">{{ product?.actShpDate || '-' }}</div>
    </div>
    <ng-template #currentPromiseDate>
      <div class="label" >{{ 'order-tracking.currentPromiseDate' | cxTranslate }}</div>
      <div class="value">{{ product?.reqShipDate || '-' }}</div>
    </ng-template>
  </div>
</ng-template>
