<div class="home-page">
  <div class="home-banner-nav pt-5">
    <h3 class="text-center">{{ 'guest-home.homeTopText' | cxTranslate }} ?</h3>
    <div class="box-wrapper m-0">
      <div class="product-lines-base">
        <a class="box cursor-pointer" routerLink="/waygate">
          <div>
            <img class="banner-nav" src="assets/img/banner1.png" alt="" />
          </div>
          <div class="banner-nav-img">
            <img src="assets/img/bannernav1.png" alt="" />
          </div>
        </a>
        <a
          class="box cursor-pointer"
          (click)="navigateTo('1830_GE_GE', 'panametrics')"
        >
          <div>
            <img class="banner-nav" src="assets/img/banner2.png" alt="" />
          </div>
          <div class="banner-nav-img">
            <img too src="assets/img/bannernav2.png" alt="" />
          </div>
        </a>
        <a class="box">
          <div>
            <img class="banner-nav" src="assets/img/banner3.png" alt="" />
          </div>
          <div class="banner-nav-img">
            <img src="assets/img/bannernav3.png" alt="" />
          </div>
        </a>
        <a class="box cursor-pointer"  [routerLink]="['/', bently]">
          <div>
            <img class="banner-nav" src="assets/img/banner4.png" alt="" />
          </div>
          <div class="banner-nav-img">
            <img src="assets/img/bannernav4.png" alt="" />
          </div>
        </a>
        <a class="box">
          <div>
            <img class="banner-nav" src="assets/img/banner5.png" alt="" />
          </div>
          <div class="banner-nav-img">
            <img src="assets/img/bannernav5.png" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="track-order">
    <div class="row box-wrapper m-0">
      <div class="col-lg-6 col-md-6 col-sm-12 box-container-align">
        <app-status-tracker></app-status-tracker>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 box-container-align">
        <app-equipment-calibration-data></app-equipment-calibration-data>
      </div>
    </div>
  </div>
  <div class="manage-orders">
    <img class="green-line" src="/assets/img/Rectangle.png" />
    <div class="register">{{ 'guest-home.register' | cxTranslate }}</div>
    <div class="see-orders">{{ 'guest-home.toSeeOrders' | cxTranslate }}</div>
    <div class="row icons">
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> image_search </span>
        <div class="icon-text">{{ 'guest-home.browse' | cxTranslate }}</div>
        <div class="icon-desc">
          {{ 'guest-home.productCatalog' | cxTranslate }}
        </div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> attach_money </span>
        <div class="icon-text">{{ 'guest-home.view' | cxTranslate }}</div>
        <div class="icon-desc">
          {{ 'guest-home.productPrice' | cxTranslate }}
        </div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> list_alt </span>
        <div class="icon-text">{{ 'guest-home.create' | cxTranslate }}</div>
        <div class="icon-desc">{{ 'guest-home.newRma' | cxTranslate }}</div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle">
          notifications_none
        </span>
        <div class="icon-text">{{ 'guest-home.recieve' | cxTranslate }}</div>
        <div class="icon-desc">
          {{ 'guest-home.notification' | cxTranslate }}
        </div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> fact_check </span>
        <div class="icon-text">{{ 'guest-home.track' | cxTranslate }}</div>
        <div class="icon-desc">
          {{ 'guest-home.returnStatus' | cxTranslate }}
        </div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> shopping_cart </span>
        <div class="icon-text">{{ 'guest-home.place' | cxTranslate }}</div>
        <div class="icon-desc">{{ 'guest-home.orders' | cxTranslate }}</div>
      </div>
      <div class="icon-width">
        <span class="material-icons-outlined icon-circle"> ballot </span>
        <div class="icon-text">{{ 'guest-home.check' | cxTranslate }}</div>
        <div class="icon-desc">
          {{ 'guest-home.productAvailability' | cxTranslate }}
        </div>
      </div>
    </div>
    <div class="register-button">
      <a [href]="registerUrl">
        <bh-button
          type="primary"
          label="{{ 'guest-home.register' | cxTranslate }}"
          class="register"
        >
        </bh-button>
      </a>
    </div>
    <div class="login-account">
      {{ 'guest-home.alreadyAccount' | cxTranslate
      }}<a href="/login"
        ><span class="login">{{ 'guest-home.login' | cxTranslate }}</span></a
      >
    </div>
  </div>
  <div>
    <img class="green-line" src="/assets/img/Rectangle.png" />
    <div class="product-brand">
      {{ 'guest-home.productBrand' | cxTranslate }}
    </div>
    <div class="brand-box">
      <div class="brand-desc">
        <img class="image-waygate" src="/assets/img/Waygate.png" />
        <div class="brand-description">
          {{ 'guest-home.waygateDescription' | cxTranslate }}
        </div>
        <div
          class="browse-brand"
          (click)="routeToPLPWaygate('1800_GE_GE', 'waygate')"
        >
          {{ 'guest-home.browseWaygate' | cxTranslate }}
        </div>
      </div>
      <div class="brand-desc">
        <img class="image-center" src="/assets/img/Bently_Nevada.png" />
        <div class="brand-description">
          {{ 'guest-home.bentlyDescription' | cxTranslate }}
        </div>
        <div
        class="browse-brand"
        (click)="routeToPLPWaygate('1200_BN_BN', bently)"
      >
       Browse Bently Nevada Products
      </div>
        
      </div>
      <div class="brand-desc">
        <img class="image-center" src="/assets/img/Reuter-Stokes.png" />
        <div class="brand-description">
          {{ 'guest-home.ReuterDescription' | cxTranslate }}
        </div>
      </div>
      <div class="brand-desc">
        <img class="image-center" src="/assets/img/Panametrics.png" />
        <div class="brand-description">
          {{ 'guest-home.panametricDescription' | cxTranslate }}
        </div>
        <div
          class="browse-brand"
          (click)="routeToPLPPanametric('1830_GE_GE', 'panametrics')"
        >
          {{ 'guest-home.browsePanametrics' | cxTranslate }}
        </div>
      </div>
      <div class="brand-desc">
        <img class="image-center" src="/assets/img/Druck.png" />
        <div class="brand-description">
          {{ 'guest-home.druckDescription' | cxTranslate }}
        </div>
      </div>
    </div>
  </div>
</div>
