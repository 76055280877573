import { Component, OnInit } from '@angular/core';
import {
  AccessRoleType,
  UserRoleService,
} from 'src/app/shared/services/user-role.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loggedin-home',
  templateUrl: './loggedin-home.component.html',
  styleUrls: ['./loggedin-home.component.scss'],
})
export class LoggedinHomeComponent implements OnInit {
  isNewCalibration = environment.isNewCalibration;
  currentUserAccess$ = this.userRoleService.currentUserRole;
  userRoleEnum = AccessRoleType;

  constructor(private userRoleService: UserRoleService) {}

  ngOnInit(): void {
    window.scrollTo({top:0})
  }
}
