<!-- <div #dialog> -->
<!-- Modal Header -->
<div class="cx-modal-container">
  <div class="cx-modal-content">
    <ng-container *ngIf="(loaded$ | async) || modalIsOpen; else loading">
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'addToCart.itemsAddedToYourCart' | cxTranslate }}
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          (click)="close('confirm')"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
      <!-- Modal Body -->
      <div
        class="add-to-cart-dialog-body modal-body"
        *ngIf="entry$ | async as entry"
      >
        <div class="add-to-cart-product">
          <div *ngIf="entry" class="row entry-item-row">
            <div class="">
              <cx-media
                class="cx-product-image"
                [ngClass]="entry.product.images?.PRIMARY ? '' : 'is-missing'"
                [container]="entry.product.images?.PRIMARY"
                format="product"
                [alt]="entry.product.summary"
              ></cx-media>
            </div>

            <div class="product-desc-col">
              <div class="dialog-product-code">
                {{ entry.product.code }}
              </div>
              <a
                [title]="entry.product.name"
                [routerLink]="['/', productLine, 'product', entry.product.code, entry.product.name]"
                (click)="close('Close on navigation')"
                class="dialog-product-name"
                [innerHTML]="entry.product.nameHtml"
              ></a>
            </div>
          </div>
        </div>
        <div class="cx-dialog-total" *ngIf="cart$ | async as cart">
          <div>
            {{
              'cartItems.cartTotal'
                | cxTranslate : { count: cart.deliveryItemsQuantity }
            }}
          </div>

          <ng-container *ngIf="user$ | async as user; else noPrice">
            <div>{{ cart.subTotal?.formattedValue }}</div>
          </ng-container>
          <ng-template #noPrice>
            <ng-container
              *ngIf="cart.commerceType == 'GUESTBUY'; else pricenot"
            >
              <div>{{ cart.subTotal?.formattedValue }}</div>
            </ng-container>
            <ng-template #pricenot>
              <div></div>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <!-- Total container -->
      <div class="cx-dialog-actions">
        <!-- Actions -->
        <div class="cx-dialog-buttons" *ngIf="cart$ | async as cart">
          <a
            *ngIf="
              cart.commerceType !== 'GUESTRFQ' &&
              cart.commerceType !== 'GUESTBUY' &&
              cart.cartType !== 'FILM' &&
              cart.cartType !== 'HYBRID'
            "
            [class.disable]="checkMinOrderQty(cart)"
            [routerLink]="['/',productLine, 'checkout']"
            class="bh-button motion--normal bh-button--type-secondary"
            (click)="!form.dirty && close('Proceed To Checkout click')"
            >{{ 'addToCart.proceedToCheckout' | cxTranslate }}</a
          >
          <a
            *ngIf="cart.commerceType == 'GUESTRFQ'"
            [class.disabled]="form.dirty"
            class="bh-button motion--normal bh-button--type-secondary"
            (click)="!form.dirty && close('Proceed To Checkout click')"
            >{{ 'buyCart.countinueshopping' | cxTranslate }}</a
          >
          <a
            *ngIf="
              cart.commerceType == 'GUESTBUY' ||
              cart.cartType == 'FILM' ||
              cart.cartType == 'HYBRID'
            "
            [class.disable]="checkMinOrderQty(cart)"
            [routerLink]="['/',productLine, 'checkout']"
            class="bh-button motion--normal bh-button--type-secondary"
            (click)="!form.dirty && close('Proceed To Checkout click')"
            >{{ 'addToCart.proceedToCheckout' | cxTranslate }}</a
          >
          <a
            [class.disabled]="form.dirty"
            [routerLink]="
              cart.commerceType == 'GUESTRFQ' ? ['/quote/cart'] : ['/', productLine, 'cart']
            "
            class="bh-button motion--normal bh-button--type-primary"
            autofocus
            (click)="!form.dirty && close('View Cart click')"
            >{{ 'addToCart.viewCart' | cxTranslate }}</a
          >
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'addToCart.updatingCart' | cxTranslate }}
        </div>
        <button
          _ngcontent-awj-c118=""
          type="button"
          aria-label="Close"
          class="close"
          (click)="close('confirm')"
        >
          <i _ngcontent-awj-c118="" class="material-icons">close</i>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          <div class="col-sm-12"><cx-spinner></cx-spinner></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
