import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductReturnService } from 'src/app/core/product-catalog/services/product-return.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'app-create-new-rma',
  templateUrl: './create-new-rma.component.html',
  styleUrls: ['./create-new-rma.component.scss'],
})
export class CreateNewRmaComponent implements OnInit, OnDestroy {
  private createRmaSubscripton: Subscription;
  showCustomerFiles: boolean = false;
  userDetails;

  constructor(
    private productReturnService: ProductReturnService,
    private router: Router,
    private userAccountFacade: UserAccountFacade
  ) {}

  ngOnDestroy(): void {
    this.createRmaSubscripton?.unsubscribe();
  }

  ngOnInit(): void {
    // To check private folder access
    this.userAccountFacade.get().subscribe((res) => {
      this.userDetails = res;
      if (res.hasOwnProperty('isPrivateFolderExists')) {
        this.showCustomerFiles = this.userDetails?.isPrivateFolderExists;
      } else {
        this.showCustomerFiles = false;
      }
    });
  }

  createNewRma() {
    // this.productReturnService.rmaValidateAndRedirect(this.modalRef, {});
    this.router.navigate(['/rma-form']);
    //******************As per Business suggestion no need to check Switch To Returns Cart pop up from home page */
    //  const updatedProduct = {};
    // this.createRmaSubscripton = this.productReturnService
    //   .rmaValidateAndRedirect(this.modalRef, updatedProduct)
    //   .subscribe((val: any) => {
    //     if (!val?.modal) {
    //       this.multiCartFacade.reloadCart(val, {
    //         active: true,
    //       });
    //       this.productReturnService.selectRmaProduct(updatedProduct);
    //       this.router.navigate(['/rma-form']);
    //     }
    //   });
    // ************************* code commented ***********************************
  }

  viewCalibrationData() {
    this.router.navigate(['/calibration-data']);
  }

  viewEquipment() {
    this.router.navigate(['/my-equipments']);
  }

  redirectToBynder() {
    this.router.navigate(['/search-private-folder']);
    const bynderUrl = environment.bynderUrl;
    window.open(bynderUrl, '_blank');
  }
}
