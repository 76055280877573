import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CartType, ProductType } from 'src/app/shared/models/cartType.models';
import { SavedCartService } from '../../saved-cart/service/saved-cart.service';
import {
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/shared/components/breadcrumb/breadcrumb.service';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import {
  Ecommerce,
  EcommerceItem,
  GTMDataLayer,
} from 'src/app/shared/models/googleTagManager.model';
import {
  GtmEvents,
  ItemListTypeEnum
} from 'src/app/shared/enums/gtm.enum';
import { GoogleTagManagerService } from 'src/app/shared/services/gtm.service';
@Component({
  selector: 'app-waygate-view-cart-details',
  templateUrl: './waygate-view-cart-details.component.html',
  styleUrls: ['./waygate-view-cart-details.component.scss'],
})
export class WaygateViewCartDetailsComponent implements OnInit {
  breadcrumbs: any[] = [];
  breakUpMenu = -1;
  showToggle: boolean;
  isSavecartCollapsed: boolean = false;
  data;
  public cartType = CartType;
  public productType = ProductType;
  nonFilmProduct: any[] = [];
  filmProduct: any[] = [];
  $subscription: Subscription;
  savedTime: string;
  commerceType: String;
  productLine: string;
  constructor(
    private savedCartService: SavedCartService,
    private globalMessageService: GlobalMessageService,
    private launchDialogService: LaunchDialogService,
    private router: Router,
    private breadCrumbService: BreadcrumbService,
    private translate: TranslationService,
    private activatedRoute: ActivatedRoute,
    private customerAccService: CustomerAccountService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.customerAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.breadcrumbs = [
        {
          url: `/${this.productLine}/saved-carts`,
          name: 'My Saved Carts',
        },
        {},
      ];
    });
    this.breadCrumbService.setBreadCrumbs([]);
    this.translate
      .translate('savedCart.saveCartTitle')
      .subscribe((res: string) =>
        this.breadCrumbService.setBreadcrumbTitle(res)
      );
    this.getCartCode();
  }
  getCartCode() {
    this.activatedRoute.params.subscribe((params) => {
      if (params?.cartId) {
        this.cartDetails(params?.cartId);
        this.breadcrumbs[1] = {
          name: params?.cartId,
        };
      }
    });
  }

  toggleButton() {
    this.showToggle = !this.showToggle;
  }

  cartDetails(cartId) {
    this.savedCartService.ViewCartDetail(cartId).subscribe(
      (data: any) => {
        this.data = data;
        this.commerceType = this.data.commerceType;
        this.getForamtedDate(this.data);
        if (this.data?.cartType == 'HYBRID') this.filmOrNonFilm(this.data);
        if (this.data?.locationMap.length > 1) this.locationWiseRMA(this.data);
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
  }

  getForamtedDate(data: any) {
    this.savedTime = moment(data?.saveTime).format('MMM Do YYYY, h:mm a');
  }

  filmOrNonFilm(items) {
    if (items.entries.length > 0) {
      for (let item of items.entries) {
        if (
          item.productType == this.productType.Typ1 ||
          item.productType == this.productType.Typ2
        ) {
          this.nonFilmProduct.push(item);
        } else if (item.productType == this.productType.Typ3) {
          this.filmProduct.push(item);
        }
      }
    }
  }

  locationWiseRMA(items) {
    if (items.locationMap.length > 1) {
      this.nonFilmProduct = items.returnsCartData.filter(
        (x) => x.returnLocationId == items.locationMap[0].key
      );
      this.filmProduct = items.returnsCartData.filter(
        (x) => x.returnLocationId == items.locationMap[1].key
      );
    }
  }

  goToCart() {
    this.router.navigate(['/cart']);
  }

  openPopup(type) {
    const viewSalesAreaDialog = this.launchDialogService.openDialog(
      DS_DIALOG.VIEW_SALES_AREA_DIALOG,
      undefined,
      undefined,
      {}
    );
    if (viewSalesAreaDialog) {
      viewSalesAreaDialog.pipe(take(1)).subscribe((value) => {});
    }
    this.savedCartService.setValidation({
      data: this.data,
      type: type,
      commerceType: this.commerceType,
    });
  }

  ngOnDestroy() {
    if (this.$subscription) {
      this.$subscription.unsubscribe();
    }
  }

  togglePriceBreakup(i) {
    if (this.breakUpMenu == i) {
      this.breakUpMenu = -1;
    } else this.breakUpMenu = i;
  }
  viewBreakUp(i) {
    return `#breakup${i}`;
  }

  getPositiveSilverClause(value) {
    if (value) {
      return Math.abs(value).toFixed(2);
    }
    return 0;
  }
  navigate() {
    this.router.navigate(['waygate/saved-carts']);
  }
  //Google Analytics
  gtmSelectItemEvent(entry) {
    if (entry) {
      let producitem: EcommerceItem[] = [];
      producitem.push({
        item_id: entry?.product?.code,
        item_name: entry?.product?.name,
        discount: entry?.discountPercentage ? +entry?.discountPercentage : '',
        index: 0,
        item_brand: this.gtmService.getItemBrand(),
        item_category: '',
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: ItemListTypeEnum.SavedCart,
        item_list_name: ItemListTypeEnum.SavedCart,
        price: entry?.discountPrice ? +entry?.discountPrice : '',
      });

      let purchaseEcommerceEcommerce: Ecommerce = {
        item_list_id: ItemListTypeEnum.SavedCart,
        item_list_name: ItemListTypeEnum.SavedCart,
        items: producitem,
      };
      let selectItemDataLayer: GTMDataLayer = {
        event: GtmEvents.SelectItem,
        store: this.gtmService.getItemBrand(),
        ecommerce: purchaseEcommerceEcommerce,
      };
      this.gtmService.sendEvent(selectItemDataLayer);
    }
  }
}
