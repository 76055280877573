<div class="footer">
  <div class="footer-top container" *ngIf="!isChooseBrandPage">
    <div class="footer-products">
      <label class="heading">{{ 'waygate.products' | cxTranslate }}</label>
      <ng-container
        *ngIf="productCategories$ | async as productCategories; else loading"
      >
        <ng-container
          *ngFor="
            let productCategory of productCategories
              | keyvalue
              | selectedCategory : salesAreas
          "
        >
          <ng-container *ngFor="let level_1 of productCategory?.categories">
            <a class="mr-b footer-title" [routerLink]="constructCategoryUrl(level_1)" (click)="scrollTop()">{{
              level_1?.name
            }}</a>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="footer-products">
      <label class="heading">{{ 'waygate.services' | cxTranslate }}</label>
          <ng-container *ngFor="let service of servicesList">
            <a class="mr-b footer-title" [href]="service.url" target="_blank">{{
              service.name
            }}</a>
          </ng-container>
    </div>
    <div class="footer-products">
      <label class="heading">{{ 'waygate.industries' | cxTranslate }}</label>
          <ng-container *ngFor="let industry of industriesList">
            <a class="mr-b footer-title" [href]="industry.url" target="_blank">{{
              industry.name
            }}</a>
          </ng-container>
    </div>
    <div class="footer-usefullinks">
      <label class="heading">{{ 'waygate.usefulLinks' | cxTranslate }}</label>
      <a class="mr-b footer-title" routerLink="/contactus" target="_blank">{{
        'waygate.helpFaq' | cxTranslate
      }}</a>
      <a
        class="mr-b footer-title"
        href="https://www.linkedin.com/company/waygate-technologies/"
        target="_blank"
        >{{ 'waygate.linkedIn' | cxTranslate }}</a
      >
      <a
        class="mr-b footer-title"
        href="https://www.youtube.com/@WaygateTechnologies"
        target="_blank"
        >{{ 'waygate.youTube' | cxTranslate }}</a
      >
      <a
        href="https://www.bakerhughes.com/"
        target="_blank"
        class="footer-title"
        >Bakerhughes.com</a
      >
    </div>
  </div>
  <hr class="horizontal-divider" *ngIf="!isChooseBrandPage"/>
  <div class="footer-bottom container">
    <div class="footer-logo">
      <a routerLink="/waygate">
        <img
          class="bh-img"
          src="../../../../../assets/img/bh-logo.svg"
          alt="click"
        />
      </a>
    </div>
    <div class="footer-links">
      <a href="https://www.bakerhughes.com/company/about-us" target="_blank">{{
        'waygate.aboutUs' | cxTranslate
      }}</a>
      <a href="https://www.bakerhughes.com/privacy" target="_blank">{{
        'waygate.privacy' | cxTranslate
      }}</a>
      <a routerLink="/contactus">{{ 'waygate.contactUs' | cxTranslate }}</a>
      <a (click)="openCookies()">{{ 'waygate.cookies' | cxTranslate }}</a>
      <a (click)="openTerms()">{{ 'waygate.termsOfUse' | cxTranslate }}</a>
    </div>
    <div class="footer-copyright">{{ 'waygate.copyRight' | cxTranslate }}</div>
  </div>
</div>
<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
