import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import {
  AuthService,
  HttpErrorModel,
  LanguageService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
  CmsService,
} from '@spartacus/core';
import { CustomerAccountService } from 'src/app/core/customer-account/customer-account.service';
import { LANGUAGES } from 'src/app/shared/models/language.model';
import { NavItems } from '../../header/navigation-menu/navigation-menu.component';
import {
  CustomerAccount,
  SalesArea,
} from 'src/app/core/customer-account/store/customer-account.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CommerceTypes } from 'src/app/shared/models/commerceTypes.model';
import { ProductCategory } from 'src/app/core/product-catalog/model/product-categories.model';
import { ProductCategoriesService } from 'src/app/core/product-catalog/services/product-categories.service';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import { DIALOG_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { DS_DIALOG } from 'src/app/core/dialog/dialog.config';
import { AllProductLine } from 'src/app/shared/enums/availableProductList.enum';

@Component({
  selector: 'app-waygate-header',
  templateUrl: './waygate-header.component.html',
  styleUrls: ['./waygate-header.component.scss'],
})
export class WaygateHeaderComponent implements OnInit {
  notificationOpened = false;
  helpOpened = false;
  supportOpened: boolean = false;
  currentLanguage = '';
  props: any = {};
  navSelected;
  navItems = NavItems;
  selectedItem: string;
  showProdCat = false;
  showBrandList: boolean = false;
  legalEntities: SalesArea[];
  registerUrl: string = environment.registerUrl;
  openMenu = false;
  totalNumberOfCart;
  cartitem: any;
  commerceTypes = CommerceTypes;
  isLoggedInUser = false;
  showEqMenu: boolean = false;
  showCustomerFiles = false;
  servicesOpened: boolean = false;
  industriesOpened: boolean = false;
  @Input() isChooseBrandPage: boolean = false;
  // @Output() closeMenu = new EventEmitter<any>();
  // showSlider: boolean = false;
  @ViewChild('searchFocusInput') searchFocusInput: ElementRef;
  showWaygate: boolean;
  favCustomerAccounts$: any;
  recentCustomerAccounts$: any;
  currentCustomerAccount$: Observable<CustomerAccount>;
  fixed: boolean;
  availableProductLines: any[];
  @ViewChild('productListTempRef')
  productListTempRef: ElementRef<HTMLInputElement>;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.fixed = window?.pageYOffset > 36 ? true : false;
  }

  constructor(
    protected languageService: LanguageService,
    private router: Router,
    private custAccService: CustomerAccountService,
    private ref: ChangeDetectorRef,
    protected activeCartFacade: ActiveCartFacade,
    private authService: AuthService,
    private globalMessageService: GlobalMessageService,
    private userAccountFacade: UserAccountFacade,
    private productCatService: ProductCategoriesService,
    private actions$: Actions,
    private multiCartFacade: MultiCartFacade,
    private cmsService: CmsService,
    private launchDialogService: LaunchDialogService,
    private customerAccService: CustomerAccountService
  ) {}

  guestSalesAreas$: Observable<SalesArea[]>;
  activeSalesArea$: Observable<SalesArea>;
  cart$: Observable<any> = this.activeCartFacade.getActive();
  userLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  productCategories$: Observable<ProductCategory[] | HttpErrorModel>;

  logoParagraphComponent$: Observable<any> = this.cmsService.getComponentData(
    'DSSiteLogoParagraphComponent'
  );
  user$: Observable<any>;
  quantity$: Observable<number> = this.activeCartFacade.getActive().pipe(
    startWith({ deliveryItemsQuantity: 0 }),
    map((cart) => cart.deliveryItemsQuantity || 0)
  );

  total$: Observable<string> = this.activeCartFacade.getActive().pipe(
    filter((cart) => !!cart.totalPrice),
    map((cart) => cart.totalPrice.formattedValue)
  );

  ngOnInit(): void {
    const itemsArr = [];
    // tslint:disable-next-line: forin
    for (const key in LANGUAGES) {
      itemsArr.push({
        label: LANGUAGES[key],
        value: key,
      });
    }
    this.props = {
      itemGroups: [
        {
          items: [...itemsArr],
        },
      ],
    };
    this.getDefaultLanguage();
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );

    this.user$.subscribe(
      (res: any) => {
        if (res) {
          this.isLoggedInUser = true;
          this.ref.detectChanges();
        } else {
          this.isLoggedInUser = false;
          this.ref.detectChanges();
        }
        if (res?.hasOwnProperty('isPrivateFolderExists')) {
          this.showCustomerFiles = res?.isPrivateFolderExists;
        } else {
          this.showCustomerFiles = false;
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          10000
        );
        window.scrollTo(0, 0);
      }
    );
    this.userLoggedIn$.subscribe((res) => {
      if (res) {
        this.productCatService.loadDefaultProductCategories(
          OCC_USER_ID_CURRENT
        );
      } else {
        this.productCatService.loadDefaultProductCategories(
          OCC_USER_ID_ANONYMOUS
        );
      }
      this.productCategories$ =
        this.productCatService.fetchDefaultProductCategories();
      this.custAccService.loadFavCustomerAccounts();
      this.favCustomerAccounts$ = this.custAccService.getFavCustomerAccounts();

      this.custAccService.loadRecentCustomerAccounts();
      this.recentCustomerAccounts$ =
        this.custAccService.getRecentCustomerAccounts();

      this.currentCustomerAccount$ =
        this.custAccService.getCurrentCustomerAccount();
      this.currentCustomerAccount$.subscribe((currentCustomerAccount) => {
        this.availableProductLines =
          currentCustomerAccount?.visibleCategories ?? [];
        if (
          currentCustomerAccount?.selectedBrand &&
          currentCustomerAccount?.selectedBrand == AllProductLine.waygate
        ) {
          this.showWaygate = true;
        } else {
          this.showWaygate = false;
        }
        this.ref.detectChanges();
      });

      this.custAccService.removeGuestActiveFromStorage();
    });
    this.guestSalesAreas$ = this.custAccService.getGuestSalesAreas();
    this.activeSalesArea$ = this.custAccService.getCurrentGuestSalesArea();
    this.activeSalesArea$.subscribe((res) => {
      if (Object.keys(res).length > 0) {
        if (res.salesAreaId === '1800_GE_GE') this.selectedItem = 'waygate';
        else this.selectedItem = '';
        this.ref.detectChanges();
      }
    });

    this.guestSalesAreas$.subscribe((res) => {
      this.legalEntities = res;
    });
    this.cart$.subscribe((res) => {
      this.totalNumberOfCart = null;
      this.cartitem = res.totalItems;
      if (this.cartitem == 0) {
        this.totalNumberOfCart = this.cartitem;
      } else {
        this.totalNumberOfCart = localStorage.getItem('numberOfCart')
          ? JSON.parse(localStorage['numberOfCart']) == 0
            ? 0
            : localStorage.getItem('numberOfCart')
          : 0;
      }
    });
    this.actions$
      .pipe(ofType(CartActions.CREATE_CART_SUCCESS), take(2))
      .subscribe((res: any) => {
        this.multiCartFacade.reloadCart(res.payload.cartId, { active: true });
      });

    // this.custAccService.getNotificationSliderState.subscribe((slider) => {
    //   this.showSlider = slider;
    // });
  }

  onLanguageChange(event) {
    this.languageService.setActive(event.detail);
    this.router.navigate(['/waygate']);
  }

  getDefaultLanguage() {
    this.languageService.getActive().subscribe((res) => {
      this.currentLanguage = LANGUAGES[res];
    });
  }
  languagedropdown() {
    this.notificationOpened = false;
  }
  togglehelp() {
    this.helpOpened = !this.helpOpened;
    this.notificationOpened = false;
  }
  toggleSupport() {
    this.supportOpened = !this.supportOpened;
    this.notificationOpened = false;
  }
  redirectToBynder() {
    this.router.navigate(['/search-private-folder']);
    const bynderUrl = environment.bynderUrl;
    window.open(bynderUrl, '_blank');
  }
  activeTabs(item: any) {
    switch (item) {
      case 'Home':
        this.navSelected = this.navItems.HOME;
        this.ref.detectChanges();
        break;
      case 'Products':
        this.navSelected = this.navItems.PRODUCTS;
        this.ref.detectChanges();
        break;
      case 'Status_tracker':
        this.navSelected = this.navItems.STATUS_TRACKER;
        this.ref.detectChanges();
        break;
      case 'Return_process':
        this.navSelected = this.navItems.RETURN_PROCESS;
        this.ref.detectChanges();
        break;
      case 'Calibration_data':
        this.navSelected = this.navItems.CALIBRATION_DATA;
        this.ref.detectChanges();
        break;
      case 'Other_portals_links':
        this.navSelected = this.navItems.OTHER_PORTALS_LINKS;
        this.ref.detectChanges();
        break;
      case 'My_orders':
        this.navSelected = this.navItems.MY_ORDERS;
        this.ref.detectChanges();
        break;
      case 'My_returns':
        this.navSelected = this.navItems.MY_RETURNS;
        this.ref.detectChanges();
        break;
      case 'My_equipment_data':
        this.navSelected = this.navItems.MY_EQUIPMENT_DATA;
        this.ref.detectChanges();
        break;
      case 'support':
        this.navSelected = this.navItems.SUPPORT;
        this.ref.detectChanges();
        break;
      default:
        this.navSelected = this.navItems.HOME;
        this.ref.detectChanges();
    }
  }
  register() {}
  signIn() {
    this.router.navigate(['/login']);
  }
  closeMenu(event) {
    this.openMenu = false;
  }
  openSearch() {
    this.searchFocusInput.nativeElement.blur();
    const searchDialog = this.launchDialogService.openDialog(
      DS_DIALOG.WAYGATE_SEARCH,
      undefined,
      undefined,
      {}
    );
    if (searchDialog) {
      searchDialog.subscribe((value) => {});
    }
  }

  WaygatePage() {
    this.customerAccService.disableChangeAccount.next(false);
    this.router.navigate(['/waygate']);
  }

  toggleBrandList(event) {
    if (event) {
      event.stopPropagation();
      this.showBrandList = !this.showBrandList;
    }
  }

  // openSlider() {
  //   this.showSlider = !this.showSlider;
  //   // this.closeMenu.emit(true);
  //   this.customerAccService.openNotifiactionSlider();
  // }
}
