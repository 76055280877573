<div class="waygate-notification-main mt-4">
  <form (ngSubmit)="onSubmit()">
    <div class="notification-sub">
      <div class="notification-label">{{'rma-confirmation.notifications' | cxTranslate}}</div>
      <div class="order-ack-label">{{'rma-confirmation.orderACKContactDetails' | cxTranslate}}</div>
      <div class="notification-contact-main">
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Contact Name"
            placeholder="Enter name"
            [value]="orderAckContactName"
            [message]="error.orderAckContactName"
            [attr.error]="
              error.orderAckContactName ? error.orderAckContactName : null
            "
            (keyup)="onChange($event, 'orderAckContactName')"
            maxlength="35"
          >
          </bh-text-input>
        </div>
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Phone Number"
            placeholder="Enter phone number"
            [value]="orderAckContactNo"
            [message]="error.orderAckContactNo"
            [attr.error]="
              error.orderAckContactNo ? error.orderAckContactNo : null
            "
            (keyup)="onChange($event, 'orderAckContactNo')"
            maxlength="16"
          >
          </bh-text-input>
        </div>
      </div>
      <div class="notification-contact-main">
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Email"
            placeholder="Enter email address"
            [value]="orderAck"
            [message]="error.orderAck"
            [attr.error]="error.orderAck ? error.orderAck : null"
            (keyup)="onChange($event, 'orderAck')"
            maxlength="241"
          >
          </bh-text-input>
        </div>
      </div>
      <div class="invoice-main mt-3">
        <div class="invoice-contact-label">{{'rma-confirmation.invoiceContactDetails' | cxTranslate}}</div>
        <div class="invoice-check mt-2">
          <bh-checkbox
            class="float-left"
            label=""
            [value]="invoiceCheck"
            (change)="onChange($event, 'invoiceCheck')"
          >
          </bh-checkbox>

          <div>
            <label class="invoice-checkbox-label"
              >{{'rma-confirmation.sameAsACKContactDetails' | cxTranslate}}</label
            >
          </div>
        </div>
      </div>
      <div class="notification-contact-main">
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Contact Name"
            placeholder="Enter name"
            [value]="invoiceContactName"
            [message]="error.invoiceContactName"
            [attr.error]="
              error.invoiceContactName ? error.invoiceContactName : null
            "
            (keyup)="onChange($event, 'invoiceContactName')"
            maxlength="35"
          >
          </bh-text-input>
        </div>
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Phone Number"
            placeholder="Enter phone number"
            [value]="invoiceContactNo"
            [message]="error.invoiceContactNo"
            [attr.error]="
              error.invoiceContactNo ? error.invoiceContactNo : null
            "
            (keyup)="onChange($event, 'invoiceContactNo')"
            maxlength="16"
          >
          </bh-text-input>
        </div>
      </div>
      <div class="notification-contact-main">
        <div>
          <bh-text-input
            type="text"
            required
            small
            label="Email"
            placeholder="Enter email address"
            [value]="invoiceMail"
            [message]="error.invoiceMail"
            [attr.error]="error.invoiceMail ? error.invoiceMail : null"
            (keyup)="onChange($event, 'invoiceMail')"
            maxlength="241"
          >
          </bh-text-input>
        </div>
      </div>
    </div>
    <div class="notification-remarks mt-3">
      <div class="uploadattachment">
        <span class="reviewed-text"
          >{{'labels.csrReviewConfirmation' | cxTranslate}}</span
        >

        <div class="radio-btn mt-2">
          <bh-radio-button
            label="Yes"
            value="true"
            name="group1"
            (change)="handleChange($event)"
          ></bh-radio-button>
          <bh-radio-button
            label="No"
            value="false"
            checked
            name="group1"
            (change)="handleChange($event)"
          ></bh-radio-button>
        </div>
        <div class="warning-box" *ngIf="this.bhgeReview == 'true'">
          <div>
            <span class="material-icons-outlined alert-warning"> warning </span>
          </div>
          <div>
            <span class="alert-text">
              {{ 'labels.BHReview' | cxTranslate }}</span
            >
          </div>
        </div>

        <div
          class="row col-lg-12 col-md-12 col-sm-12 uploadattachment reasonforchange"
          *ngIf="this.bhgeReview == 'true'"
        >
          <label class="textarea-label"
            ><span class="star"></span>
            {{ 'labels.csrReviewLbl' | cxTranslate }}</label
          >

          <textarea
            required
            class="textarea-buycheckout"
            name="reasonForCsrReview"
            [(ngModel)]="reason"
            (keyup)="onChange($event, 'reason')"
          ></textarea>
          <div
            *ngIf="this.error.reasonMsg"
            class="bh-form-message typography--body-small bh-form-message--error"
          >
            <i class="typography--icon-small bh-form-message__icon"
              >error_outline</i
            >{{ this.error.reasonMsg }}
          </div>
        </div>
        <div class="mt-3">
          <div class="additional-attachment-label">{{'labels.additionalDocuments' | cxTranslate}}</div>
          <div class="mt-2">
            <ds-file-upload
              label="Upload"
              [maxLength]="5"
              [maxSizeInMB]="10"
              [allowedFileExtensions]="ALLOWED_EXTENSIONS"
              (selectedFiles)="selectedFiles($event)"
              [showIcon]="showIcon"
              [icon]="'backup'"
              >upload</ds-file-upload
            >
          </div>

          <div class="file-list">
            <ds-file-upload-progress
              *ngFor="let file of files"
              [layout]="layouts.LIST"
              [deleteUrl]="deletUrl"
              [file]="file"
              (deletedFiles)="deletedFiles($event)"
              [uploadParams]="uploadParams"
              [uploadUrl]="uploadUrl"
              [showProgress]="true"
            ></ds-file-upload-progress>
          </div>
          <span class="fileformat mt-1"
            >{{ 'labels.fileFormat' | cxTranslate }}
          </span>
        </div>
      </div>
    </div>
  </form>
</div>
