import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService, HttpErrorModel } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductCategory } from 'src/app/core/product-catalog/model/product-categories.model';
import { ProductCategoriesService } from 'src/app/core/product-catalog/services/product-categories.service';

@Component({
  selector: 'app-waygate-menu',
  templateUrl: './waygate-menu.component.html',
  styleUrls: ['./waygate-menu.component.scss'],
})
export class WaygateMenuComponent {
  productCategories$: Observable<ProductCategory[] | HttpErrorModel>;
  salesAreas = ['ECOM_LVL1_00000001'];
  user$: Observable<any>;
  categories = [];
  selecteds = [];
  @Output() closeMenu = new EventEmitter<any>();
  constructor(
    private productCategoriesService: ProductCategoriesService,
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade
  ) {
    this.productCategories$ =
      this.productCategoriesService.fetchDefaultProductCategories();
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.userAccountFacade.get();
        } else {
          return of(undefined);
        }
      })
    );
  }
  openSubCategory(categories, index, i) {
    this.categories[index] = categories;
    this.selecteds[index] = i;
    if (index == 0) {
      this.categories[1] = [];
      this.selecteds[1] = [];
    }
  }
  constructCategoryUrl(category) {
    return `/waygate/categories/${category.code}/${category.name}`;
  }
  clicked(event) {
    this.closeMenu.emit(event);
  }
}
